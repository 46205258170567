import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraficaTestComponent } from './grafica-test/grafica-test.component';
import { NgApexchartsModule } from "ng-apexcharts";


@NgModule({
  declarations: [GraficaTestComponent],
  imports: [
    CommonModule,
    NgApexchartsModule
  ]
})
export class GraficaTestModule { }
