//import { Component } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { IUserAuth, USER_AUTH, IAuthService, AUTH_SERVICE } from 'vesta-authentication';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor(@Inject(USER_AUTH) private userAuth: IUserAuth, @Inject(AUTH_SERVICE) private authService: IAuthService) {}
  user: IUserAuth = this.userAuth;

  ngOnInit() {}
  
  Logout() {
    this.authService.signoutRedirect();
  }

}
