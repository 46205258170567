export class SameDataBarGraph {
  distance: number;
  widthBar: number;
}

export class ScmGraph extends SameDataBarGraph {
  title?: string;
  series: Array<ScmGraphSeries>
  xAxis: GraphXAxis;
  yAxis: GraphYAxis;
  linesV?: Array<LinesV>
  lineStartEndMonth?: Array<ScmGraphLine>;
  dataFooters?: Array<ScmGraphDataFooters>
  shadowsByRange?: Array<ShadowsByRange>;
}

export class PointVm {
  min: number;
  max: number;
}

export class LinesV {
  x: number;
  y: number;
  x2: number;
  y2: number;

  public static insert(x: number, y2: number, y: number = 0): LinesV {
    let obj = new LinesV();
    obj.x = x;
    obj.y = y;
    obj.x2 = x;
    obj.y2 = y2;
    return obj;
  }
}

export class ShadowsByRange {
  color: string;
  pathD: string;
  xMin: number;
  xMax: number;
  y: number;
  h: number;

  public static insert(color: string, xMin: number, xMax: number, y: number, h: number): ShadowsByRange {
    let obj = new ShadowsByRange();
    obj.color = color;
    obj.xMin = xMin;
    obj.xMax = xMax;
    obj.y = y;
    obj.h = h;
    obj.pathD = 'M ' + xMin + ' ' + y + ' L ' + xMax + ' ' + y + ' L ' + xMax + ' ' + h + ' L ' + xMin + ' ' + h + ' Z';
    return obj;
  }

}

export class ScmGraphSeries {
  x: number;
  y: number;
  title?: string;
  wBar: number;
  hBar: number;
  color: string;

  public static insert(x: number, y: number, wBar: number, hBar: number, color: string = '#2874a6', title: string = 'Información de la gráfica'): ScmGraphSeries {//#4a82c5
    let obj = new ScmGraphSeries();
    obj.x = x;
    obj.y = y;
    obj.wBar = wBar;
    obj.hBar = hBar;
    obj.color = color;
    obj.title = title;
    return obj;
  }
}

export class ScmGraphLine {
  xUno: number;
  yUno: number;
  xDos: number;
  yDos: number;
  color?: string;
}

export class ScmGraphDataFooters {
  dataFooter: Array<ScmGraphDataFooter>;
  title: string;
  x: number;
  y: number;
  yTitle: number;
}

export class ScmGraphDataFooter {
  x: number;
  y: number;
  wRect: number;
  hRect: number;
  information: number;
  yInformation?: number;
  rCorners?: number;

  public static insert(x: number, y: number, wRect: number, hRect: number, information: number, rCorners: number = 5): ScmGraphDataFooter {
    let obj = new ScmGraphDataFooter();
    obj.x = x;
    obj.y = y;
    obj.wRect = wRect;
    obj.hRect = hRect;
    obj.information = information;
    obj.yInformation = y + 20;
    obj.rCorners = rCorners;
    return obj;
  }

}

export class GraphXAxis {
  x: number;
  y: Array<ScmGraphPointValue>;/*va con una diferencia de +60*/
  value?: string;
  title?: string;
}


/*export class GraphLegendY {
  x: string;
  y: string;
  value: string;
}*/

export class GraphYAxis {
  x: Array<ScmGraphPointValue>;
  y: number;
  value?: string;
  rectData?: rectDataY;
  title?: string;
}

export class rectDataY {
  points: Array<ScmGraphPoint>
  radiusXY: string;
  wRect: number;/*Es el mismo para toda la leyenda*/
  hRect: number;/*Es el mismo para toda la leyenda*/

  public static insert(points: Array<ScmGraphPoint>, wRect: number, hRect: number, radiusXY: string = "5"): rectDataY {
    let obj = new rectDataY();
    obj.points = points;
    obj.wRect = wRect;
    obj.hRect = hRect;
    obj.radiusXY = radiusXY;
    return obj;
  }
}

export class ScmGraphPoint {
  x: number;
  y: number;
}

export class ScmGraphPointValue {
  point: number;
  value: string;
}

export class ScmColors {
  public static getColors(): Array<string> {
    let list: Array<string> = [];
    list.push("#f01212");//red #b03a2e
    //CornflowerBlue
    list.push("blue");
    list.push("green");
    /*list.push("magenta");*/
    list.push("#b9770e");//orange
    list.push("#b7950b");//yellow
    list.push("cobalt");
    list.push("teal");
    list.push("pink");
    return list;
  }
}
