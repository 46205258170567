import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-ordenes-de-compra-mes',
  templateUrl: './grid-ordenes-de-compra-mes.component.html',
  styleUrls: ['./grid-ordenes-de-compra-mes.component.css']
})
export class GridOrdenesDeCompraMesComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridOrdenesCompraMes', { static: false }) myGridOrdenesCompraMes: jqxGridComponent;

  ngOnInit() {
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'ProveedorNombre', type: 'string' },
        { name: 'Fecha', type: 'date' },
        { name: 'OrdenCompra', type: 'number' },
        { name: 'Cantidad', type: 'number' }
      ],
      id: "OrdenCompra",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      {
        text: 'Nº', sortable: false, filterable: false, columntype: 'number', width: '5%', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 12px; text-align:center;">' + (value + 1) + '</div>';
        },
      },
      { text: 'Proveedor', datafield: 'ProveedorNombre', cellsalign: 'left', align: 'center', width: '35%' },
      { text: 'Orden Compra', datafield: 'OrdenCompra', cellsalign: 'center', align: 'center', filterable: false, width: '20%' },
      { text: 'Fecha', datafield: 'Fecha', cellsalign: 'center', align: 'center', width: '20%', filterable: false, cellsformat: 'dd/MM/yyyy hh:mm:ss' },
      {
        text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '20%', cellsformat: 'n', aggregates: ['sum'],
        aggregatesrenderer: (aggregates) => {
          if (aggregates.sum == undefined) {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + "0" + '</div>';
            return renderstring;
          } else {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + aggregates.sum + '</div>';
            return renderstring;
          }
        }
      }
    ];

}
