import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { GridModificacionesForecastComponent } from '../grid-modificaciones-forecast/grid-modificaciones-forecast.component';
import { GridGestionesComponent } from '../grid-gestiones/grid-gestiones.component';
import { GridRequerimientosAfectadosComponent } from '../grid-requerimientos-afectados/grid-requerimientos-afectados.component';
import { ModificacionesForecast, Gestiones, RequerimientosAfectados, ProductoTerminadoAfectado } from '../../../objects/modificaciones-forecast-object';
import * as Enumerable from 'linq';

@Component({
  selector: 'app-modificaciones-forecast',
  templateUrl: './modificaciones-forecast.component.html',
  styleUrls: ['./modificaciones-forecast.component.css']
})
export class ModificacionesForecastComponent implements OnInit, AfterViewInit {

  constructor() { }
  @ViewChild(GridModificacionesForecastComponent, { static: false }) myGridModificacionesForecast: GridModificacionesForecastComponent;
  @ViewChild(GridGestionesComponent, { static: false }) myGridGestiones: GridGestionesComponent;
  @ViewChild(GridRequerimientosAfectadosComponent, { static: false }) myGridRequerimientosAfectados: GridRequerimientosAfectadosComponent;

  ngOnInit() {
  }

  DetailReferencia: boolean = false;
  Row: ModificacionesForecast;

  Modificaciones: ModificacionesForecast[] = [
    {
      "Id": 1,
      "Timer": "15:10:30",
      "Referencia": "SV-H21-70",
      "Tipo": "Spot",
      "Fecha": "23-03-2021",
      "Sku": 2006351,
      "Descripcion": "Tapa Chile 5 Onzas",
      "Categoria": "Foods",
      "Planta": "Dixie",
      "Unidad": "KG",
      "Cantidad": 200.00,
      "Observacion": "Negociación Mayoreo"
    },
    {
      "Id": 2,
      "Timer": "05:03:00",
      "Referencia": "SV-H21-71",
      "Tipo": "Tendencia",
      "Fecha": "Abril",
      "Sku": 832483,
      "Descripcion": "Pasta de Tomate Extra Espesa Issima 106g",
      "Categoria": "Foods",
      "Planta": "Dixie",
      "Unidad": "KG",
      "Cantidad": 200.00,
      "Observacion": "Negociación Mayoreo"
    },
    {
      "Id": 3,
      "Timer": "02:05:15",
      "Referencia": "SV-H21-72",
      "Tipo": "Tendencia",
      "Fecha": "23-03-2021",
      "Sku": 2183721,
      "Descripcion": "Mazola Palma Soya y Maiz 750 ml",
      "Categoria": "Fat & Oil",
      "Planta": "Dixie",
      "Unidad": "KG",
      "Cantidad": 200.00,
      "Observacion": "Negociación Mayoreo"
    }
  ];
  ModificacionGestion: Gestiones[] = [
    {
      "Id": 1,
      "ReferenciaGestion": "CD-CD-21-63",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 500.00
    },
    {
      "Id": 2,
      "ReferenciaGestion": "CD-CD-21-04",
      "FechaETD": "03-01-2021",
      "Unidad": "KG",
      "Cantidad": 1500.00
    },
    {
      "Id": 3,
      "ReferenciaGestion": "CD-CD-21-05",
      "FechaETD": "24-01-2021",
      "Unidad": "LB",
      "Cantidad": 100.00
    },
    {
      "Id": 4,
      "ReferenciaGestion": "CD-CD-20-100",
      "FechaETD": "05-02-2021",
      "Unidad": "KG",
      "Cantidad": 115.00
    },
    {
      "Id": 5,
      "ReferenciaGestion": "CD-CD-21-11",
      "FechaETD": "08-05-2021",
      "Unidad": "KG",
      "Cantidad": 125.00
    },
    {
      "Id": 6,
      "ReferenciaGestion": "CD-CD-21-52",
      "FechaETD": "10-03-2021",
      "Unidad": "KG",
      "Cantidad": 425.00
    },
    {
      "Id": 7,
      "ReferenciaGestion": "CD-CD-20-101",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 400.00
    },
    {
      "Id": 8,
      "ReferenciaGestion": "CD-CD-22-65",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 50.00
    },
    {
      "Id": 9,
      "ReferenciaGestion": "CD-CD-21-57",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 315.00
    },
    {
      "Id": 10,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 11,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 12,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 13,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 14,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 15,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 16,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 17,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 18,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 19,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 20,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    },
    {
      "Id": 21,
      "ReferenciaGestion": "CD-CD-21-17",
      "FechaETD": "02-01-2021",
      "Unidad": "KG",
      "Cantidad": 165.30
    }
  ]
  RequerimientosAfectados: RequerimientosAfectados[] = [
    {
      "Id": 1,
      "ReferenciaRequerimiento": "SRW-21-3",
      "Fecha": "24/03/2021" ,
      "CantidadNoAfectada": 0,
      "CantidadAfectada": 500.00 
    },
    {
      "Id": 2,
      "ReferenciaRequerimiento": "SRW-21-4",
      "Fecha": "24/03/2021",
      "CantidadNoAfectada": 0,
      "CantidadAfectada": 500.00
    },
    {
      "Id": 3,
      "ReferenciaRequerimiento": "SRW-21-5",
      "Fecha": "24/03/2021",
      "CantidadNoAfectada": 0,
      "CantidadAfectada": 500.00
    },
    {
      "Id": 4,
      "ReferenciaRequerimiento": "SRW-21-17",
      "Fecha": "24/03/2021",
      "CantidadNoAfectada": 0,
      "CantidadAfectada": 500.00
    },
    {
      "Id": 5,
      "ReferenciaRequerimiento": "SRW-21-78",
      "Fecha": "24/03/2021",
      "CantidadNoAfectada": 0,
      "CantidadAfectada": 500.00
    },
    {
      "Id": 6,
      "ReferenciaRequerimiento": "SRW-21-412",
      "Fecha": "24/03/2021",
      "CantidadNoAfectada": 0,
      "CantidadAfectada": 500.00
    }
  ]
  ProductoTerminado: ProductoTerminadoAfectado[]= [
    {
      "Sku": "Pasta de Tomate Issima",
      "Unidades": 120
    },
    {
      "Sku": "Ketchup Issima",
      "Unidades": 78
    },
    {
      "Sku": "Salsita de Tomate Ranchero",
      "Unidades": 230
    },
    {
      "Sku": "Sal Yodada",
      "Unidades": 12
    },
    {
      "Sku": "Aceite Issima",
      "Unidades": 230
    }
  ]

  CargarInfo() {
    this.myGridModificacionesForecast.source.localdata = Enumerable.from(this.Modificaciones).orderBy(o => o.Referencia).toArray();
    this.myGridModificacionesForecast.dataAdapter.dataBind();
  }

  TotalUnidades: number;
  ViewDetails(event: ModificacionesForecast) {
    this.DetailReferencia = true;
    this.ProductoTerminado;
    this.ModificacionGestion = Enumerable.from(this.ModificacionGestion).orderBy(o => o.ReferenciaGestion).toArray();
    this.RequerimientosAfectados = Enumerable.from(this.RequerimientosAfectados).orderBy(o => o.ReferenciaRequerimiento).toArray();
    this.TotalUnidades = Enumerable.from(this.ProductoTerminado).sum(s => s.Unidades);
    this.Row = event;
  }

  Regresar() {    
    this.DetailReferencia = false;
    this.Modificaciones = Enumerable.from(this.Modificaciones).orderBy(o => o.Referencia).toArray();
  }

  ngAfterViewInit() {
    this.CargarInfo();
  }
  
}
