import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import {
  ClienteXUsuario, detailsSku, ListaSemanaDetalle, SkuChequeado, SkuaDividir,
  RequerimientoParaDespacho, DetalleRequerimientoParaDespacho, GestionesPorSku, LoadDespachosResult,
  listaTest, Combos, ComboSave,


  ComboBoxTiposUPI, MesesAnio, Categorias, SkyByProveedor, Plantas, ConsumosSku, TablaSemanasSku, NewGestiones,
  CentrosVm, TipoMaterialesVm, GrupoMaterialesVm, CargarSkuPorGrupoMaterial, RespuestaUpiCrearPlantilla, PlantillasCreadasVm, GridPlantillasCreadasVm,
  Planta, TipoMaterial, SkuPlantilla, RespuestaUpiEditarPlantilla, SkuPorCodigo, GrupoMaterial
} from '../../../objects/upi-object';

import { GridAsignarCantidadASkuComponent } from '../../upi/grid-asignar-cantidad-a-sku/grid-asignar-cantidad-a-sku.component';
import { GridDividirSkuComponent } from '../../upi/grid-dividir-sku/grid-dividir-sku.component';
import { GridDespachosComponent } from '../../upi/grid-despachos/grid-despachos.component';

import { GridGestionesMesComponent } from '../../upi/grid-gestiones-mes/grid-gestiones-mes.component';
import { GridGestionesSemanasComponent } from '../../upi/grid-gestiones-semanas/grid-gestiones-semanas.component';

import { GridDespachosAsignadosMesComponent } from '../../upi/grid-despachos-asignados-mes/grid-despachos-asignados-mes.component';
import { GridDespachosAsignadosSemanasComponent } from '../../upi/grid-despachos-asignados-semanas/grid-despachos-asignados-semanas.component';

import { GridDespachosSinAsignarMesComponent } from '../../upi/grid-despachos-sin-asignar-mes/grid-despachos-sin-asignar-mes.component';
import { GridDespachosSinAsignarSemanasComponent } from '../../upi/grid-despachos-sin-asignar-semanas/grid-despachos-sin-asignar-semanas.component';

import { GridGestionesMensualSkuComponent } from '../../upi/grid-gestiones-mensual-sku/grid-gestiones-mensual-sku.component';

import { GridGestionesFinalizadasMesComponent } from '../../upi/grid-gestiones-finalizadas-mes/grid-gestiones-finalizadas-mes.component';
import { GridGestionesNoFinalizadasMesComponent } from '../../upi/grid-gestiones-no-finalizadas-mes/grid-gestiones-no-finalizadas-mes.component';

import { GridGestionesFinalizadasSemanasComponent } from '../../upi/grid-gestiones-finalizadas-semanas/grid-gestiones-finalizadas-semanas.component';
import { GridGestionesNoFinalizadasSemanasComponent } from '../../upi/grid-gestiones-no-finalizadas-semanas/grid-gestiones-no-finalizadas-semanas.component';

import { GridDespachosAsignadosMensualSkuComponent } from '../../upi/grid-despachos-asignados-mensual-sku/grid-despachos-asignados-mensual-sku.component';
import { GridDespachosSinAsignarMensualSkuComponent } from '../../upi/grid-despachos-sin-asignar-mensual-sku/grid-despachos-sin-asignar-mensual-sku.component';

import { GridOrdenesDeCompraMesComponent } from '../../upi/grid-ordenes-de-compra-mes/grid-ordenes-de-compra-mes.component';
import { GridOrdenesDeCompraMensualComponent } from '../../upi/grid-ordenes-de-compra-mensual/grid-ordenes-de-compra-mensual.component';

import { GridPlantillasComponent } from '../grid-plantillas/grid-plantillas.component';

import { USER_AUTH, IUserAuth } from 'vesta-authentication';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from 'modal';
import { DialogService, CallHttp } from 'vesta';
import * as moment from "moment";
import * as Enumerable from 'linq';


@Component({
  selector: 'app-upi',
  templateUrl: './upi.component.html',
  styleUrls: ['./upi.component.css'],
  providers: [TitleCasePipe]
})

export class UpiComponent implements OnInit, AfterViewInit {
  constructor(private DialogService: DialogService, private CallHttp: CallHttp, @Inject(USER_AUTH) private user: IUserAuth, private upperCase: TitleCasePipe) { }

  @ViewChild(GridAsignarCantidadASkuComponent, { static: false }) myGridAsignarCantidadaSku: GridAsignarCantidadASkuComponent;
  @ViewChild(GridDividirSkuComponent, { static: false }) myGridDividirSku: GridDividirSkuComponent;
  @ViewChild(GridDespachosComponent, { static: false }) myGridDespachos: GridDespachosComponent;
  @ViewChild("modalDividirSku", { static: false }) modalDividirSku: ModalComponent;

  @ViewChild("modalVerPlantillas", { static: false }) modalVerPlantillas: ModalComponent;
  @ViewChild("modalCrearPlantilla", { static: false }) modalCrearPlantilla: ModalComponent;

  @ViewChild("modalGestionesxMes", { static: false }) modalGestionesxMes: ModalComponent;
  @ViewChild(GridGestionesMesComponent, { static: false }) myGridGestionesMes: GridGestionesMesComponent;
  @ViewChild("modalGestionesSemanasxMes", { static: false }) modalGestionesSemanasxMes: ModalComponent;
  @ViewChild(GridGestionesSemanasComponent, { static: false }) myGridGestionesSemanas: GridGestionesSemanasComponent;

  @ViewChild("modalDespachosAsignadosxMes", { static: false }) modalDespachosAsignadosxMes: ModalComponent;
  @ViewChild(GridDespachosAsignadosMesComponent, { static: false }) myGridDespachosAsignadosMes: GridDespachosAsignadosMesComponent;
  @ViewChild("modalDespachosAsignadosSemanasxMes", { static: false }) modalDespachosAsignadosSemanasxMes: ModalComponent;
  @ViewChild(GridDespachosAsignadosSemanasComponent, { static: false }) myGridDespachosAsignadosSemanas: GridDespachosAsignadosSemanasComponent;

  @ViewChild("modalDespachosSinAsignarxMes", { static: false }) modalDespachosSinAsignarxMes: ModalComponent;
  @ViewChild(GridDespachosSinAsignarMesComponent, { static: false }) myGridDespachosSinAsignarMes: GridDespachosSinAsignarMesComponent;
  @ViewChild("modalDespachosSinAsignarSemanasxMes", { static: false }) modalDespachosSinAsignarSemanasxMes: ModalComponent;
  @ViewChild(GridDespachosSinAsignarSemanasComponent, { static: false }) myGridDespachosSinAsignarSemanas: GridDespachosSinAsignarSemanasComponent;

  @ViewChild("modalGestionesMesSku", { static: false }) modalGestionesMesSku: ModalComponent;
  @ViewChild(GridGestionesMensualSkuComponent, { static: false }) myGridGestionesMensualSku: GridGestionesMensualSkuComponent;

  @ViewChild(GridGestionesFinalizadasMesComponent, { static: false }) myGridGestionesFinalizadasMes: GridGestionesFinalizadasMesComponent;
  @ViewChild(GridGestionesNoFinalizadasMesComponent, { static: false }) myGridGestionesNoFinalizadasMes: GridGestionesNoFinalizadasMesComponent;

  @ViewChild(GridGestionesFinalizadasSemanasComponent, { static: false }) myGridGestionesFinalizadasSemanas: GridGestionesFinalizadasSemanasComponent;
  @ViewChild(GridGestionesNoFinalizadasSemanasComponent, { static: false }) myGridGestionesNoFinalizadasSemanas: GridGestionesNoFinalizadasSemanasComponent;

  @ViewChild("modalDespachosAsignadosMesSku", { static: false }) modalDespachosAsignadosMesSku: ModalComponent;
  @ViewChild(GridDespachosAsignadosMensualSkuComponent, { static: false }) myGridDespachosAsignadosMensualSku: GridDespachosAsignadosMensualSkuComponent;

  @ViewChild("modalDespachossinAsignarMesSku", { static: false }) modalDespachossinAsignarMesSku: ModalComponent;
  @ViewChild(GridDespachosSinAsignarMensualSkuComponent, { static: false }) myGridDespachosSinAsignarMesSku: GridDespachosSinAsignarMensualSkuComponent;

  @ViewChild(GridOrdenesDeCompraMesComponent, { static: false }) myGridOrdenesCompraMes: GridOrdenesDeCompraMesComponent;
  @ViewChild(GridOrdenesDeCompraMensualComponent, { static: false }) myGridOrdenesCompraMesSku: GridOrdenesDeCompraMensualComponent;

  @ViewChild(GridPlantillasComponent, { static: false }) myGridPlantillas: GridPlantillasComponent;
  @ViewChild("modalEditarPlantilla", { static: false }) modalEditarPlantilla: ModalComponent;

  ngOnInit() {
    this.CargarClienteUsuario();

    this.PlantaPlantilla = null;
    //this.skuCheck = null;
    this.search = null;
    //this.listaTest = Enumerable.from(this.listaTest).orderBy(o => o.SkuId).toArray();
    //this.ListaPermanente = this.listaTest;
    this.CheckComboUno = Enumerable.from(this.ComboUno).where(w => w.Id == 1).firstOrDefault();

    this.FechaInicio = moment().format('YYYY-MM-DD');
    //this.CargarCategorias();
    //this.CargarPlantas();
    this.CalculoDeFechas();

    this.tipoUpi = Enumerable.from(this.TiposUpi).where(w => w.Id == 1).firstOrDefault();
    this.validarDivs = false;
    this.validarLineasAbiertas = false;
    //console.log(this.validarDivs);
  }

  ngAfterViewInit() {
    this.CargarCentrosUpi();
    this.CargarTipoMaterialesUpi();
  }

  ///LISTA MESES DEL AÑO///
  MesesAnio: MesesAnio[] = [
    {
      Id: 1,
      Mes: 'Enero'
    },
    {
      Id: 2,
      Mes: 'Febrero'
    },
    {
      Id: 3,
      Mes: 'Marzo'
    },
    {
      Id: 4,
      Mes: 'Abril'
    },
    {
      Id: 5,
      Mes: 'Mayo'
    },
    {
      Id: 6,
      Mes: 'Junio'
    },
    {
      Id: 7,
      Mes: 'Julio'
    },
    {
      Id: 8,
      Mes: 'Agosto'
    },
    {
      Id: 9,
      Mes: 'Septiembre'
    },
    {
      Id: 10,
      Mes: 'Octubre'
    },
    {
      Id: 11,
      Mes: 'Noviembre'
    },
    {
      Id: 12,
      Mes: 'Diciembre'
    }
  ]

  ///LISTA COMBOBOX TIPOS DE UPI///
  tipoUpi: ComboBoxTiposUPI;
  TiposUpi: ComboBoxTiposUPI[] = [
    {
      Id: 1,
      Descripcion: "Inv. Inicial"
    },
    {
      Id: 2,
      Descripcion: "Inv. Inicial – Inv. De Seguridad"
    },
    {
      Id: 3,
      Descripcion: "Inv. Inicial – Inv. Máximo"
    }
  ]

  ///LISTA RADIOBUTTONS///
  CheckComboUno: Combos = null;
  ComboUno: Combos[] = [
    {
      Id: 1,
      Descripcion: 'Absolutos',
      Estado: false,
      NCombo: 1
    },
    {
      Id: 2,
      Descripcion: 'Cobertura',
      Estado: false,
      NCombo: 1
    }
  ]

  ///LISTA LINEAS DE TABLA///
  ListDetalleSemana: ListaSemanaDetalle[] = [
    {
      Id: 1,
      Descripcion: 'UPI',
      MostrarLinea: false,
      CambioImg: false,
    },
    {
      Id: 2,
      Descripcion: 'Inventario Inicial',
      MostrarLinea: false,
      CambioImg: false,
    },
    {
      Id: 3,
      Descripcion: 'Cobertura',
      MostrarLinea: false,
      CambioImg: false,
    },
    {
      Id: 4,
      Descripcion: 'Ingresos',
      MostrarLinea: false,
      CambioImg: false,
    },
    {
      Id: 5,
      Descripcion: 'Gestiones',
      MostrarLinea: false,
      CambioImg: false,
    },
    {
      Id: 6,
      Descripcion: 'Despachos Asignados',
      MostrarLinea: false,
      CambioImg: false,
    },
    {
      Id: 7,
      Descripcion: 'Despachos Sin Asignar',
      MostrarLinea: false,
      CambioImg: false,
    },

    {
      Id: 8,
      Descripcion: 'Consumos',
      MostrarLinea: false,
      CambioImg: false,
    },
    {
      Id: 9,
      Descripcion: 'Inventario Final',
      MostrarLinea: false,
      CambioImg: false,
    }
  ]

  ///METODO CALCULO DE FECHAS INICIO Y FIN///
  FechaInicio: string;
  FechaFinal: string;
  CalculoDeFechas() {
    let Fecha = this.FechaInicio.split("-");
    let mesNew = null;
    let anio = Fecha[0];
    let mes = Fecha[1];
    let diasMes = moment(this.FechaInicio).daysInMonth();

    if (mes >= "01" && mes <= "09") {
      mesNew = parseInt(mes.substring(1));
      mes = "0" + mesNew;
    } else if (mes == "10" || mes == "11") {
      mesNew = parseInt(mes);
      mes = mesNew;
    }

    this.FechaInicio = anio + "-" + mes + "-" + "01";
    this.FechaFinal = anio + "-" + mes + "-" + diasMes;
  }

  ///CARGAR CLIENTEID POR USUARIO///
  ClientexUsuario: string = null;
  CargarClienteUsuario() {
    let obj = {
      personaNaturalColaboradorId: this.user.Id
    }

    this.CallHttp.httpPost<ClienteXUsuario>(environment.webServices.ppm + "Mants/Personas/Home/PersonaByEmpresa", obj)
      .subscribe(response => {
        this.ClientexUsuario = response.PersonaJuridicaClienteId;
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  ///CARGAR CENTROS UPI///
  ListaCentros: Array<CentrosVm> = [];
  centroUpi: CentrosVm = null;

  ListaCentrosPlantillas: Array<CentrosVm> = [];
  centroPlantilla: CentrosVm = null;

  ListaCentrosEditarPlantillas: Array<CentrosVm> = [];
  CargarCentrosUpi() {
    this.CallHttp.httpGet<Array<CentrosVm>>(environment.webServices.ppm + "Mants/Centro/Home/Index")
      .subscribe(response => {
        if (response.length == 0) {
          this.DialogService.NotificacionWarning("No existen centros");
        } else {
          this.ListaCentros = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Codigo: s.Codigo,
              Observacion: s.Observacion,
              PlantaId: s.PlantaId,
              Planta: s.Codigo + " - " + s.Observacion
            }
          }).orderBy(o => o.Observacion).toArray();

          this.ListaCentrosPlantillas = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Codigo: s.Codigo,
              Observacion: s.Observacion,
              PlantaId: s.PlantaId,
              Planta: s.Codigo + " - " + s.Observacion
            }
          }).orderBy(o => o.Observacion).toArray();

          this.ListaCentrosEditarPlantillas = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Codigo: s.Codigo,
              Observacion: s.Observacion,
              PlantaId: s.PlantaId,
              Planta: s.Codigo + " - " + s.Observacion
            }
          }).orderBy(o => o.Observacion).toArray();
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  ///CARGAR TIPO DE MATERIALES///
  ListaTipoMateriales: Array<TipoMaterialesVm> = [];
  tipoMaterialUpi: Array<TipoMaterialesVm> = [];

  ListaTipoMaterialesPlantilla: Array<TipoMaterialesVm> = [];
  tipoMaterialUpiPlantilla: Array<TipoMaterialesVm> = [];

  ListaTipoMaterialesEditarPlantilla: Array<TipoMaterialesVm> = [];
  CargarTipoMaterialesUpi() {
    let personaJuridicaId = "8092e57d-03a5-44d3-b271-0f27ebf3d818";
    this.CallHttp.httpGet<Array<TipoMaterialesVm>>(environment.webServices.ppm + "Mants/Personas/Home/GetLoadCategoriaGrupoArticuloByPersonaJuridicaClienteId?PersonaJuridicaClienteId=" + personaJuridicaId)
      .subscribe(response => {
        if (response.length == 0) {
          this.DialogService.NotificacionWarning("No existen tipos de materiales");
        } else {
          this.ListaTipoMateriales = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId
            }
          }).orderBy(o => o.Descripcion).toArray();

          this.ListaTipoMaterialesPlantilla = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId
            }
          }).orderBy(o => o.Descripcion).toArray();

          this.ListaTipoMaterialesEditarPlantilla = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId
            }
          }).orderBy(o => o.Descripcion).toArray();
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  ///CARGAR GRUPO MATERIALES///
  ListaGrupoMateriales: Array<GrupoMaterialesVm> = [];
  grupoMaterialUpi: Array<GrupoMaterialesVm> = [];
  ObtenerTipoMaterial() {
    let obj = {
      personaJuridicaClienteId: "8092e57d-03a5-44d3-b271-0f27ebf3d818",
      categoriaGrupoArticuloSkuInboundIds: Enumerable.from(this.tipoMaterialUpi).select(s => s.Id).toArray()
    }

    this.CallHttp.httpPost<Array<GrupoMaterialesVm>>(environment.webServices.ppm + "/Mants/Personas/Home/ListLoadGrupoArticuloByCategoriaGrupoArticuloId", obj)
      .subscribe(response => {
        if (response.length == 0) {
          this.ListaGrupoMateriales = [];
          this.grupoMaterialUpi = [];

          this.ListaCargaSku = [];
        } else {
          this.ListaGrupoMateriales = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
              CategoriaGrupoArticuloSkuInboundId: s.CategoriaGrupoArticuloSkuInboundId
            }
          }).orderBy(o => o.Descripcion).toArray();
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  ///CARGAR GRUPO MATERIALES PLANTILLAS///
  ListaGrupoMaterialesPlantilla: Array<GrupoMaterialesVm> = [];
  grupoMaterialUpiPlantilla: Array<GrupoMaterialesVm> = [];
  ObtenerTipoMaterialPlantilla() {
    let obj = {
      personaJuridicaClienteId: "8092e57d-03a5-44d3-b271-0f27ebf3d818",
      categoriaGrupoArticuloSkuInboundIds: Enumerable.from(this.tipoMaterialUpiPlantilla).select(s => s.Id).toArray()
    }

    this.CallHttp.httpPost<Array<GrupoMaterialesVm>>(environment.webServices.ppm + "/Mants/Personas/Home/ListLoadGrupoArticuloByCategoriaGrupoArticuloId", obj)
      .subscribe(response => {
        if (response.length == 0) {
          this.ListaGrupoMaterialesPlantilla = [];
          this.grupoMaterialUpiPlantilla = [];

          this.ListaCargaSkuPlantillas = [];
        } else {
          this.ListaGrupoMaterialesPlantilla = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
              CategoriaGrupoArticuloSkuInboundId: s.CategoriaGrupoArticuloSkuInboundId
            }
          }).orderBy(o => o.Descripcion).toArray();
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  ListaGrupoMaterialesEditarPlantilla: Array<GrupoMaterialesVm> = [];
  ObtenerTipoMaterialEditarPlantilla() {
    let obj = {
      personaJuridicaClienteId: "8092e57d-03a5-44d3-b271-0f27ebf3d818",
      categoriaGrupoArticuloSkuInboundIds: Enumerable.from(this.tipoMaterialEditarUpiPlantilla).select(s => s.Id).toArray()
    }

    this.CallHttp.httpPost<Array<GrupoMaterialesVm>>(environment.webServices.ppm + "/Mants/Personas/Home/ListLoadGrupoArticuloByCategoriaGrupoArticuloId", obj)
      .subscribe(response => {
        if (response.length == 0) {
        } else {
          this.ListaGrupoMaterialesEditarPlantilla = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
              CategoriaGrupoArticuloSkuInboundId: s.CategoriaGrupoArticuloSkuInboundId
            }
          }).orderBy(o => o.Descripcion).toArray();

          this.ObtenerSkuEditarPlantilla();
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  ListaTipoMaterialesEditarPlantillaTodos: Array<TipoMaterialesVm> = [];
  ListaGrupoMaterialesEditarPlantillaTodos: Array<GrupoMaterialesVm> = [];
  ListaSkuEditarPlantillaTodos: Array<CargarSkuPorGrupoMaterial> = [];
  ObtenerTipoMaterialGrupoMaterialesEditarPlantillaTodos() {
    let personaJuridicaId = "8092e57d-03a5-44d3-b271-0f27ebf3d818";
    this.CallHttp.httpGet<Array<TipoMaterialesVm>>(environment.webServices.ppm + "Mants/Personas/Home/GetLoadCategoriaGrupoArticuloByPersonaJuridicaClienteId?PersonaJuridicaClienteId=" + personaJuridicaId)
      .subscribe(response => {
        if (response.length != 0) {
          this.ListaTipoMaterialesEditarPlantillaTodos = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId
            }
          }).orderBy(o => o.Descripcion).toArray();

          let obj = {
            personaJuridicaClienteId: "8092e57d-03a5-44d3-b271-0f27ebf3d818",
            categoriaGrupoArticuloSkuInboundIds: Enumerable.from(this.ListaTipoMaterialesEditarPlantillaTodos).select(s => s.Id).toArray()
          }

          this.CallHttp.httpPost<Array<GrupoMaterialesVm>>(environment.webServices.ppm + "/Mants/Personas/Home/ListLoadGrupoArticuloByCategoriaGrupoArticuloId", obj)
            .subscribe(response => {
              if (response.length != 0) {
                this.ListaGrupoMaterialesEditarPlantillaTodos = Enumerable.from(response).select(s => {
                  return {
                    Id: s.Id,
                    Descripcion: s.Descripcion,
                    PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                    CategoriaGrupoArticuloSkuInboundId: s.CategoriaGrupoArticuloSkuInboundId
                  }
                }).orderBy(o => o.Descripcion).toArray();

                let obj = {
                  grupoArticuloSkuInboundIds: Enumerable.from(this.ListaGrupoMaterialesEditarPlantillaTodos).select(s => s.Id).toArray()
                }

                this.CallHttp.httpPost<Array<CargarSkuPorGrupoMaterial>>(environment.webServices.ppm + "/Mants/Personas/Home/DetailsByGrupoArticuloIdByList", obj)
                  .subscribe(response => {
                    if (response.length != 0) {
                      this.ListaSkuEditarPlantillaTodos = Enumerable.from(response).select(s => {
                        return {
                          Id: s.Id,
                          PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                          PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
                          Descripcion: s.Descripcion,
                          Codigo: s.Codigo,
                          GrupoCompraId: s.GrupoCompraId,
                          Sku: s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
                          isSelected: false
                        }
                      }).orderBy(o => o.Codigo).toArray();
                    }
                  }, error => {
                    this.DialogService.NotificacionAlert(error);
                  });
              }
            }, error => {
              this.DialogService.NotificacionAlert(error);
            });
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  EliminarSkuChequeadoEditarPlantilla(Sku: CargarSkuPorGrupoMaterial) {
    for (var i = 0; i < this.SkuChequeadosEditarUpiPlantilla.length; i++) {
      if (this.SkuChequeadosEditarUpiPlantilla[i].Codigo == Sku.Codigo) {
        this.SkuChequeadosEditarUpiPlantilla.splice(i--, 1);
        this.SkuEditarUpiPlantilla = Enumerable.from(this.SkuChequeadosEditarUpiPlantilla).orderBy(o => o.Codigo).toArray();
      }
    }

    this.ListaSkuEditarPlantilla.forEach(f => {
      if (f.Codigo == Sku.Codigo) {
        f.isSelected = false;
      }
    });
  }

  ///CARGAR SKU'S///
  ListaCargaSku: Array<CargarSkuPorGrupoMaterial> = [];
  ObtenerSkus() {
    if (this.grupoMaterialUpi.length == 0) {
      this.ListaCargaSku = [];
      this.grupoMaterialUpi = [];
      //this.ListaSkuChequeadosProveedor = [];
    } else {
      //this.ListaCargaSku = [];
      //this.ListaSkuChequeadosProveedor = []

      let obj = {
        grupoArticuloSkuInboundIds: Enumerable.from(this.grupoMaterialUpi).select(s => s.Id).toArray()
      }

      this.CallHttp.httpPost<Array<CargarSkuPorGrupoMaterial>>(environment.webServices.ppm + "/Mants/Personas/Home/DetailsByGrupoArticuloIdByList", obj)
        .subscribe(response => {
          if (response.length == 0) {
            this.DialogService.NotificacionWarning("No existen grupos de materiales para tipo material");
          } else {
            if (this.ListaSkuChequeadosProveedor.length == 0) {
              this.ListaCargaSku = Enumerable.from(response).select(s => {
                return {
                  Id: s.Id,
                  PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                  PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
                  Descripcion: s.Descripcion,
                  Codigo: s.Codigo,
                  GrupoCompraId: s.GrupoCompraId,
                  Sku: s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
                  isSelected: false
                }
              }).orderBy(o => o.Codigo).toArray();
              this.ListaCargaSku = Enumerable.from(this.ListaCargaSku).orderBy(o => o.Codigo).toArray();
            } else {
              this.ListaCargaSku = Enumerable.from(response).select(s => {
                return {
                  Id: s.Id,
                  PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                  PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
                  Descripcion: s.Descripcion,
                  Codigo: s.Codigo,
                  GrupoCompraId: s.GrupoCompraId,
                  Sku: s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
                  isSelected: false
                }
              }).orderBy(o => o.Codigo).toArray();
              this.ListaCargaSku = Enumerable.from(this.ListaCargaSku).orderBy(o => o.Codigo).toArray();

              this.ListaSkuChequeadosProveedor.forEach(f => {
                this.ListaCargaSku.forEach(ff => {
                  if (f.Codigo == ff.Codigo) {
                    ff.isSelected = true;
                  }
                });      
              });
            }
          }
        }, error => {
          this.DialogService.NotificacionAlert(error);
        });    
    }
  }

  ListaCargaSkuPlantillas: Array<CargarSkuPorGrupoMaterial> = [];
  ObtenerSkusPlantillas() {
    if (this.grupoMaterialUpiPlantilla.length == 0) {
      this.ListaCargaSkuPlantillas = [];
      this.grupoMaterialUpiPlantilla = [];
      //this.ListaSkuChequeadosPlantilla = [];
    } else {
      //this.ListaCargaSkuPlantillas = [];
      //this.ListaSkuChequeadosPlantilla = []

      let obj = {
        grupoArticuloSkuInboundIds: Enumerable.from(this.grupoMaterialUpiPlantilla).select(s => s.Id).toArray()
      }

      this.CallHttp.httpPost<Array<CargarSkuPorGrupoMaterial>>(environment.webServices.ppm + "/Mants/Personas/Home/DetailsByGrupoArticuloIdByList", obj)
        .subscribe(response => {
          if (response.length == 0) {
            this.DialogService.NotificacionWarning("No existen grupos de materiales para tipo material ");
          } else {
            if (this.ListaSkuChequeadosPlantilla.length == 0) {
              this.ListaCargaSkuPlantillas = Enumerable.from(response).select(s => {
                return {
                  Id: s.Id,
                  PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                  PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
                  Descripcion: s.Descripcion,
                  Codigo: s.Codigo,
                  GrupoCompraId: s.GrupoCompraId,
                  Sku: s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
                  isSelected: false
                }
              }).orderBy(o => o.Codigo).toArray();
              this.ListaCargaSkuPlantillas = Enumerable.from(this.ListaCargaSkuPlantillas).orderBy(o => o.Codigo).toArray();
            } else {
              this.ListaCargaSkuPlantillas = Enumerable.from(response).select(s => {
                return {
                  Id: s.Id,
                  PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                  PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
                  Descripcion: s.Descripcion,
                  Codigo: s.Codigo,
                  GrupoCompraId: s.GrupoCompraId,
                  Sku: s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
                  isSelected: false
                }
              }).orderBy(o => o.Codigo).toArray();
              this.ListaCargaSkuPlantillas = Enumerable.from(this.ListaCargaSkuPlantillas).orderBy(o => o.Codigo).toArray();

              this.ListaSkuChequeadosPlantilla.forEach(f => {
                this.ListaCargaSkuPlantillas.forEach(ff => {
                  if (f.Codigo == ff.Codigo) {
                    ff.isSelected = true;
                  }
                });
              });
            }
          }
        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
    }
  }

  ListaSkuEditarPlantilla: Array<CargarSkuPorGrupoMaterial> = [];
  ObtenerSkuEditarPlantilla() {
    if (this.grupoMaterialUpiEditarPlantilla.length == 0) {
      this.ListaSkuEditarPlantilla = [];
      this.SkuEditarUpiPlantilla = [];
    } else {
      let obj = {
        grupoArticuloSkuInboundIds: Enumerable.from(this.grupoMaterialUpiEditarPlantilla).select(s => s.Id).toArray()
      }

      this.CallHttp.httpPost<Array<CargarSkuPorGrupoMaterial>>(environment.webServices.ppm + "/Mants/Personas/Home/DetailsByGrupoArticuloIdByList", obj)
        .subscribe(response => {
          if (response.length == 0) {
            //this.DialogService.NotificacionWarning("No existen grupos de materiales para tipo material ");
          } else {
            this.ListaSkuEditarPlantilla = Enumerable.from(response).select(s => {
              return {
                Id: s.Id,
                PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
                Descripcion: this.upperCase.transform(s.Descripcion),
                Codigo: s.Codigo,
                GrupoCompraId: s.GrupoCompraId,
                Sku: s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
                isSelected: false
              }
            }).orderBy(o => o.Codigo).toArray();
          }          

          this.SkuChequeadosEditarUpiPlantilla.forEach(f => {
            this.ListaSkuEditarPlantilla.forEach(ff => {
              if (f.Codigo == ff.Codigo) {
                ff.isSelected = true;
              }
            });
          });
        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
    }
  }

  ///CHECK POR SKU PARA BUSCAR UPI///
  masterSelected = false;
  ListaSkuChequeadosProveedor: Array<CargarSkuPorGrupoMaterial> = [];
  onCategoriaPressedSku(event: any, SkuId: string) {
    if (event.target.checked) {
      let [skuEncontrado] = this.ListaCargaSku.filter(w => w.Id == SkuId);
      if (skuEncontrado) {
        skuEncontrado.isSelected = true;
      }
      this.ListaSkuChequeadosProveedor.push(skuEncontrado);
      this.ListaSkuChequeadosProveedor = Enumerable.from(this.ListaSkuChequeadosProveedor).orderBy(o => o.Codigo).toArray();
    } else {
      for (var i = 0; i < this.ListaSkuChequeadosProveedor.length; i++) {
        if (this.ListaSkuChequeadosProveedor[i].Id == SkuId) {
          this.ListaSkuChequeadosProveedor.splice(i--, 1);
        }
      }
      this.ListaCargaSku.forEach(f => {
        if (f.Id == SkuId) {
          f.isSelected = false;
        }
      });

      this.ListaSkuChequeadosProveedor = Enumerable.from(this.ListaSkuChequeadosProveedor).orderBy(o => o.Codigo).toArray();
    }       
  }

  masterSelectedPlantilla = false;
  ListaSkuChequeadosPlantilla: Array<CargarSkuPorGrupoMaterial> = [];
  onCategoriaPressedSkuPlantilla(event: any, SkuId: string) {
    if (event.target.checked) {
      let [skuEncontrado] = this.ListaCargaSkuPlantillas.filter(w => w.Id == SkuId);
      if (skuEncontrado) {
        skuEncontrado.isSelected = true;
      }
      this.ListaSkuChequeadosPlantilla.push(skuEncontrado);
      this.ListaSkuChequeadosPlantilla = Enumerable.from(this.ListaSkuChequeadosPlantilla).select(s => {
        return {
          Id: s.Id,
          PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
          PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
          Descripcion: this.upperCase.transform(s.Descripcion),
          Codigo: s.Codigo,
          Sku: s.Sku,
          GrupoCompraId: s.GrupoCompraId,
          isSelected: s.isSelected
        }
      }).orderBy(o => o.Codigo).toArray();
    } else {
      for (var i = 0; i < this.ListaSkuChequeadosPlantilla.length; i++) {
        if (this.ListaSkuChequeadosPlantilla[i].Id == SkuId) {
          this.ListaSkuChequeadosPlantilla.splice(i--, 1);
        }
      }

      this.ListaSkuChequeadosPlantilla = Enumerable.from(this.ListaSkuChequeadosPlantilla).orderBy(o => o.Codigo).toArray();
    }
  }

  ///CHECK SKU TODOS PARA BUSCAR UPI///
  onCategoriaPressedSkuAll(evt) {
    this.ListaCargaSku.forEach((c) => c.isSelected = evt.target.checked);
    let SkuEncontrados = Enumerable.from(this.ListaCargaSku).where(w => w.isSelected == true).select(s => {
      return {
        Id: s.Id,
        PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
        PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
        Descripcion: this.upperCase.transform(s.Descripcion),
        Codigo: s.Codigo,
        Sku: s.Sku,
        GrupoCompraId: s.GrupoCompraId,
        isSelected: s.isSelected
        //Id: s.Id,
        //PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
        //PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
        //Descripcion: s.Descripcion,
        //Codigo: s.Codigo,
        //Presentacion: s.Presentacion,
        //Mezcla: s.Mezcla,
        //Categoria: s.Categoria,
        //Familia: s.Familia,
        //CategoriaVestaId: s.CategoriaVestaId,
        //Nombre: s.Codigo == null ? "N/D" : s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
        //isSelected: s.isSelected
      }
    }).orderBy(o => o.Codigo).toArray();
    this.ListaSkuChequeadosProveedor = SkuEncontrados;
  }

  onCategoriaPressedSkuAllPlantilla(evt) {
    this.ListaCargaSkuPlantillas.forEach((c) => c.isSelected = evt.target.checked);
    let SkuEncontrados = Enumerable.from(this.ListaCargaSkuPlantillas).where(w => w.isSelected == true).select(s => {
      return {
        Id: s.Id,
        PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
        PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
        Descripcion: this.upperCase.transform(s.Descripcion),
        Codigo: s.Codigo,
        Sku: s.Sku,
        GrupoCompraId: s.GrupoCompraId,
        isSelected: s.isSelected
      }
    }).orderBy(o => o.Codigo).toArray();
    this.ListaSkuChequeadosPlantilla = SkuEncontrados;
  }

  ///CARGAR PLANTAS///
  PlantasPrincipales: Array<Plantas> = [];
  PlantasPlantillas: Array<Plantas> = [];
  PlantaPrincipal: Plantas;
  mostrarPlantas: boolean = true;
  CargarPlantas() {
    let obj = {
      tipodeSitioId: null,
      personasJuridicaClienteId: [
        this.ClientexUsuario
      ]
    }

    this.CallHttp.httpPost<Array<Plantas>>(environment.webServices.ppm + "Mants/Personas/Home/DetailsbyClientesTipoSitio", obj)
      .subscribe(response => {
        this.PlantasPrincipales = Enumerable.from(response).select(s => {
          return {
            Id: s.Id,
            Nombre: this.upperCase.transform(s.Nombre),//s.Nombre.toLowerCase().trim().split(' ').map(v => v[0].toUpperCase() + v.substr(1)).join(' '),
            Descripcion: s.Descripcion,
            TipoDeSitioDescripcion: s.TipoDeSitioDescripcion
          }
        }).orderBy(o => o.Nombre).toArray();
        this.PlantasPlantillas = Enumerable.from(response).select(s => {
          return {
            Id: s.Id,
            Nombre: this.upperCase.transform(s.Nombre),//s.Nombre.toLowerCase().trim().split(' ').map(v => v[0].toUpperCase() + v.substr(1)).join(' '),
            Descripcion: s.Descripcion,
            TipoDeSitioDescripcion: s.TipoDeSitioDescripcion
          }
        }).orderBy(o => o.Nombre).toArray();
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  //change de checkbox para mostrar o no mostrar Despachos sin relacionar
  //Variable para controlar y poner cantidad despachos sin relacionar en 0
  checkDespachosSinRelacionar: boolean = false;
  changeCheckDespachos(event) {
    this.checkDespachosSinRelacionar = event.target.checked;
    if (this.listaReconstruidoFinal.length != 0) {
      this.BuscarSku();
    }
  }

  //Buscar Sku detalle 
  listaMesesYSemanas: Array<EncabezadoMesesSku> = [];
  listaSumasTotales: Array<SumasTotales> = [];

  listaOriginalUPI: Array<TablaSemanasSku> = [];
  listaReconstruidoFinal: Array<SkuList> = [];
  validarLineasAbiertas: boolean;
  validarDivs: boolean;

  BuscarSku(info?: GridPlantillasCreadasVm) {
    if (this.centroUpi == null) {
      this.DialogService.NotificacionWarning("Seleccione un centro");
    } else if (this.ListaSkuChequeadosProveedor.length == 0) {
      this.DialogService.NotificacionWarning("Agregue uno o mas Sku's");
    } else {
              this.validarDivs = false;
              let arrayCodigosSku: Array<string> = [];
              let arrayConsumosSku: Array<ConsumosSku> = [];
              arrayCodigosSku = Enumerable.from(this.ListaSkuChequeadosProveedor).select(s => s.Codigo).toArray();

              ////let vienePlantilla: GridPlantillasCreadasVm = null;
              ////if (info == undefined) {
              ////  info = null;
              ////  vienePlantilla = null;
              ////} else {
              ////  vienePlantilla = info;

              ////}

              //if (vienePlantilla == null) {
              //  arrayCodigosSku = Enumerable.from(this.ListaSkuChequeadosProveedor).select(s => s.Codigo).toArray();
              //} else {
              //  arrayCodigosSku = Enumerable.from(this.ListaSkuChequeadosProveedor).select(s => s.Codigo).toArray();
              //}

              let obj = {
                fechaInicio: this.FechaInicio + " " + "00:00:00",
                fechaFin: this.FechaFinal + " " + "23:59:59",
                skuCodigo: arrayCodigosSku,
                sitioId: this.centroUpi.PlantaId
              }

              this.CallHttp.httpPost<Array<ConsumosSku>>(environment.webServices.ppm + "Opers/Requerimiento/Home/LoadConsumoUpi", obj)
                .subscribe(response => {
                  arrayConsumosSku = Enumerable.from(response).select(s => {
                    return {
                      InvInicial: s.InvInicial,
                      SkuCodigo: s.SkuCodigo,
                      UnidadMedidaCantidadTotal: s.UnidadMedidaCantidadTotal
                    }
                  }).toArray();

                  let objTabla = {
                    fechaInicio: this.FechaInicio + " " + "00:00:00",
                    fechaFin: this.FechaFinal + " " + "23:59:59",
                    sitioId: this.centroUpi.PlantaId,
                    clienteId: this.ClientexUsuario,
                    skuAndInvInicia: arrayConsumosSku
                  }

                  this.CallHttp.httpPost<Array<TablaSemanasSku>>(environment.webServices.ppm + "Opers/Despacho/Home/LoadUPI", objTabla)
                    .subscribe(responses => {

                      this.checkDespachosSinRelacionar;

                      //Lista de objeto recontruido            
                      this.listaReconstruidoFinal = [];
                      this.listaOriginalUPI = [];
                      let codigosSku: Array<SkuList> = [];

                      this.listaOriginalUPI = responses;

                      //Metodo para encontrar Meses y Semanas
                      let MesesSemanasEncabezados: Array<EncabezadoMesesSku> = [];
                      this.listaMesesYSemanas = [];
                      Enumerable.from(responses).groupBy(ss => { return { Mes: ss.Mes, Semana: ss.Semana } }).forEach(ss => {
                        ss.forEach(ff => {

                          if (MesesSemanasEncabezados.length == 0) {
                            MesesSemanasEncabezados.push({
                              CantidadMeses: 1,
                              Meses: [
                                {
                                  CantidadSemanas: 1,
                                  DetailSemana: false,
                                  Nmes: ss.key().Mes,
                                  Mes: this.MesesAnio.filter(w => w.Id == ss.key().Mes)[0].Mes,
                                  Semanas: [
                                    {
                                      NSemana: ff.Semana,
                                      Semana: 'Semana ' + ff.Semana
                                    }
                                  ]
                                }
                              ]
                            })
                          } else {
                            let mesIndice = MesesSemanasEncabezados[0].Meses.findIndex(v => v.Nmes == ss.key().Mes);

                            if (mesIndice >= 0) {
                              let semanasIndice = MesesSemanasEncabezados[0].Meses[mesIndice].Semanas.findIndex(f => f.NSemana == ss.key().Semana);
                              let mes = MesesSemanasEncabezados[0].Meses[mesIndice];

                              if (semanasIndice == -1) {
                                mes.CantidadSemanas += 1;
                                mes.Semanas.push({
                                  NSemana: ff.Semana,
                                  Semana: 'Semana ' + ff.Semana
                                })
                              }
                            } else {
                              MesesSemanasEncabezados[0].CantidadMeses = MesesSemanasEncabezados[0].CantidadMeses + 1
                              MesesSemanasEncabezados[0].Meses.push({
                                CantidadSemanas: 1,
                                DetailSemana: false,
                                Nmes: ss.key().Mes,
                                Mes: this.MesesAnio.filter(w => w.Id == ss.key().Mes)[0].Mes,
                                Semanas: [
                                  {
                                    NSemana: ff.Semana,
                                    Semana: 'Semana ' + ff.Semana
                                  }
                                ]
                              })
                            }
                          }

                        })
                      });
                      this.listaMesesYSemanas = MesesSemanasEncabezados;
                      //console.log(this.listaMesesYSemanas);

                      //Metodo para cargar lineas del SKU
                      Enumerable.from(responses).groupBy(s => { return { Mes: s.Mes, Semana: s.Semana } }).forEach(s => {
                        //console.log(s.key())
                        s.forEach(f => {
                          f.UpiByDiasBySku.forEach(ff => {
                            let [codigos] = codigosSku.filter(s => s.SkuCodigo == ff.SkuCodigo);

                            if (!codigos) {
                              codigosSku.push({
                                Sku: this.upperCase.transform(ff.SkuDescripcion),//ff.SkuDescripcion.toLowerCase().trim().split(' ').map(v => v[0].toUpperCase() + v.substr(1)).join(' '),
                                SkuCodigo: ff.SkuCodigo,
                                Planta: this.centroUpi.Observacion,
                                AbrirCerrarTabla: false,
                                MostrarOcultarLineasTabla: false,
                                MostrarDetalleIngresos: false,
                                UnidadMedida: Enumerable.from(arrayConsumosSku).where(w => w.SkuCodigo == ff.SkuCodigo).select(s => s.UnidadMedidaCantidadTotal).firstOrDefault(),
                                LineasDescripcion: [
                                  {
                                    Id: 1,
                                    Descripcion: 'UPI',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },
                                  {
                                    Id: 2,
                                    Descripcion: 'Inventario Inicial',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },
                                  {
                                    Id: 3,
                                    Descripcion: 'Cobertura',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },
                                  {
                                    Id: 4,
                                    Descripcion: 'Ingresos',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },
                                  {
                                    Id: 5,
                                    Descripcion: 'Gestiones',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },
                                  {
                                    Id: 6,
                                    Descripcion: 'Despachos Asignados',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },
                                  {
                                    Id: 7,
                                    Descripcion: 'Despachos Sin Asignar',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },

                                  {
                                    Id: 8,
                                    Descripcion: 'Consumos',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  },
                                  {
                                    Id: 9,
                                    Descripcion: 'Inventario Final',
                                    MostrarLinea: false,
                                    CambioImg: false,
                                  }
                                ],
                                Meses: [
                                  {
                                    CantidadMeses: 1,
                                    MesesArray: [
                                      {
                                        Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                                        NMes: s.key().Mes,
                                        DetailSemana: false,
                                        CantidadSemanas: 1,
                                        Lineas: [
                                          {
                                            LineaPrincipal: this.CheckComboUno.Id == 1 ? ff.NewInvInicial : ff.NewCobertura,//ff.Cobertura,
                                            InventarioInicial: ff.NewInvInicial,
                                            Cobertura: Number(ff.NewCobertura.toFixed(2)),
                                            Ingresos: this.checkDespachosSinRelacionar == false ? ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones : ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado - ff.NewCantidadGestiones,
                                            IngresosSum: ff.NewTotalIngresos,
                                            DespachosAsignados: ff.CantidadDespachoRelacionado,
                                            DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                                            DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                                            DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                                            Gestiones: ff.NewCantidadGestiones,
                                            GestionesSum: ff.NewCantidadGestiones,
                                            Consumos: ff.Consumo,
                                            ConsumosSum: ff.Consumo,
                                            InventarioFinal: ff.NewUpi
                                          }
                                        ],
                                        Semanas: [
                                          {
                                            NSemana: s.key().Semana,
                                            Semana: `Semana ${s.key().Semana}`,
                                            ParoProgramado: false,
                                            SemanaDetalle: [
                                              {
                                                LineaPrincipal: this.CheckComboUno.Id == 1 ? ff.NewInvInicial : ff.NewCobertura,//ff.Cobertura,
                                                InventarioInicial: ff.NewInvInicial,
                                                Cobertura: Number(ff.NewCobertura.toFixed(2)),
                                                Ingresos: this.checkDespachosSinRelacionar == false ? ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones : ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado - ff.NewCantidadGestiones,
                                                IngresosSum: ff.NewTotalIngresos,
                                                DespachosAsignados: ff.CantidadDespachoRelacionado,
                                                DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                                                DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                                                DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                                                Gestiones: ff.NewCantidadGestiones,
                                                GestionesSum: ff.NewCantidadGestiones,
                                                Consumos: ff.Consumo,
                                                ConsumosSum: ff.Consumo,
                                                InventarioFinal: ff.NewUpi
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              });
                            } else {
                              //Validar Meses
                              let mesIndex = codigos.Meses[0].MesesArray.findIndex(f => f.NMes == s.key().Mes);

                              if (mesIndex > -1) {
                                let mes = codigos.Meses[0].MesesArray[mesIndex];
                                mes.Lineas[0].ConsumosSum += ff.Consumo,
                                  mes.Lineas[0].IngresosSum += ff.NewTotalIngresos,
                                  mes.Lineas[0].DespachosSinAsignarSum += ff.CantidadDespachoSinRelacionar,
                                  mes.Lineas[0].DespachosAsignadosSum += ff.CantidadDespachoRelacionado,
                                  mes.Lineas[0].GestionesSum += ff.NewCantidadGestiones

                                mes.Lineas[0].Cobertura += Number(ff.NewCobertura.toFixed(2)),
                                  mes.Lineas[0].DespachosAsignados += ff.CantidadDespachoRelacionado,
                                  mes.Lineas[0].Gestiones += ff.NewCantidadGestiones,
                                  mes.Lineas[0].Ingresos = this.checkDespachosSinRelacionar == false ? 0 + mes.Lineas[0].DespachosAsignados + mes.Lineas[0].GestionesSum : mes.Lineas[0].DespachosSinAsignar + mes.Lineas[0].DespachosAsignados + mes.Lineas[0].GestionesSum,
                                  mes.Lineas[0].DespachosSinAsignar = this.checkDespachosSinRelacionar == false ? 0 : mes.Lineas[0].DespachosSinAsignar += ff.CantidadDespachoSinRelacionar,
                                  mes.Lineas[0].Consumos += ff.Consumo

                                if (this.checkDespachosSinRelacionar == true) {
                                  mes.Lineas[0].InventarioFinal = mes.Lineas[0].InventarioInicial
                                    +
                                    (mes.Lineas[0].GestionesSum + mes.Lineas[0].DespachosAsignadosSum + mes.Lineas[0].DespachosSinAsignarSum)
                                    -
                                    mes.Lineas[0].Consumos

                                  //ff.NewUpi
                                } else {
                                  mes.Lineas[0].InventarioFinal = mes.Lineas[0].InventarioInicial
                                    +
                                    (mes.Lineas[0].DespachosAsignadosSum + mes.Lineas[0].GestionesSum)
                                    -
                                    mes.Lineas[0].Consumos
                                }
                              } else {
                                codigos.Meses[0].CantidadMeses++;
                                codigos.Meses[0].MesesArray.push(
                                  {
                                    Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                                    NMes: s.key().Mes,
                                    DetailSemana: false,
                                    CantidadSemanas: 1,
                                    Lineas: [
                                      {
                                        LineaPrincipal: this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == true ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal ? this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == false ? Number(ff.NewCobertura.toFixed(2)) : this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == true ? Number(ff.NewCobertura.toFixed(2)) : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : Number(ff.NewCobertura.toFixed(2)),
                                        InventarioInicial: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal,
                                        Cobertura: Number(ff.NewCobertura.toFixed(2)),
                                        Ingresos: this.checkDespachosSinRelacionar == false ? ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones : ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado - ff.NewCantidadGestiones,
                                        IngresosSum: ff.NewTotalIngresos,
                                        DespachosAsignados: ff.CantidadDespachoRelacionado,
                                        DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                                        DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                                        DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                                        Gestiones: ff.NewCantidadGestiones,
                                        GestionesSum: ff.NewCantidadGestiones,
                                        Consumos: ff.Consumo,
                                        ConsumosSum: ff.Consumo,
                                        InventarioFinal: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ?
                                          (
                                            (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioInicial
                                              +
                                              (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                            )
                                            -
                                            ff.Consumo
                                          )
                                          :
                                          this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ?
                                            ff.NewUpi
                                            : (
                                              (
                                                codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioInicial +
                                                (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                              ) - ff.Consumo
                                            )
                                      }
                                    ],
                                    Semanas: //[]
                                      [
                                        {
                                          NSemana: s.key().Semana,
                                          Semana: `Semana ${s.key().Semana}`,
                                          ParoProgramado: false,
                                          SemanaDetalle: [
                                            {
                                              LineaPrincipal: this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == true ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal ? this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == false ? Number(ff.NewCobertura.toFixed(2)) : this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == true ? Number(ff.NewCobertura.toFixed(2)) : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : Number(ff.NewCobertura.toFixed(2)),
                                              InventarioInicial: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal,
                                              //InventarioInicial: codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal,
                                              Cobertura: 0,
                                              Ingresos: 0,
                                              IngresosSum: 0,
                                              DespachosAsignados: 0,
                                              DespachosAsignadosSum: 0,
                                              DespachosSinAsignar: 0,
                                              DespachosSinAsignarSum: 0,
                                              Gestiones: 0,
                                              GestionesSum: 0,
                                              Consumos: 0,
                                              ConsumosSum: 0,
                                              InventarioFinal: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ?
                                                (
                                                  (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal
                                                    +
                                                    (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                                  )
                                                  -
                                                  ff.Consumo
                                                )
                                                :
                                                this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ?
                                                  (
                                                    (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal
                                                      +
                                                      (ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                                    )
                                                    -
                                                    ff.Consumo
                                                  )
                                                  :
                                                  (
                                                    (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal
                                                      +
                                                      (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                                    )
                                                    -
                                                    ff.Consumo
                                                  )
                                            }
                                          ]
                                        }
                                      ]
                                  });

                                mesIndex = codigos.Meses[0].MesesArray.length - 1;
                              }

                              //Validar Semanas                    
                              let semanasIndex = codigos.Meses[0].MesesArray[mesIndex].Semanas.findIndex(f => f.NSemana == s.key().Semana);

                              if (semanasIndex == -1) {
                                codigos.Meses[0].MesesArray[mesIndex].CantidadSemanas++;
                                codigos.Meses[0].MesesArray[mesIndex].Semanas.push(
                                  {
                                    NSemana: s.key().Semana,
                                    Semana: `Semana ${s.key().Semana}`,
                                    ParoProgramado: false,
                                    SemanaDetalle: [
                                      {
                                        LineaPrincipal: this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == true ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal ? this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == false ? Number(ff.NewCobertura.toFixed(2)) : this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == true ? Number(ff.NewCobertura.toFixed(2)) : codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : Number(ff.NewCobertura.toFixed(2)),
                                        InventarioInicial: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal,
                                        //InventarioInicial: codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length -1].SemanaDetalle[0].InventarioFinal,
                                        Cobertura: Number(ff.NewCobertura.toFixed(2)),
                                        Ingresos: this.checkDespachosSinRelacionar == false ? ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones : ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado - ff.NewCantidadGestiones,
                                        IngresosSum: ff.NewTotalIngresos,
                                        DespachosAsignados: ff.CantidadDespachoRelacionado,
                                        DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                                        DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                                        DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                                        Gestiones: ff.NewCantidadGestiones,
                                        GestionesSum: ff.NewCantidadGestiones,
                                        Consumos: ff.Consumo,
                                        ConsumosSum: ff.Consumo,
                                        InventarioFinal: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ?
                                          (
                                            (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                                              +
                                              (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                            )
                                            -
                                            ff.Consumo
                                          )
                                          :
                                          this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ?
                                            (
                                              (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                                                +
                                                (ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                              )
                                              -
                                              ff.Consumo
                                            )
                                            :
                                            (
                                              (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                                                +
                                                (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                                              )
                                              -
                                              ff.Consumo
                                            )
                                      }
                                    ]
                                  })
                              } else {
                                let semanas = codigos.Meses[0].MesesArray[mesIndex].Semanas[semanasIndex];
                                semanas.SemanaDetalle[0].ConsumosSum += ff.Consumo,
                                  semanas.SemanaDetalle[0].IngresosSum += ff.NewTotalIngresos,
                                  semanas.SemanaDetalle[0].DespachosSinAsignarSum += ff.CantidadDespachoSinRelacionar,
                                  semanas.SemanaDetalle[0].DespachosAsignadosSum += ff.CantidadDespachoRelacionado,
                                  semanas.SemanaDetalle[0].GestionesSum += ff.NewCantidadGestiones

                                semanas.SemanaDetalle[0].Cobertura += Number(ff.NewCobertura.toFixed(2)),
                                  semanas.SemanaDetalle[0].DespachosAsignados += ff.CantidadDespachoRelacionado,
                                  semanas.SemanaDetalle[0].Gestiones += ff.NewCantidadGestiones,
                                  semanas.SemanaDetalle[0].Ingresos = this.checkDespachosSinRelacionar == false ? 0 + semanas.SemanaDetalle[0].DespachosAsignados + semanas.SemanaDetalle[0].GestionesSum : semanas.SemanaDetalle[0].DespachosSinAsignar + semanas.SemanaDetalle[0].DespachosAsignados + semanas.SemanaDetalle[0].GestionesSum,
                                  semanas.SemanaDetalle[0].DespachosSinAsignar = this.checkDespachosSinRelacionar == false ? 0 : semanas.SemanaDetalle[0].DespachosSinAsignar += ff.CantidadDespachoSinRelacionar,
                                  semanas.SemanaDetalle[0].Consumos += ff.Consumo

                                if (this.checkDespachosSinRelacionar == true) {
                                  semanas.SemanaDetalle[0].InventarioFinal =
                                    (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                                      +
                                      (semanas.SemanaDetalle[0].DespachosSinAsignarSum + semanas.SemanaDetalle[0].DespachosAsignadosSum + semanas.SemanaDetalle[0].GestionesSum)
                                    )
                                    -
                                    semanas.SemanaDetalle[0].ConsumosSum
                                } else {
                                  semanas.SemanaDetalle[0].InventarioFinal = semanas.SemanaDetalle[0].InventarioInicial
                                    +
                                    (semanas.SemanaDetalle[0].DespachosAsignadosSum + semanas.SemanaDetalle[0].GestionesSum)
                                    -
                                    semanas.SemanaDetalle[0].ConsumosSum
                                }
                              }
                            }
                          })
                        })
                      });

                      this.listaReconstruidoFinal = codigosSku;
                      console.log(this.listaReconstruidoFinal);

                      //Metodo para cargar lineas de sumas totales
                      let sumastotales: Array<SumasTotales> = [];
                      Enumerable.from(this.listaReconstruidoFinal).selectMany(b => b.Meses).forEach(c => {
                        c.MesesArray.forEach(d => {
                          //d.Semanas.forEach(dd => {

                          if (sumastotales.length == 0) {
                            sumastotales.push({
                              Sku: Enumerable.from(this.listaReconstruidoFinal).select(s => s.Sku).firstOrDefault(),
                              LineasDescripcion: this.ListDetalleSemana,
                              AbrirTotales: false,
                              Totales: [
                                {
                                  CantidadMeses: c.CantidadMeses,
                                  Meses: [
                                    {
                                      CantidadSemanas: d.CantidadSemanas,
                                      DetailSemana: false,
                                      Nmes: d.NMes,
                                      Mes: d.Mes,
                                      Lineas: [
                                        {
                                          LineaPrincipal: Enumerable.from(d.Lineas).select(s => s.LineaPrincipal).firstOrDefault(),
                                          InventarioInicial: Enumerable.from(d.Lineas).select(s => s.InventarioInicial).firstOrDefault(),
                                          Cobertura: Enumerable.from(d.Lineas).select(s => Number(s.Cobertura.toFixed(2))).firstOrDefault(),
                                          Ingresos: Enumerable.from(d.Lineas).select(s => s.Ingresos).firstOrDefault(),
                                          Gestiones: Enumerable.from(d.Lineas).select(s => s.Gestiones).firstOrDefault(),
                                          DespachosAsignados: Enumerable.from(d.Lineas).select(s => s.DespachosAsignados).firstOrDefault(),
                                          DespachosSinAsignar: Enumerable.from(d.Lineas).select(s => s.DespachosSinAsignar).firstOrDefault(),
                                          Consumos: Enumerable.from(d.Lineas).select(s => s.Consumos).firstOrDefault(),
                                          InventarioFinal: Enumerable.from(d.Lineas).select(s => s.InventarioFinal).firstOrDefault(),
                                        }
                                      ],
                                      LineasSemanas: Enumerable.from(d.Semanas).select(s => {
                                        return {
                                          NSemana: s.NSemana,
                                          Semana: s.Semana,
                                          SemanaDetalle: Enumerable.from(s.SemanaDetalle).select(ss => {
                                            return {
                                              LineaPrincipal: ss.LineaPrincipal,
                                              InventarioInicial: ss.InventarioInicial,
                                              Cobertura: Number(ss.Cobertura.toFixed(2)),
                                              Ingresos: ss.Ingresos,
                                              Gestiones: ss.Gestiones,
                                              DespachosAsignados: ss.DespachosAsignados,
                                              DespachosSinAsignar: ss.DespachosSinAsignar,
                                              Consumos: ss.Consumos,
                                              InventarioFinal: ss.InventarioFinal
                                            }
                                          }).toArray()
                                        }
                                      }).toArray()
                                    }
                                  ]
                                }
                              ]
                            });
                          } else {
                            //Meses
                            let mesIndice = sumastotales[0].Totales[0].Meses.findIndex(v => v.Nmes == d.NMes);

                            if (mesIndice > -1) {
                              let mes = sumastotales[0].Totales[0].Meses[mesIndice];
                              mes.Lineas[0].LineaPrincipal += Enumerable.from(d.Lineas).select(s => s.LineaPrincipal).firstOrDefault(),
                                mes.Lineas[0].InventarioInicial += Enumerable.from(d.Lineas).select(s => s.InventarioInicial).firstOrDefault(),

                                mes.Lineas[0].Ingresos += Enumerable.from(d.Lineas).select(s => s.Ingresos).firstOrDefault(),
                                mes.Lineas[0].Gestiones += Enumerable.from(d.Lineas).select(s => s.Gestiones).firstOrDefault(),
                                mes.Lineas[0].DespachosAsignados += Enumerable.from(d.Lineas).select(s => s.DespachosAsignados).firstOrDefault(),
                                mes.Lineas[0].DespachosSinAsignar += Enumerable.from(d.Lineas).select(s => s.DespachosSinAsignar).firstOrDefault(),
                                mes.Lineas[0].Consumos += Enumerable.from(d.Lineas).select(s => s.Consumos).firstOrDefault(),
                                mes.Lineas[0].InventarioFinal += Enumerable.from(d.Lineas).select(s => s.InventarioFinal).firstOrDefault(),

                                mes.Lineas[0].CoberturaTotal = mes.Lineas[0].InventarioInicial / mes.Lineas[0].Consumos,
                                mes.Lineas[0].Cobertura = mes.Lineas[0].CoberturaTotal == -Infinity ? "0" : Number(mes.Lineas[0].CoberturaTotal.toFixed(2))
                              //mes.Lineas[0].Cobertura = mes.Lineas[0].InventarioFinal / mes.Lineas[0].Consumos == -Infinity ? 0 : Number((mes.Lineas[0].InventarioInicial / mes.Lineas[0].Consumos).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])

                              //Semanas
                              Enumerable.from(d.Semanas).forEach(f => {
                                let semanasIndex = sumastotales[0].Totales[0].Meses[mesIndice].LineasSemanas.findIndex(v => v.NSemana == f.NSemana)
                                if (semanasIndex >= 0) {
                                  let semanas = sumastotales[0].Totales[0].Meses[mesIndice].LineasSemanas[semanasIndex];
                                  semanas.SemanaDetalle[0].LineaPrincipal += Enumerable.from(f.SemanaDetalle).select(ss => ss.LineaPrincipal).firstOrDefault(),
                                    semanas.SemanaDetalle[0].InventarioInicial += Enumerable.from(f.SemanaDetalle).select(ss => ss.InventarioInicial).firstOrDefault(),

                                    semanas.SemanaDetalle[0].Ingresos += Enumerable.from(f.SemanaDetalle).select(ss => ss.Ingresos).firstOrDefault(),
                                    semanas.SemanaDetalle[0].Gestiones += Enumerable.from(f.SemanaDetalle).select(ss => ss.Gestiones).firstOrDefault(),
                                    semanas.SemanaDetalle[0].DespachosAsignados += Enumerable.from(f.SemanaDetalle).select(ss => ss.DespachosAsignados).firstOrDefault(),
                                    semanas.SemanaDetalle[0].DespachosSinAsignar += Enumerable.from(f.SemanaDetalle).select(ss => ss.DespachosSinAsignar).firstOrDefault(),
                                    semanas.SemanaDetalle[0].Consumos += Enumerable.from(f.SemanaDetalle).select(ss => ss.Consumos).firstOrDefault(),
                                    semanas.SemanaDetalle[0].InventarioFinal += Enumerable.from(f.SemanaDetalle).select(ss => ss.InventarioFinal).firstOrDefault(),

                                    semanas.SemanaDetalle[0].CoberturaTotal = semanas.SemanaDetalle[0].InventarioInicial / semanas.SemanaDetalle[0].Consumos,
                                    semanas.SemanaDetalle[0].Cobertura = semanas.SemanaDetalle[0].CoberturaTotal == -Infinity ? "0" : Number(semanas.SemanaDetalle[0].CoberturaTotal.toFixed(2))
                                  //semanas.SemanaDetalle[0].Cobertura = semanas.SemanaDetalle[0].InventarioInicial / semanas.SemanaDetalle[0].Consumos == -Infinity ? 0 : Number((semanas.SemanaDetalle[0].InventarioInicial / semanas.SemanaDetalle[0].Consumos).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0])
                                }
                              });
                            } else {
                              sumastotales[0].Totales[0].Meses.push({
                                CantidadSemanas: d.CantidadSemanas,
                                DetailSemana: false,
                                Nmes: d.NMes,
                                Mes: d.Mes,
                                Lineas: [
                                  {
                                    LineaPrincipal: Enumerable.from(d.Lineas).select(s => s.LineaPrincipal).firstOrDefault(),
                                    InventarioInicial: Enumerable.from(d.Lineas).select(s => s.InventarioInicial).firstOrDefault(),
                                    Cobertura: Enumerable.from(d.Lineas).select(s => Number(s.Cobertura.toFixed(2))).firstOrDefault(),
                                    Ingresos: Enumerable.from(d.Lineas).select(s => s.Ingresos).firstOrDefault(),
                                    Gestiones: Enumerable.from(d.Lineas).select(s => s.Gestiones).firstOrDefault(),
                                    DespachosAsignados: Enumerable.from(d.Lineas).select(s => s.DespachosAsignados).firstOrDefault(),
                                    DespachosSinAsignar: Enumerable.from(d.Lineas).select(s => s.DespachosSinAsignar).firstOrDefault(),
                                    Consumos: Enumerable.from(d.Lineas).select(s => s.Consumos).firstOrDefault(),
                                    InventarioFinal: Enumerable.from(d.Lineas).select(s => s.InventarioFinal).firstOrDefault()
                                  }
                                ],
                                LineasSemanas: Enumerable.from(d.Semanas).select(s => {
                                  return {
                                    NSemana: s.NSemana,
                                    Semana: s.Semana,
                                    SemanaDetalle: Enumerable.from(s.SemanaDetalle).select(ss => {
                                      return {
                                        LineaPrincipal: ss.LineaPrincipal,
                                        InventarioInicial: ss.InventarioInicial,
                                        Cobertura: Number(ss.Cobertura.toFixed(2)),
                                        Ingresos: ss.Ingresos,
                                        Gestiones: ss.Gestiones,
                                        DespachosAsignados: ss.DespachosAsignados,
                                        DespachosSinAsignar: ss.DespachosSinAsignar,
                                        Consumos: ss.Consumos,
                                        InventarioFinal: ss.InventarioFinal
                                      }
                                    }).toArray()
                                  }
                                }).toArray()
                              });

                            }
                          }
                        });
                      });

                      this.listaSumasTotales = sumastotales;
                      console.log(sumastotales);

                      //PRUEBA TEST 2
                      //Enumerable.from(responses).groupBy(s => { return { Mes: s.Mes, Semana: s.Semana } }).forEach(s => {
                      //  //console.log(s.key())
                      //  s.forEach(f => {
                      //    f.UpiBySemanaBySku.forEach(ff => {
                      //      let [codigos] = codigosSku.filter(s => s.SkuCodigo == ff.SkuCodigo);

                      //      if (!codigos) {
                      //        codigosSku.push({
                      //          Sku: this.upperCase.transform(ff.SkuDescripcion),//ff.SkuDescripcion.toLowerCase().trim().split(' ').map(v => v[0].toUpperCase() + v.substr(1)).join(' '),
                      //          SkuCodigo: ff.SkuCodigo,
                      //          Planta: this.PlantaPrincipal.Descripcion,
                      //          AbrirCerrarTabla: false,
                      //          MostrarOcultarLineasTabla: false,
                      //          MostrarDetalleIngresos: false,
                      //          LineasDescripcion: [
                      //            {
                      //              Id: 1,
                      //              Descripcion: 'UPI',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },
                      //            {
                      //              Id: 2,
                      //              Descripcion: 'Inventario Inicial',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },
                      //            {
                      //              Id: 3,
                      //              Descripcion: 'Cobertura',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },
                      //            {
                      //              Id: 4,
                      //              Descripcion: 'Ingresos',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },
                      //            {
                      //              Id: 5,
                      //              Descripcion: 'Gestiones',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },
                      //            {
                      //              Id: 6,
                      //              Descripcion: 'Despachos Asignados',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },
                      //            {
                      //              Id: 7,
                      //              Descripcion: 'Despachos Sin Asignar',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },

                      //            {
                      //              Id: 8,
                      //              Descripcion: 'Consumos',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            },
                      //            {
                      //              Id: 9,
                      //              Descripcion: 'Inventario Final',
                      //              MostrarLinea: false,
                      //              CambioImg: false,
                      //            }
                      //          ],
                      //          Meses: [
                      //            {
                      //              CantidadMeses: 1,
                      //              MesesArray: [
                      //                {
                      //                  Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                      //                  NMes: s.key().Mes,
                      //                  DetailSemana: false,
                      //                  CantidadSemanas: 1,
                      //                  Lineas: [
                      //                    {
                      //                      LineaPrincipal: this.CheckComboUno.Id == 1 ? ff.NewInvInicial : ff.Cobertura,
                      //                      InventarioInicial: ff.NewInvInicial,
                      //                      Cobertura: ff.NewCobertura,
                      //                      Ingresos: this.checkDespachosSinRelacionar == false ? ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones : ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado - ff.NewCantidadGestiones,
                      //                      IngresosSum: ff.NewTotalIngresos,
                      //                      DespachosAsignados: ff.CantidadDespachoRelacionado,
                      //                      DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                      //                      DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                      //                      DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                      //                      Gestiones: ff.NewCantidadGestiones,
                      //                      GestionesSum: ff.NewCantidadGestiones,
                      //                      Consumos: ff.Consumo,
                      //                      ConsumosSum: ff.Consumo,
                      //                      InventarioFinal: ff.NewUpi,
                      //                      InventarioFinalConDespachosAsignadosSum: ff.NewUpi,
                      //                      InventarioFinalSinDespachosAsignadosSum: ff.NewUpi
                      //                    }
                      //                  ],
                      //                  Semanas: [
                      //                    {
                      //                      NSemana: s.key().Semana,
                      //                      Semana: `Semana ${s.key().Semana}`,
                      //                      ParoProgramado: false,
                      //                      SemanaDetalle: [
                      //                        {
                      //                          LineaPrincipal: this.CheckComboUno.Id == 1 ? ff.NewInvInicial : ff.Cobertura,
                      //                          InventarioInicial: ff.NewInvInicial,
                      //                          Cobertura: ff.NewCobertura,
                      //                          Ingresos: this.checkDespachosSinRelacionar == false ? ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones : ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado - ff.NewCantidadGestiones,
                      //                          IngresosSum: ff.NewTotalIngresos,
                      //                          DespachosAsignados: ff.CantidadDespachoRelacionado,
                      //                          DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                      //                          DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                      //                          DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                      //                          Gestiones: ff.NewCantidadGestiones,
                      //                          GestionesSum: ff.NewCantidadGestiones,
                      //                          Consumos: ff.Consumo,
                      //                          ConsumosSum: ff.Consumo,
                      //                          InventarioFinal: ff.NewUpi
                      //                        }
                      //                      ]
                      //                    }
                      //                  ]
                      //                }
                      //              ]
                      //            }
                      //          ]
                      //        });
                      //      } else {
                      //        //Validar Meses
                      //        let mesIndex = codigos.Meses[0].MesesArray.findIndex(f => f.NMes == s.key().Mes);

                      //        if (mesIndex > -1) {
                      //          let mes = codigos.Meses[0].MesesArray[mesIndex];
                      //          mes.Lineas[0].Cobertura += ff.NewCobertura,
                      //          mes.Lineas[0].IngresosSum += ff.NewTotalIngresos,
                      //          mes.Lineas[0].GestionesSum += ff.NewCantidadGestiones
                      //          mes.Lineas[0].DespachosAsignadosSum += ff.CantidadDespachoRelacionado,
                      //          mes.Lineas[0].DespachosSinAsignarSum += ff.CantidadDespachoSinRelacionar,
                      //          mes.Lineas[0].ConsumosSum += ff.Consumo,
                      //          mes.Lineas[0].InventarioFinalConDespachosAsignadosSum = (mes.Lineas[0].GestionesSum + mes.Lineas[0].DespachosAsignados + mes.Lineas[0].DespachosSinAsignarSum) - mes.Lineas[0].ConsumosSum
                      //          mes.Lineas[0].InventarioFinalSinDespachosAsignadosSum = (mes.Lineas[0].GestionesSum + mes.Lineas[0].DespachosAsignados) - mes.Lineas[0].ConsumosSum

                      //          mes.Lineas[0].DespachosAsignados += ff.CantidadDespachoRelacionado,
                      //          mes.Lineas[0].Gestiones += ff.NewCantidadGestiones,
                      //          mes.Lineas[0].Ingresos = this.checkDespachosSinRelacionar == false ? 0 + mes.Lineas[0].DespachosAsignados + mes.Lineas[0].GestionesSum : mes.Lineas[0].DespachosSinAsignar + mes.Lineas[0].DespachosAsignados + mes.Lineas[0].GestionesSum,
                      //          mes.Lineas[0].DespachosSinAsignar = this.checkDespachosSinRelacionar == false ? 0 : mes.Lineas[0].DespachosSinAsignar += ff.CantidadDespachoSinRelacionar,
                      //          mes.Lineas[0].Consumos += ff.Consumo

                      //          if (this.checkDespachosSinRelacionar == true) {
                      //            mes.Lineas[0].InventarioFinalConDespachosAsignadosSum = (mes.Lineas[0].GestionesSum + mes.Lineas[0].DespachosAsignadosSum + mes.Lineas[0].DespachosSinAsignarSum) - mes.Lineas[0].ConsumosSum
                      //            //mes.Lineas[0].InventarioFinal = mes.Lineas[0].InventarioInicial
                      //            //  +
                      //            //  (mes.Lineas[0].GestionesSum + mes.Lineas[0].DespachosAsignadosSum + mes.Lineas[0].DespachosSinAsignarSum)
                      //            //  -
                      //            //  mes.Lineas[0].Consumos

                      //          } else {
                      //            mes.Lineas[0].InventarioFinalSinDespachosAsignadosSum = (mes.Lineas[0].GestionesSum + mes.Lineas[0].DespachosAsignadosSum) - mes.Lineas[0].ConsumosSum
                      //            //mes.Lineas[0].InventarioFinal = mes.Lineas[0].InventarioInicial
                      //            //  +
                      //            //  (mes.Lineas[0].DespachosAsignadosSum + mes.Lineas[0].GestionesSum)
                      //            //  -
                      //            //  mes.Lineas[0].Consumos
                      //          }
                      //        } else {
                      //          codigos.Meses[0].CantidadMeses++;
                      //          codigos.Meses[0].MesesArray.push(
                      //            {
                      //              Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                      //              NMes: s.key().Mes,
                      //              DetailSemana: false,
                      //              CantidadSemanas: 0,
                      //              Lineas: [
                      //                {

                      //                  LineaPrincipal: this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : ff.NewCobertura,  //this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == true ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal ? this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == false ? ff.NewCobertura : this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == true ? ff.NewCobertura : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinal : ff.NewCobertura,
                      //                  InventarioInicial: this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinalSinDespachosAsignadosSum : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinalConDespachosAsignadosSum,//this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinalSinDespachosAsignadosSum : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioFinalConDespachosAsignadosSum,
                      //                  Cobertura: ff.NewCobertura,
                      //                  Ingresos: codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].GestionesSum + codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].DespachosAsignadosSum,
                      //                  IngresosSum: codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].GestionesSum + codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].DespachosAsignadosSum + codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].DespachosSinAsignarSum,//ff.NewTotalIngresos,
                      //                  Gestiones: ff.NewCantidadGestiones,
                      //                  GestionesSum: ff.NewCantidadGestiones,
                      //                  DespachosAsignados: ff.CantidadDespachoRelacionado,
                      //                  DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                      //                  DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                      //                  DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                      //                  Consumos: ff.Consumo,
                      //                  ConsumosSum: ff.Consumo,
                      //                  InventarioFinalConDespachosAsignadosSum: (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].GestionesSum + codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].DespachosAsignadosSum + codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].DespachosSinAsignarSum) - codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].ConsumosSum,
                      //                  InventarioFinalSinDespachosAsignadosSum: (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].GestionesSum + codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].DespachosAsignadosSum) - codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].ConsumosSum,
                      //                  InventarioFinal: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ?
                      //                    (
                      //                      (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioInicial
                      //                        +
                      //                        (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                      )
                      //                      -
                      //                      ff.Consumo
                      //                    )
                      //                    :
                      //                    this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ?
                      //                      ff.NewUpi
                      //                      : (
                      //                        (
                      //                          codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Lineas[0].InventarioInicial +
                      //                          (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                        ) - ff.Consumo
                      //                      )
                      //                }
                      //              ],
                      //              Semanas: //[]
                      //                [
                      //                  {
                      //                    NSemana: s.key().Semana,
                      //                    Semana: `Semana ${s.key().Semana}`,
                      //                    ParoProgramado: false,
                      //                    SemanaDetalle: [
                      //                      {
                      //                        LineaPrincipal: this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == true ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal ? this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == false ? ff.NewCobertura : this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == true ? ff.NewCobertura : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : ff.NewCobertura,
                      //                        InventarioInicial: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal,
                      //                        //InventarioInicial: codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal,
                      //                        Cobertura: 0,
                      //                        Ingresos: 0,
                      //                        IngresosSum: 0,
                      //                        DespachosAsignados: 0,
                      //                        DespachosAsignadosSum: 0,
                      //                        DespachosSinAsignar: 0,
                      //                        DespachosSinAsignarSum: 0,
                      //                        Gestiones: 0,
                      //                        GestionesSum: 0,
                      //                        Consumos: 0,
                      //                        ConsumosSum: 0,
                      //                        InventarioFinal: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ?
                      //                          (
                      //                            (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal
                      //                              +
                      //                              (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                            )
                      //                            -
                      //                            ff.Consumo
                      //                          )
                      //                          :
                      //                          this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ?
                      //                            (
                      //                              (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal
                      //                                +
                      //                                (ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                              )
                      //                              -
                      //                              ff.Consumo
                      //                            )
                      //                            :
                      //                            (
                      //                              (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioFinal
                      //                                +
                      //                                (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                              )
                      //                              -
                      //                              ff.Consumo
                      //                            )
                      //                      }
                      //                    ]
                      //                  }
                      //                ]
                      //            });

                      //          mesIndex = codigos.Meses[0].MesesArray.length - 1;
                      //        }

                      //        //Validar Semanas                    
                      //        let semanasIndex = codigos.Meses[0].MesesArray[mesIndex].Semanas.findIndex(f => f.NSemana == s.key().Semana);

                      //        if (semanasIndex == -1) {
                      //          codigos.Meses[0].MesesArray[mesIndex].CantidadSemanas++;
                      //          codigos.Meses[0].MesesArray[mesIndex].Semanas.push(
                      //            {
                      //              NSemana: s.key().Semana,
                      //              Semana: `Semana ${s.key().Semana}`,
                      //              ParoProgramado: false,
                      //              SemanaDetalle: [
                      //                {
                      //                  LineaPrincipal: this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == false ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.CheckComboUno.Id == 1 && this.checkDespachosSinRelacionar == true ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal ? this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == false ? ff.NewCobertura : this.CheckComboUno.Id == 2 && this.checkDespachosSinRelacionar == true ? ff.NewCobertura : codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : ff.NewCobertura,
                      //                  InventarioInicial: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ? codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal : codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length - 1].SemanaDetalle[0].InventarioFinal,
                      //                  //InventarioInicial: codigos.Meses[0].MesesArray[mesIndex].Semanas[codigos.Meses[0].MesesArray[mesIndex].Semanas.length -1].SemanaDetalle[0].InventarioFinal,
                      //                  Cobertura: ff.NewCobertura,
                      //                  Ingresos: this.checkDespachosSinRelacionar == false ? ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones : ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado - ff.NewCantidadGestiones,
                      //                  IngresosSum: ff.NewTotalIngresos,
                      //                  DespachosAsignados: ff.CantidadDespachoRelacionado,
                      //                  DespachosAsignadosSum: ff.CantidadDespachoRelacionado,
                      //                  DespachosSinAsignar: this.checkDespachosSinRelacionar == false ? 0 : ff.CantidadDespachoSinRelacionar,
                      //                  DespachosSinAsignarSum: ff.CantidadDespachoSinRelacionar,
                      //                  Gestiones: ff.NewCantidadGestiones,
                      //                  GestionesSum: ff.NewCantidadGestiones,
                      //                  Consumos: ff.Consumo,
                      //                  ConsumosSum: ff.Consumo,
                      //                  InventarioFinal: this.checkDespachosSinRelacionar == false && this.CheckComboUno.Id == 1 ?
                      //                    (
                      //                      (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                      //                        +
                      //                        (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                      )
                      //                      -
                      //                      ff.Consumo
                      //                    )
                      //                    :
                      //                    this.checkDespachosSinRelacionar == true && this.CheckComboUno.Id == 1 ?
                      //                      (
                      //                        (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                      //                          +
                      //                          (ff.CantidadDespachoSinRelacionar + ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                        )
                      //                        -
                      //                        ff.Consumo
                      //                      )
                      //                      :
                      //                      (
                      //                        (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                      //                          +
                      //                          (ff.CantidadDespachoRelacionado + ff.NewCantidadGestiones)
                      //                        )
                      //                        -
                      //                        ff.Consumo
                      //                      )
                      //                }
                      //              ]
                      //            })
                      //        } else {
                      //          let semanas = codigos.Meses[0].MesesArray[mesIndex].Semanas[semanasIndex];
                      //          semanas.SemanaDetalle[0].ConsumosSum += ff.Consumo,
                      //            semanas.SemanaDetalle[0].IngresosSum += ff.NewTotalIngresos,
                      //            semanas.SemanaDetalle[0].DespachosSinAsignarSum += ff.CantidadDespachoSinRelacionar,
                      //            semanas.SemanaDetalle[0].DespachosAsignadosSum += ff.CantidadDespachoRelacionado,
                      //            semanas.SemanaDetalle[0].GestionesSum += ff.NewCantidadGestiones

                      //          semanas.SemanaDetalle[0].Cobertura += ff.NewCobertura,
                      //            semanas.SemanaDetalle[0].DespachosAsignados += ff.CantidadDespachoRelacionado,
                      //            semanas.SemanaDetalle[0].Gestiones += ff.NewCantidadGestiones,
                      //            semanas.SemanaDetalle[0].Ingresos = this.checkDespachosSinRelacionar == false ? 0 + semanas.SemanaDetalle[0].DespachosAsignados + semanas.SemanaDetalle[0].GestionesSum : semanas.SemanaDetalle[0].DespachosSinAsignar + semanas.SemanaDetalle[0].DespachosAsignados + semanas.SemanaDetalle[0].GestionesSum,
                      //            semanas.SemanaDetalle[0].DespachosSinAsignar = this.checkDespachosSinRelacionar == false ? 0 : semanas.SemanaDetalle[0].DespachosSinAsignar += ff.CantidadDespachoSinRelacionar,
                      //            semanas.SemanaDetalle[0].Consumos += ff.Consumo

                      //          if (this.checkDespachosSinRelacionar == true) {
                      //            semanas.SemanaDetalle[0].InventarioFinal =
                      //              (codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas[codigos.Meses[0].MesesArray[codigos.Meses[0].MesesArray.length - 1].Semanas.length - 1].SemanaDetalle[0].InventarioInicial
                      //                +
                      //                (semanas.SemanaDetalle[0].DespachosSinAsignarSum + semanas.SemanaDetalle[0].DespachosAsignadosSum + semanas.SemanaDetalle[0].GestionesSum)
                      //              )
                      //              -
                      //              semanas.SemanaDetalle[0].ConsumosSum
                      //          } else {
                      //            semanas.SemanaDetalle[0].InventarioFinal = semanas.SemanaDetalle[0].InventarioInicial
                      //              +
                      //              (semanas.SemanaDetalle[0].DespachosAsignadosSum + semanas.SemanaDetalle[0].GestionesSum)
                      //              -
                      //              semanas.SemanaDetalle[0].ConsumosSum
                      //          }
                      //        }
                      //      }
                      //    })
                      //  })
                      //});
                    }, error => {
                      this.DialogService.NotificacionAlert(error);
                    });
                }, error => {
                  this.DialogService.NotificacionAlert(error);
                });
           }
  }

  ///INICIO BUSCAR SKU'S POR CODIGO///
  ArrayCodigoSku: Array<SkuPorCodigo> = [];
  codigoSkuSearch: string = null;
  codigoSku: string = null;
  BuscarSkuXCodigo() {
    if (this.codigoSkuSearch == null || this.codigoSkuSearch == "") {
      this.DialogService.NotificacionWarning("Ingrese un código");
    } else {
      let obj = {
        personaJuridicaClienteId: "8092e57d-03a5-44d3-b271-0f27ebf3d818",
        codigos: [this.codigoSkuSearch]
      }

      this.CallHttp.httpPost<Array<SkuPorCodigo>>(environment.webServices.ppm + "Mants/Personas/Home/ListSkuInboundClienteByCodigo", obj)
        .subscribe(response => {
          response.forEach(f => {
            if (response.length == 0) {
              this.codigoSku = "No existe un Sku creado con este código " + this.codigoSkuSearch;
            } else {
              this.ArrayCodigoSku = response;
              this.codigoSku = f.Codigo + " - " + this.upperCase.transform(f.Descripcion);
            }
          });
        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
    }
  }

  AgregarSkuXCodigo() {
    if (this.codigoSkuSearch == null || this.codigoSkuSearch == "") {
      this.DialogService.NotificacionWarning("Ingrese un código");
    } else {
      if (this.ListaSkuChequeadosProveedor.length == 0) {
        this.codigoSkuSearch = null;
        this.codigoSku = null;

        let SkuEncontrado = {
          Id: this.ArrayCodigoSku[0].Id,
          PersonaJuridicaClienteId: null,
          PersonaJuridicaClienteNombre: null,
          Descripcion: this.upperCase.transform(this.ArrayCodigoSku[0].Descripcion),
          Codigo: this.ArrayCodigoSku[0].Codigo,
          Sku: this.ArrayCodigoSku[0].Codigo + " - " + this.upperCase.transform(this.ArrayCodigoSku[0].Descripcion),
          GrupoCompraId: null,
          isSelected: true
        }
        this.ListaSkuChequeadosProveedor.push(SkuEncontrado);
        this.ListaSkuChequeadosProveedor = Enumerable.from(this.ListaSkuChequeadosProveedor).orderBy(o => o.Codigo).toArray();

        this.ListaCargaSku.forEach(f => {
          if (f.Codigo == this.ArrayCodigoSku[0].Codigo) {
            f.isSelected = true;
          }
        });
      } else {
        this.ListaSkuChequeadosProveedor.forEach(f => {
          if (f.Codigo == this.ArrayCodigoSku[0].Codigo) {
            this.DialogService.NotificacionWarning("Sku  " + this.ArrayCodigoSku[0].Descripcion + " ya esta seleccionado");
          } else {
            this.codigoSkuSearch = null;
            this.codigoSku = null;

            let SkuEncontrado = {
              Id: this.ArrayCodigoSku[0].Id,
              PersonaJuridicaClienteId: null,
              PersonaJuridicaClienteNombre: null,
              Descripcion: this.upperCase.transform(this.ArrayCodigoSku[0].Descripcion),
              Codigo: this.ArrayCodigoSku[0].Codigo,
              Sku: this.ArrayCodigoSku[0].Codigo + " - " + this.upperCase.transform(this.ArrayCodigoSku[0].Descripcion),
              GrupoCompraId: null,
              isSelected: true
            }
            this.ListaSkuChequeadosProveedor.push(SkuEncontrado);
            this.ListaSkuChequeadosProveedor = Enumerable.from(this.ListaSkuChequeadosProveedor).orderBy(o => o.Codigo).toArray();

            this.ListaCargaSku.forEach(f => {
              if (f.Codigo == this.ArrayCodigoSku[0].Codigo) {
                f.isSelected = true;
              }
            });
          }
        });
      }
    }
  }

  EliminarSkuChequeado(Sku: CargarSkuPorGrupoMaterial) {
    if (this.ListaCargaSku.length == 0) {
      for (var i = 0; i < this.ListaSkuChequeadosProveedor.length; i++) {
        if (this.ListaSkuChequeadosProveedor[i].Codigo == Sku.Codigo) {
          this.ListaSkuChequeadosProveedor.splice(i--, 1);
        }
      }
    } else {
      this.ListaCargaSku.forEach(f => {
        if (f.Codigo == Sku.Codigo) {
          f.isSelected = false;
        }
      });

      for (var i = 0; i < this.ListaSkuChequeadosProveedor.length; i++) {
        if (this.ListaSkuChequeadosProveedor[i].Codigo == Sku.Codigo) {
          this.ListaSkuChequeadosProveedor.splice(i--, 1);
        }
      }
    }
  }
  ///FIN BUSCAR SKU'S POR CODIGO///

  ///INICIO BUSCAR SKU'S POR CODIGO PLANTILLA///
  ArrayCodigoSkuPlantilla: Array<SkuPorCodigo> = [];
  codigoSkuSearchPlantilla: string = null;
  codigoSkuPlantilla: string = null;

  BuscarSkuXCodigoPlantilla() {
    if (this.codigoSkuSearchPlantilla == null || this.codigoSkuSearchPlantilla == "") {
      this.DialogService.NotificacionWarning("Ingrese un código");
    } else {
      let obj = {
        personaJuridicaClienteId: "8092e57d-03a5-44d3-b271-0f27ebf3d818",
        codigos: [this.codigoSkuSearchPlantilla]
      }

      this.CallHttp.httpPost<Array<SkuPorCodigo>>(environment.webServices.ppm + "Mants/Personas/Home/ListSkuInboundClienteByCodigo", obj)
        .subscribe(response => {
          response.forEach(f => {
            if (response.length == 0) {
              this.codigoSkuPlantilla = "No existe un Sku creado con este código " + this.codigoSkuSearchPlantilla;
            } else {
              this.ArrayCodigoSkuPlantilla = response;
              this.codigoSkuPlantilla = f.Codigo + " - " + this.upperCase.transform(f.Descripcion);
            }
          });

        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
    }
  }

  AgregarSkuXCodigoPlantilla() {
    if (this.codigoSkuSearchPlantilla == null || this.codigoSkuSearchPlantilla == "") {
      this.DialogService.NotificacionWarning("Ingrese un código");
    } else {
      if (this.ListaSkuChequeadosPlantilla.length == 0) {
        this.codigoSkuSearchPlantilla = null;
        this.codigoSkuPlantilla = null;

        let SkuEncontrado = {
          Id: this.ArrayCodigoSkuPlantilla[0].Id,
          PersonaJuridicaClienteId: null,
          PersonaJuridicaClienteNombre: null,
          Descripcion: this.upperCase.transform(this.ArrayCodigoSkuPlantilla[0].Descripcion),
          Codigo: this.ArrayCodigoSkuPlantilla[0].Codigo,
          Sku: this.ArrayCodigoSkuPlantilla[0].Codigo + " - " + this.upperCase.transform(this.ArrayCodigoSkuPlantilla[0].Descripcion),
          GrupoCompraId: null,
          isSelected: true
        }
        this.ListaSkuChequeadosPlantilla.push(SkuEncontrado);
        this.ListaSkuChequeadosPlantilla = Enumerable.from(this.ListaSkuChequeadosPlantilla).orderBy(o => o.Codigo).toArray();

        this.ListaCargaSkuPlantillas.forEach(f => {
          if (f.Codigo == this.ArrayCodigoSkuPlantilla[0].Codigo) {
            f.isSelected = true;
          }
        });
      } else {
        this.ListaSkuChequeadosPlantilla.forEach(f => {
          if (f.Codigo == this.ArrayCodigoSkuPlantilla[0].Codigo) {
            this.DialogService.NotificacionWarning("Sku  " + this.ArrayCodigoSkuPlantilla[0].Descripcion + " ya esta seleccionado");
          } else {
            this.codigoSkuSearchPlantilla = null;
            this.codigoSkuPlantilla = null;

            let SkuEncontrado = {
              Id: this.ArrayCodigoSkuPlantilla[0].Id,
              PersonaJuridicaClienteId: null,
              PersonaJuridicaClienteNombre: null,
              Descripcion: this.upperCase.transform(this.ArrayCodigoSkuPlantilla[0].Descripcion),
              Codigo: this.ArrayCodigoSkuPlantilla[0].Codigo,
              Sku: this.ArrayCodigoSkuPlantilla[0].Codigo + " - " + this.upperCase.transform(this.ArrayCodigoSkuPlantilla[0].Descripcion),
              GrupoCompraId: null,
              isSelected: true
            }
            this.ListaSkuChequeadosPlantilla.push(SkuEncontrado);
            this.ListaSkuChequeadosPlantilla = Enumerable.from(this.ListaSkuChequeadosPlantilla).orderBy(o => o.Codigo).toArray();

            this.ListaCargaSkuPlantillas.forEach(f => {
              if (f.Codigo == this.ArrayCodigoSkuPlantilla[0].Codigo) {
                f.isSelected = true;
              }
            });
          }
        });
      }
    }
  }

  EliminarSkuChequeadoPlantilla(Sku: CargarSkuPorGrupoMaterial) {
    if (this.ListaCargaSkuPlantillas.length == 0) {
      for (var i = 0; i < this.ListaSkuChequeadosPlantilla.length; i++) {
        if (this.ListaSkuChequeadosPlantilla[i].Codigo == Sku.Codigo) {
          this.ListaSkuChequeadosPlantilla.splice(i--, 1);
        }
      }
    } else {
      this.ListaCargaSkuPlantillas.forEach(f => {
        if (f.Codigo == Sku.Codigo) {
          f.isSelected = false;
        }
      });

      for (var i = 0; i < this.ListaSkuChequeadosPlantilla.length; i++) {
        if (this.ListaSkuChequeadosPlantilla[i].Codigo == Sku.Codigo) {
          this.ListaSkuChequeadosPlantilla.splice(i--, 1);
        }
      }
    }
  }
  ///FIN BUSCAR SKU'S POR CODIGO PLANTILLA///

  ///INICIO FUNCIONES PARA SCROLL DE DIVS LINEAS Y MESES-SEMANAS///
  myFunction() {
    let elmnt = document.getElementById("myDIV");
    let elmnt2 = document.getElementById("myDIV2");
    let elmnt3 = document.getElementById("myDIV3"); 

    let x = elmnt.scrollLeft;
    let y = elmnt.scrollTop;
    elmnt2.scrollLeft = x;
    elmnt2.scrollTop = y;

    let x3 = elmnt.scrollLeft;
    let y3 = elmnt.scrollTop;
    elmnt3.scrollLeft = x3;
    elmnt3.scrollTop = y3;
  }

  myFunction2() {
    let elmnt = document.getElementById("myDIV");
    let elmnt2 = document.getElementById("myDIV2");
    let elmnt3 = document.getElementById("myDIV3"); 

    let x = elmnt2.scrollLeft;
    let y = elmnt2.scrollTop;

    elmnt.scrollLeft = x;
    elmnt.scrollTop = y;

    let x3 = elmnt2.scrollLeft;
    let y3 = elmnt2.scrollTop;
    elmnt3.scrollLeft = x3;
    elmnt3.scrollTop = y3;
  }

  myFunction3() {
    let elmnt = document.getElementById("myDIV");
    let elmnt2 = document.getElementById("myDIV2");
    let elmnt3 = document.getElementById("myDIV3");

    let x = elmnt3.scrollLeft;
    let y = elmnt3.scrollTop;
    elmnt.scrollLeft = x;
    elmnt.scrollTop = y;

    let x3 = elmnt3.scrollLeft;
    let y3 = elmnt3.scrollTop;
    elmnt3.scrollLeft = x3;
    elmnt3.scrollTop = y3;
  }
  ///FIN FUNCIONES PARA SCROLL DE DIVS LINEAS Y MESES-SEMANAS///

  ///ABRIR TABLA///
  AbrirTablaCompleta(SkuCodigo: string) {
    let [lista] = this.listaReconstruidoFinal.filter(f => f.SkuCodigo == SkuCodigo);

    if (lista) {
      if (lista.AbrirCerrarTabla == false) {
        lista.AbrirCerrarTabla = true;
        lista.LineasDescripcion[1].MostrarLinea = true;
        lista.LineasDescripcion[2].MostrarLinea = true;
        lista.LineasDescripcion[3].MostrarLinea = true;
        lista.LineasDescripcion[7].MostrarLinea = true;
        lista.LineasDescripcion[8].MostrarLinea = true;

        if (lista.MostrarDetalleIngresos == true) {
          lista.LineasDescripcion[3].CambioImg = true;
        } else if (lista.MostrarDetalleIngresos == false) {
          lista.LineasDescripcion[3].CambioImg = false;
        }
      } else if (lista.AbrirCerrarTabla == true) {
        lista.AbrirCerrarTabla = false;

        lista.LineasDescripcion[1].MostrarLinea = false;
        lista.LineasDescripcion[2].MostrarLinea = false;

        lista.LineasDescripcion[3].MostrarLinea = false;
        lista.LineasDescripcion[3].CambioImg = false;

        //lista.LineasDescripcion[4].MostrarLinea = false;
        //lista.LineasDescripcion[4].CambioImg = false;

        //lista.LineasDescripcion[5].MostrarLinea = false;
        //lista.LineasDescripcion[5].CambioImg = false;

        //lista.LineasDescripcion[6].MostrarLinea = false;
        //lista.LineasDescripcion[6].CambioImg = false;

        lista.LineasDescripcion[7].MostrarLinea = false;
        lista.LineasDescripcion[8].MostrarLinea = false;
      }
    }

    let validarLineasAbiertas = this.listaReconstruidoFinal[0].AbrirCerrarTabla;
    this.validarLineasAbiertas = Enumerable.from(this.listaReconstruidoFinal).any(a => a.AbrirCerrarTabla != validarLineasAbiertas);

    //console.log(this.validarLineasAbiertas);
    //for (var i = 0; i < this.listaReconstruidoFinal.length; i++) {
    //  if (this.listaReconstruidoFinal[i].SkuCodigo == SkuCodigo && this.listaReconstruidoFinal[i].AbrirCerrarTabla == false) {
    //    this.listaReconstruidoFinal[i].AbrirCerrarTabla = true;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[1].MostrarLinea = true;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[2].MostrarLinea = true;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[3].MostrarLinea = true;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[7].MostrarLinea = true;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[8].MostrarLinea = true;
    //  } else if (this.listaReconstruidoFinal[i].SkuCodigo == SkuCodigo && this.listaReconstruidoFinal[i].AbrirCerrarTabla == true) {
    //    this.listaReconstruidoFinal[i].AbrirCerrarTabla = false;

    //    this.listaReconstruidoFinal[i].LineasDescripcion[1].MostrarLinea = false;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[2].MostrarLinea = false;

    //    this.listaReconstruidoFinal[i].LineasDescripcion[3].MostrarLinea = false;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[3].CambioImg = false;

    //    this.listaReconstruidoFinal[i].LineasDescripcion[4].MostrarLinea = false;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[4].CambioImg = false;

    //    this.listaReconstruidoFinal[i].LineasDescripcion[5].MostrarLinea = false;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[5].CambioImg = false;

    //    this.listaReconstruidoFinal[i].LineasDescripcion[6].MostrarLinea = false;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[6].CambioImg = false;

    //    this.listaReconstruidoFinal[i].LineasDescripcion[7].MostrarLinea = false;
    //    this.listaReconstruidoFinal[i].LineasDescripcion[8].MostrarLinea = false;
    //  }
    //}

    //this.listaReconstruidoFinal.forEach(f => {
    //  if (f.AbrirCerrarTabla == false) {
    //    f.AbrirCerrarTabla = true;
    //    //this.ListDetalleSemana[0].CambioImg = true;
    //    this.ListDetalleSemana[1].MostrarLinea = true;
    //    this.ListDetalleSemana[2].MostrarLinea = true;
    //    this.ListDetalleSemana[3].MostrarLinea = true;
    //    this.ListDetalleSemana[7].MostrarLinea = true;
    //    this.ListDetalleSemana[8].MostrarLinea = true;
    //  } else if (f.AbrirCerrarTabla == true) {
    //    f.AbrirCerrarTabla = false;
    //    f.MostrarDetalleIngresos = false;
    //    //this.ListDetalleSemana[0].MostrarLinea = true;
    //    //this.ListDetalleSemana[0].CambioImg = false;
    //    this.ListDetalleSemana[1].MostrarLinea = false;
    //    this.ListDetalleSemana[2].MostrarLinea = false;

    //    this.ListDetalleSemana[3].MostrarLinea = false;
    //    this.ListDetalleSemana[3].CambioImg = false;

    //    this.ListDetalleSemana[4].MostrarLinea = false;
    //    this.ListDetalleSemana[4].CambioImg = false;

    //    this.ListDetalleSemana[5].MostrarLinea = false;
    //    this.ListDetalleSemana[5].CambioImg = false;


    //    this.ListDetalleSemana[6].MostrarLinea = false;
    //    this.ListDetalleSemana[6].CambioImg = false;

    //    this.ListDetalleSemana[7].MostrarLinea = false;
    //    this.ListDetalleSemana[8].MostrarLinea = false;
    //  }
    //})  
  }

  //Abrir y cerrar detalle lineas Gestiones, despachos asignados, despachos sin asignar
  AbrirCerrarIngresos(SkuCodigo: string) {
    let [listaIngresos] = this.listaReconstruidoFinal.filter(f => f.SkuCodigo == SkuCodigo);

    if (listaIngresos) {
      if (listaIngresos.MostrarDetalleIngresos == false) {
        listaIngresos.MostrarDetalleIngresos = true;
        listaIngresos.LineasDescripcion[3].CambioImg = true;

        listaIngresos.LineasDescripcion[4].MostrarLinea = true;
        listaIngresos.LineasDescripcion[5].MostrarLinea = true;
        listaIngresos.LineasDescripcion[6].MostrarLinea = true;
      } else if (listaIngresos.MostrarDetalleIngresos == true) {
        listaIngresos.MostrarDetalleIngresos = false;
        listaIngresos.LineasDescripcion[3].CambioImg = false;

        listaIngresos.LineasDescripcion[4].MostrarLinea = false;
        listaIngresos.LineasDescripcion[5].MostrarLinea = false;
        listaIngresos.LineasDescripcion[6].MostrarLinea = false;
      }
    }

    //this.listaReconstruidoFinal.forEach(f => {
    //  if (f.MostrarDetalleIngresos == false) {
    //    f.MostrarDetalleIngresos = true;
    //    this.ListDetalleSemana[3].CambioImg = true;

    //    this.ListDetalleSemana[4].MostrarLinea = true;
    //    this.ListDetalleSemana[5].MostrarLinea = true;
    //    this.ListDetalleSemana[6].MostrarLinea = true;
    //  } else if (f.MostrarDetalleIngresos == true) {
    //    f.MostrarDetalleIngresos = false;
    //    this.ListDetalleSemana[3].CambioImg = false;

    //    this.ListDetalleSemana[4].MostrarLinea = false;
    //    this.ListDetalleSemana[5].MostrarLinea = false;
    //    this.ListDetalleSemana[6].MostrarLinea = false;
    //  }
    //});

    //for (var i = 0; i < this.listaReconstruidoFinal.length; i++) {
    //    if (this.listaReconstruidoFinal[i].MostrarDetalleIngresos == false && this.ListDetalleSemana[3].CambioImg == false) {
    //      this.listaReconstruidoFinal[i].MostrarDetalleIngresos = true;
    //      this.ListDetalleSemana[3].CambioImg = true;
    //      this.ListDetalleSemana[4].MostrarLinea = true;
    //      this.ListDetalleSemana[5].MostrarLinea = true;
    //      this.ListDetalleSemana[6].MostrarLinea = true;
    //    } else if (this.listaReconstruidoFinal[i].MostrarDetalleIngresos == true && this.ListDetalleSemana[3].CambioImg == true) {
    //      this.listaReconstruidoFinal[i].MostrarDetalleIngresos = false;
    //      this.ListDetalleSemana[3].CambioImg = false;
    //      this.ListDetalleSemana[4].MostrarLinea = false;
    //      this.ListDetalleSemana[5].MostrarLinea = false;
    //      this.ListDetalleSemana[6].MostrarLinea = false;
    //    }     
    //} 
  }

  //Cambiar de mes a semanas
  AbrirSemanasMes(Nmes: number, DetailSemana: boolean) {   

    //for (var i = 0; i < this.listaReconstruidoFinal.length; i++) {
    //  for (var ii = 0; ii < this.listaReconstruidoFinal[i].Meses.length; ii++) {
    //    for (var iii = 0; iii < this.listaReconstruidoFinal[i].Meses[ii].MesesArray.length; iii++) {
    //      if (this.listaReconstruidoFinal[i].Meses[ii].MesesArray[iii].NMes == Nmes && DetailSemana == false) {
    //        this.listaReconstruidoFinal[i].Meses[ii].MesesArray[iii].DetailSemana = true;
    //      } else if (this.listaReconstruidoFinal[i].Meses[ii].MesesArray[iii].NMes == Nmes && DetailSemana == true) {
    //        this.listaReconstruidoFinal[i].Meses[ii].MesesArray[iii].DetailSemana = false;
    //      }
    //    }
    //  }
    //}

    ///FOR PARA ABRIR MESES Y SEMANAS///
    for (var j = 0; j < this.listaMesesYSemanas.length; j++) {
      for (var jj = 0; jj < this.listaMesesYSemanas[j].Meses.length; jj++) {
        if (this.listaMesesYSemanas[j].Meses[jj].Nmes == Nmes && this.listaMesesYSemanas[j].Meses[jj].DetailSemana == false) {
          this.listaMesesYSemanas[j].Meses[jj].DetailSemana = true;
        } else if (this.listaMesesYSemanas[j].Meses[jj].Nmes == Nmes && this.listaMesesYSemanas[j].Meses[jj].DetailSemana == true) {
          this.listaMesesYSemanas[j].Meses[jj].DetailSemana = false;
        }
      }
    }   

    ///FOR PARA ABRIR DETALLE DE MESES Y SEMANAS///
    let verificarMeses: boolean;
    let mesesTablas: boolean;
    let mesesArray = [];

    for (var i = 0; i < this.listaReconstruidoFinal.length; i++) {
      //if (this.listaReconstruidoFinal[i].SkuCodigo == SkuCodigo) {
        let Meses = this.listaReconstruidoFinal[i].Meses;
        for (var j = 0; j < Meses.length; j++) {
          mesesArray = Meses[j].MesesArray;
          for (var k = 0; k < mesesArray.length; k++) {
            if (mesesArray[k].NMes == Nmes) {
              verificarMeses = Enumerable.from(mesesArray).any(a => a.DetailSemana != DetailSemana);

              if (verificarMeses == true) {
                if (mesesArray[k].DetailSemana == false) {
                  mesesArray[k].DetailSemana = true;
                } else if (mesesArray[k].DetailSemana == true) {
                  mesesArray[k].DetailSemana = false;
                }
              } else if (verificarMeses == false) {
                if (mesesArray[k].DetailSemana == false) {
                  mesesArray[k].DetailSemana = true;
                } else if (mesesArray[k].DetailSemana == true) {
                  mesesArray[k].DetailSemana = false;
                }
              }

            }
          }
        }

        mesesTablas = Enumerable.from(mesesArray).any(a => a.DetailSemana != false);
        if (mesesTablas == true) {
          this.listaReconstruidoFinal[i].MostrarOcultarLineasTabla = true;
        } else if (mesesTablas == false) {
          this.listaReconstruidoFinal[i].MostrarOcultarLineasTabla = false;
        }       

      //}
    }

    ///FOR PARA ABRIR MESES Y SEMANAS DE SUMAS TOTALES///
    for (var m = 0; m < this.listaSumasTotales[0].Totales[0].Meses.length; m++) {
      if (this.listaSumasTotales[0].Totales[0].Meses[m].Nmes == Nmes) {
        if (this.listaSumasTotales[0].Totales[0].Meses[m].DetailSemana == false) {
          this.listaSumasTotales[0].Totales[0].Meses[m].DetailSemana = true;
        } else if (this.listaSumasTotales[0].Totales[0].Meses[m].DetailSemana == true) {
          this.listaSumasTotales[0].Totales[0].Meses[m].DetailSemana = false;
        }
      }
    }

    //let validarMes = this.listaMesesYSemanas[0].Meses[0].DetailSemana; 
    //this.validarDivs = Enumerable.from(this.listaMesesYSemanas).selectMany(s => s.Meses).any(ss => ss.DetailSemana != validarMes);
    //console.log(this.validarDivs);
  }

  //ABRIR RESTO DE LINEAS TOTALES(SUMATORIA)
  AbrirTotalesLineas() {
    if (this.listaSumasTotales[0].AbrirTotales == false) {
      this.listaSumasTotales[0].AbrirTotales = true;
    } else if (this.listaSumasTotales[0].AbrirTotales == true) {
      this.listaSumasTotales[0].AbrirTotales = false;
    }
  }
   
  //Chequear Sku para dividir sku

  //Abrir modal para dividir sku y crear despachos
  ListSkuaDividir: Array<SkuaDividir> = [];
  FechaSalidaOrigen: string;
  FechaRequeridaCliente: string;
  FechaSalidaOrigenDespacho: string;
  FechaRequeridaClienteDespacho: string;
  ContarListRequerimientosSku: number;
  ValidabrirModalDespachos: number = 0;
  checkDivirdirSku() {

    let Skuactual: Array<SkuaDividir> = [];
    this.FechaRequeridaCliente = moment().format('YYYY-MM-DD');
    this.FechaSalidaOrigen = moment().format('YYYY-MM-DD');

    Skuactual = this.myGridAsignarCantidadaSku.myGridAsignarCantidadaSku.getboundrows();

    if (Skuactual.length == 0) {
      this.DialogService.NotificacionWarning("No ha seleccionado ningun sku");
    } else {

      Skuactual.forEach(f => {
        if (f.Cantidad == null || f.Cantidad.toString() == "" || f.Cantidad == undefined) {
          this.DialogService.NotificacionWarning(f.Sku + " la cantidad no puede estar vacía");
          this.ValidabrirModalDespachos = 0;
        } else {
          this.ValidabrirModalDespachos = 1;
        }
      });

      if (this.ValidabrirModalDespachos == 1) {
        this.ListSkuaDividir = Enumerable.from(Skuactual).select(s => {
          return {
            Id: s.Id,
            SkuId: s.SkuId,
            Sku: s.Sku,
            Cantidad: s.Cantidad,
            CantidadIngresada: s.CantidadIngresada
          }
        }).orderBy(o => o.Sku).toArray();
        this.ListaRequerimientosParaDespachos = [];
        this.ListaDefinitivaDespachos = [];
        this.ContarListRequerimientosSku = Enumerable.from(this.ListSkuaDividir).select(s => s.Cantidad).sum();

        this.myGridDividirSku.source.localdata = this.ListSkuaDividir;
        this.myGridDividirSku.dataAdapter.dataBind();

        $(this.myGridDividirSku.myGridDividirSku.elementRef.nativeElement).show();
        this.modalDividirSku.showModal().subscribe(response => {
          $(this.myGridDividirSku.myGridDividirSku.elementRef.nativeElement).hide();
        });
      }
    }
  }

  //Dividir sku en despachos
  IdGeneral: number;
  IdDetalle: number;
  ListaRequerimientosParaDespachos: Array<RequerimientoParaDespacho> = [];
  ContarListDivididos: number;
  RequerimientosADividir(RegistroAdd: SkuaDividir) {
    if (this.ListaRequerimientosParaDespachos.length == 0) {
      this.DialogService.ShowConfirm("¿Esta seguro de la Fecha Requerida Cliente y la Fecha Salida Origen?", "")
        .subscribe(response => {
          if (response) {
            let IdMaster = this.ListaDefinitivaDespachos.length;

            if (IdMaster == 0) {
              this.IdGeneral = 1;
              this.IdDetalle = 1;
              let hora = moment().format('HH:mm:ss');
              let Detalle: Array<DetalleRequerimientoParaDespacho> = []
              let arrayEncontrado: Array<SkuaDividir> = [];
              let arrayNoEncontrados: Array<SkuaDividir> = [];
              let obj = { Id: this.IdDetalle, SkuId: RegistroAdd.SkuId, SkuDescripcion: RegistroAdd.Sku, Cantidad: RegistroAdd.CantidadIngresada };
              Detalle.push(obj);

              this.ListaRequerimientosParaDespachos.push({
                Id: this.IdGeneral,
                FechaRequeridaClienteDespacho: this.FechaRequeridaCliente + " " + hora,
                FechaSalidaOrigenDespacho: this.FechaSalidaOrigen + " " + hora,
                DetalleDespacho: Detalle
              });

              //Recalcular la cantidad despacho
              for (var i = 0; i < this.ListSkuaDividir.length; i++) {
                if (this.ListSkuaDividir[i].SkuId == RegistroAdd.SkuId) {
                  arrayEncontrado.push({
                    Id: this.ListSkuaDividir[i].Id,
                    SkuId: this.ListSkuaDividir[i].SkuId,
                    Sku: this.ListSkuaDividir[i].Sku,
                    Cantidad: this.ListSkuaDividir[i].Cantidad - RegistroAdd.CantidadIngresada,
                    CantidadIngresada: null
                  })
                }
              }

              arrayNoEncontrados = Enumerable.from(this.ListSkuaDividir).where(w => w.SkuId != RegistroAdd.SkuId).select(j => {
                return {
                  Id: j.Id,
                  SkuId: j.SkuId,
                  Sku: j.Sku,
                  Cantidad: j.Cantidad,
                  CantidadIngresada: null
                }
              }).toArray();

              if (arrayNoEncontrados.length == 0) {
                this.ListSkuaDividir = Enumerable.from(arrayEncontrado).orderBy(o => o.Sku).toArray();
              } else {
                this.ListSkuaDividir = Enumerable.from(arrayEncontrado).concat(arrayNoEncontrados).orderBy(o => o.Sku).toArray();
              }

              this.ContarListRequerimientosSku = Enumerable.from(this.ListSkuaDividir).select(s => s.Cantidad).sum();
              this.ContarListDivididos = Enumerable.from(this.ListaRequerimientosParaDespachos).selectMany(s => s.DetalleDespacho).select(ss => ss.Cantidad).sum();
              this.myGridDividirSku.source.localdata = Enumerable.from(this.ListSkuaDividir).orderBy(o => o.Sku).toArray();
              this.myGridDividirSku.dataAdapter.dataBind();

            } else {
              this.IdGeneral = IdMaster + 1;
              this.IdDetalle = 1;
              let hora = moment().format('HH:mm:ss');
              let Detalle: Array<DetalleRequerimientoParaDespacho> = []
              let arrayEncontrado: Array<SkuaDividir> = [];
              let arrayNoEncontrados: Array<SkuaDividir> = [];
              let obj = { Id: this.IdDetalle, SkuId: RegistroAdd.SkuId, SkuDescripcion: RegistroAdd.Sku, Cantidad: RegistroAdd.CantidadIngresada };
              Detalle.push(obj);

              this.ListaRequerimientosParaDespachos.push({
                Id: this.IdGeneral,
                FechaRequeridaClienteDespacho: this.FechaRequeridaCliente + " " + hora,
                FechaSalidaOrigenDespacho: this.FechaSalidaOrigen + " " + hora,
                DetalleDespacho: Detalle
              });

              //Recalcular la cantidad despacho
              for (var i = 0; i < this.ListSkuaDividir.length; i++) {
                if (this.ListSkuaDividir[i].Id == RegistroAdd.Id) {
                  arrayEncontrado.push({
                    Id: this.ListSkuaDividir[i].Id,
                    SkuId: this.ListSkuaDividir[i].SkuId,
                    Sku: this.ListSkuaDividir[i].Sku,
                    Cantidad: this.ListSkuaDividir[i].Cantidad - RegistroAdd.CantidadIngresada,
                    CantidadIngresada: null
                  })
                }
              }

              arrayNoEncontrados = Enumerable.from(this.ListSkuaDividir).where(w => w.SkuId != RegistroAdd.SkuId).select(j => {
                return {
                  Id: j.Id,
                  SkuId: j.SkuId,
                  Sku: j.Sku,
                  Cantidad: j.Cantidad,
                  CantidadIngresada: null
                }
              }).toArray();

              if (arrayNoEncontrados.length == 0) {
                this.ListSkuaDividir = Enumerable.from(arrayEncontrado).orderBy(o => o.Sku).toArray();
              } else {
                this.ListSkuaDividir = Enumerable.from(arrayEncontrado).concat(arrayNoEncontrados).orderBy(o => o.Sku).toArray();
              }

              this.ContarListRequerimientosSku = Enumerable.from(this.ListSkuaDividir).select(s => s.Cantidad).sum();
              this.ContarListDivididos = Enumerable.from(this.ListaRequerimientosParaDespachos).selectMany(s => s.DetalleDespacho).select(ss => ss.Cantidad).sum();
              this.myGridDividirSku.source.localdata = Enumerable.from(this.ListSkuaDividir).orderBy(o => o.Sku).toArray();
              this.myGridDividirSku.dataAdapter.dataBind();
            }
          }
        });
    }
    else {
      let DetalleActual: Array<DetalleRequerimientoParaDespacho> = [];
      let arrayEncontrado: Array<SkuaDividir> = [];
      let arrayNoEncontrados: Array<SkuaDividir> = [];
      let Idprincipal = Enumerable.from(this.ListaRequerimientosParaDespachos).select(s => s.Id).last();
      let IdaSeguir: number;
      let IdaSeguisList: number;

      if (this.IdGeneral == Idprincipal) {
        this.ListaRequerimientosParaDespachos.forEach(f => {
          DetalleActual = f.DetalleDespacho;
          f.DetalleDespacho.forEach(ff => {
            IdaSeguir = ff.Id;
          })
        })

        IdaSeguisList = IdaSeguir + 1;

        let Detalle: Array<DetalleRequerimientoParaDespacho> = []
        let DetalleTotal: Array<DetalleRequerimientoParaDespacho> = [];
        let obj = { Id: IdaSeguisList, SkuId: RegistroAdd.SkuId, SkuDescripcion: RegistroAdd.Sku, Cantidad: RegistroAdd.CantidadIngresada };
        Detalle.push(obj);
        DetalleTotal = Enumerable.from(DetalleActual).concat(Detalle).orderBy(o => o.Id).toArray();

        this.ListaRequerimientosParaDespachos = Enumerable.from(this.ListaRequerimientosParaDespachos).select(s => {
          return {
            Id: s.Id,
            FechaRequeridaClienteDespacho: s.FechaRequeridaClienteDespacho,
            FechaSalidaOrigenDespacho: s.FechaSalidaOrigenDespacho,
            DetalleDespacho: DetalleTotal
          }
        }).toArray();

        //Recalcular la cantidad despacho
        for (var i = 0; i < this.ListSkuaDividir.length; i++) {
          if (this.ListSkuaDividir[i].Id == RegistroAdd.Id) {
            arrayEncontrado.push({
              Id: this.ListSkuaDividir[i].Id,
              SkuId: this.ListSkuaDividir[i].SkuId,
              Sku: this.ListSkuaDividir[i].Sku,
              Cantidad: this.ListSkuaDividir[i].Cantidad - RegistroAdd.CantidadIngresada,
              CantidadIngresada: null
            })
          }
        }

        arrayNoEncontrados = Enumerable.from(this.ListSkuaDividir).where(w => w.SkuId != RegistroAdd.SkuId).select(j => {
          return {
            Id: j.Id,
            SkuId: j.SkuId,
            Sku: j.Sku,
            Cantidad: j.Cantidad,
            CantidadIngresada: null
          }
        }).toArray();

        if (arrayNoEncontrados.length == 0) {
          this.ListSkuaDividir = Enumerable.from(arrayEncontrado).orderBy(o => o.Sku).toArray();
        } else {
          this.ListSkuaDividir = Enumerable.from(arrayEncontrado).concat(arrayNoEncontrados).orderBy(o => o.Sku).toArray();
        }

        this.ContarListRequerimientosSku = Enumerable.from(this.ListSkuaDividir).select(s => s.Cantidad).sum();
        this.ContarListDivididos = Enumerable.from(this.ListaRequerimientosParaDespachos).selectMany(s => s.DetalleDespacho).select(ss => ss.Cantidad).sum();
        this.myGridDividirSku.source.localdata = Enumerable.from(this.ListSkuaDividir).orderBy(o => o.Sku).toArray();
        this.myGridDividirSku.dataAdapter.dataBind();
      }
    }
  }

  //Agregar despacho
  ListaDefinitivaDespachos: Array<RequerimientoParaDespacho> = [];
  AddPreviewDespachos() {
    let ListaDespachosPreview: Array<RequerimientoParaDespacho> = [];
    let arrayEncontrado: Array<RequerimientoParaDespacho> = [];
    let IdMaster = Enumerable.from(this.ListaRequerimientosParaDespachos).select(s => s.Id).first();

    if (this.ListaDefinitivaDespachos.length == 0) {
      this.ListaDefinitivaDespachos = this.ListaRequerimientosParaDespachos;
      this.ListaRequerimientosParaDespachos = [];
    } else {
      for (var i = 0; i < this.ListaDefinitivaDespachos.length; i++) {
        if (this.ListaDefinitivaDespachos[i].Id != IdMaster) {
          ListaDespachosPreview = this.ListaRequerimientosParaDespachos;
        }
      }

      arrayEncontrado = Enumerable.from(this.ListaDefinitivaDespachos).select(s => {
        return {
          Id: s.Id,
          FechaRequeridaClienteDespacho: s.FechaRequeridaClienteDespacho,
          FechaSalidaOrigenDespacho: s.FechaSalidaOrigenDespacho,
          DetalleDespacho: s.DetalleDespacho
        }
      }).toArray();

      this.ListaDefinitivaDespachos = Enumerable.from(arrayEncontrado).concat(ListaDespachosPreview).orderBy(o => o.Id).toArray();
      this.ListaRequerimientosParaDespachos = [];
    }
  }

  //Eliminar despacho total
  EliminarPreviewDespachos() {
    let ListaRegresar: Array<DetalleRequerimientoParaDespacho> = [];
    let arrayEncontrado: Array<SkuaDividir> = [];
    let arrayNoEncontrados: Array<SkuaDividir> = [];

    this.ListaRequerimientosParaDespachos.forEach(f => {
      ListaRegresar = f.DetalleDespacho;
    });

    this.ListSkuaDividir.forEach(g => {
      ListaRegresar.forEach(gg => {
        if (g.SkuId == gg.SkuId) {
          arrayEncontrado.push({
            Id: g.Id,
            SkuId: g.SkuId,
            Sku: g.Sku,
            Cantidad: g.Cantidad + gg.Cantidad,
            CantidadIngresada: null
          })
        } else if (g.SkuId != gg.SkuId) {
          arrayNoEncontrados.push({
            Id: g.Id,
            SkuId: g.SkuId,
            Sku: g.Sku,
            Cantidad: gg.Cantidad,
            CantidadIngresada: null
          })
        }
      });
    });
    let x = Enumerable.from(arrayEncontrado).orderBy(o => o.Sku).toArray();
    let y = Enumerable.from(arrayNoEncontrados).orderBy(o => o.Sku).toArray();

    this.ListSkuaDividir = Enumerable.from(x).concat(y).toArray();
    this.ContarListRequerimientosSku = Enumerable.from(this.ListSkuaDividir).select(s => s.Cantidad).sum();
    this.ContarListDivididos = Enumerable.from(this.ListaRequerimientosParaDespachos).selectMany(s => s.DetalleDespacho).select(ss => ss.Cantidad).sum();
    this.myGridDividirSku.source.localdata = Enumerable.from(this.ListSkuaDividir).orderBy(o => o.Sku).toArray();
    this.myGridDividirSku.dataAdapter.dataBind();

    this.ListaRequerimientosParaDespachos = [];
  }

  //Eliminar detalle de crear preview despacho
  DeleteItem(event: DetalleRequerimientoParaDespacho) {
    let arrayEncontrado: Array<SkuaDividir> = [];
    let arrayNoEncontrados: Array<SkuaDividir> = [];
    let quitarDetalle: Array<DetalleRequerimientoParaDespacho> = [];

    this.ListaRequerimientosParaDespachos.forEach(f => {
      quitarDetalle = f.DetalleDespacho;
      quitarDetalle.forEach((item) => {
        if (item.Id == event.Id) {
          quitarDetalle.splice(quitarDetalle.indexOf(item), 1);

          if (quitarDetalle.length == 0) {
            this.ListaRequerimientosParaDespachos = [];
          } else {
            this.ListaRequerimientosParaDespachos = Enumerable.from(this.ListaRequerimientosParaDespachos).select(s => {
              return {
                Id: f.Id,
                FechaRequeridaClienteDespacho: f.FechaRequeridaClienteDespacho,
                FechaSalidaOrigenDespacho: f.FechaSalidaOrigenDespacho,
                DetalleDespacho: quitarDetalle
              }
            }).orderBy(o => o.Id).toArray();
          }
        }
      })

      arrayEncontrado = Enumerable.from(this.ListSkuaDividir).where(w => w.SkuId == event.SkuId).select(s => {
        return {
          Id: s.Id,
          SkuId: s.SkuId,
          Sku: s.Sku,
          Cantidad: s.Cantidad + event.Cantidad,
          CantidadIngresada: null
        }
      }).orderBy(o => o.Sku).toArray();
    })

    arrayNoEncontrados = Enumerable.from(this.ListSkuaDividir).where(w => w.SkuId != event.SkuId).select(s => {
      return {
        Id: s.Id,
        SkuId: s.SkuId,
        Sku: s.Sku,
        Cantidad: s.Cantidad,
        CantidadIngresada: null
      }
    }).orderBy(o => o.Sku).toArray();

    if (arrayNoEncontrados.length == 0) {
      this.ListSkuaDividir = Enumerable.from(arrayEncontrado).orderBy(o => o.Sku).toArray();
    } else {
      this.ListSkuaDividir = Enumerable.from(arrayEncontrado).concat(arrayNoEncontrados).orderBy(o => o.Sku).toArray();
    }

    this.ContarListRequerimientosSku = Enumerable.from(this.ListSkuaDividir).select(s => s.Cantidad).sum();
    this.ContarListDivididos = Enumerable.from(this.ListaRequerimientosParaDespachos).selectMany(s => s.DetalleDespacho).select(ss => ss.Cantidad).sum();
    this.myGridDividirSku.source.localdata = Enumerable.from(this.ListSkuaDividir).orderBy(o => o.Sku).toArray();
    this.myGridDividirSku.dataAdapter.dataBind();

    this.ListaDefinitivaDespachos;
  }

  //Abrir detalle Sku
  ListaNuevaDetalleSku: Array<detailsSku> = [];
  DetalleSku(Id: number) {
    //this.myGridDetalleSku.myGridDetalleSku.clear();
    //this.ListaNuevaDetalleSku = Enumerable.from(this.ListaDetalleSku).where(w => w.Id == Id).select(s => {
    //  return {
    //    Id: s.Id,
    //    CodigoSku: s.CodigoSku,
    //    Descripcion: s.Descripcion,
    //    Semana: s.Semana,
    //    Clasificacion: s.Clasificacion,
    //    Planta: s.Planta,
    //    Unidad: s.Unidad,
    //    VariacionConsumo: s.VariacionConsumo,
    //    VariacionGestiones: s.VariacionGestiones,
    //    VariacionesPlanificacion: s.VariacionesPlanificacion
    //  }
    //}).toArray();

    //this.myGridDetalleSku.source.localdata = this.ListaNuevaDetalleSku;
    //this.myGridDetalleSku.dataAdapter.dataBind();

    //$(this.myGridDetalleSku.myGridDetalleSku.elementRef.nativeElement).show();
    //this.modalDetalleSku.showModal().subscribe(response => {
    //  $(this.myGridDetalleSku.myGridDetalleSku.elementRef.nativeElement).hide();
    //});

  }

  //Ver gestiones por sku
  //GestionesFilterSku: Array<GestionesPorSku> = []
  //GestionesFilterSkuMetodo: Array<GestionesModal> = []
  NombreSkuGestion: string;
  verGestionesSku(SkuId: string, Sku: string) {
    //this.GestionesFilterSkuMetodo = [];
    //this.GestionesFilterSku = [];
    //this.ListaRespuestaMetodo.forEach(f => {
    //  f.UpiBySemanaBySku.forEach(ff => {
    //    if (ff.SkuCodigo == "1001551") {
    //      let obj = {
    //        Semana: f.Semana,
    //        Gestiones: ff.Gestiones
    //      }
    //      this.GestionesFilterSkuMetodo.push(obj);
    //    }
    //  })
    //})

    //this.ListaRespuestaMetodo.forEach(f => {
    //  f.UpiBySemanaBySku.forEach(ff => {
    //    if (ff.SkuCodigo == SkuId) {
    //      ff.Gestiones.forEach(fff => {
    //        let obj = {
    //          SitioId: fff.SitioId,
    //          Sku: fff.Sku,
    //          Unidades: fff.Unidades,
    //          CodigoGestion: fff.CodigoGestion,
    //          Fecha: fff.Fecha,
    //          GetSemana: "Semana " + fff.GetSemana,
    //          Mes: Enumerable.from(this.MesesAnio).where(w => w.Id == f.Mes).select(s => s.Mes).firstOrDefault()
    //        }

    //        this.GestionesFilterSkuMetodo.push(obj);
    //      })
    //    }
    //  })
    //})

    //this.NombreSkuGestion = Sku;
    //this.myGridGestiones.source.localdata = Enumerable.from(this.GestionesFilterSkuMetodo).orderBy(o => o.GetSemana).toArray();
    //this.myGridGestiones.dataAdapter.dataBind();

    //$(this.myGridGestiones.myGridGestiones.elementRef.nativeElement).show();
    //this.modalGestiones.showModal().subscribe(response => {
    //  $(this.myGridGestiones.myGridGestiones.elementRef.nativeElement).hide();
    //});
  }

  //Ver despachos por sku
  //DespachosFilterSkuMetodo: Array<DespachosModal> = [];
  //DespachosTorreFilterSkuMetodo: Array<DespachosModal> = [];
  //NombreSkuDespacho: string;
  //verDespachosSku(SkuId: string, Sku: string) {

  //  this.DespachosTorreFilterSkuMetodo = [];
  //  this.DespachosFilterSkuMetodo = [];

  //  this.ListaRespuestaMetodo.forEach(f => {
  //    f.UpiBySemanaBySku.forEach(ff => {
  //      if (ff.SkuCodigo == SkuId) {
  //        ff.Despachos.forEach(fff => {
  //          let obj = {
  //            Id: fff.Id,
  //            Cantidad: fff.Cantidad,
  //            CodigoDespacho: fff.CodigoDespacho,
  //            Fecha: fff.Fecha,
  //            GetSemana: "Semana " + fff.GetSemana,
  //            Mes: Enumerable.from(this.MesesAnio).where(w => w.Id == f.Mes).select(s => s.Mes).firstOrDefault(),
  //            DespachoDetalle: fff.DespachoDetalle
  //          }

  //          this.DespachosFilterSkuMetodo.push(obj);
  //        })
  //      }
  //    })
  //  });

  //  this.ListaRespuestaMetodo.forEach(l => {
  //    l.UpiBySemanaBySku.forEach(ll => {
  //      if (ll.SkuCodigo == SkuId) {
  //        ll.DespachosTorre.forEach(lll => {
  //          let obj = {
  //            Id: lll.Id,
  //            Cantidad: lll.Cantidad,
  //            CodigoDespacho: lll.CodigoDespacho,
  //            Fecha: lll.Fecha,
  //            GetSemana: "Semana " + lll.GetSemana,
  //            Mes: Enumerable.from(this.MesesAnio).where(w => w.Id == l.Mes).select(s => s.Mes).firstOrDefault(),
  //            DespachoDetalle: lll.DespachoDetalle
  //          }

  //          this.DespachosTorreFilterSkuMetodo.push(obj);
  //        })
  //      }
  //    })
  //  });

  //  this.NombreSkuDespacho = Sku;

  //  this.myGridDespachosSku.source.localdata = Enumerable.from(this.DespachosFilterSkuMetodo).orderBy(o => o.GetSemana).toArray();
  //  this.myGridDespachosSku.dataAdapter.dataBind();

  //  this.myGridDespachosTorresSku.source.localdata = Enumerable.from(this.DespachosTorreFilterSkuMetodo).orderBy(o => o.GetSemana).toArray();
  //  this.myGridDespachosTorresSku.dataAdapter.dataBind();

  //  console.log(this.DespachosFilterSkuMetodo);
  //  console.log(this.DespachosTorreFilterSkuMetodo);
  //  //$(this.myGridGestiones.myGridGestiones.elementRef.nativeElement).show();
  //  //this.modalGestiones.showModal().subscribe(response => {
  //  //  $(this.myGridGestiones.myGridGestiones.elementRef.nativeElement).hide();
  //  //});
  //  this.modalDespachos.showModal();
  //}

  VerDespachoDetalle(Id: string) {

  }

  //Buscar filtros

  //change filtro Sku
  ChangeSkuNull() {
    //if (this.skuCheck == null) {
    //  this.listaTest = this.ListaPermanente;
    //}
  }

  BuscarFiltros() {
    //this.listaTest = Enumerable.from(this.listaTest).where(w => w.SkuId == this.skuCheck.SkuId).select(s => {
    //  return {
    //    Id: s.Id,
    //    SkuId: s.SkuId,
    //    Sku: s.Sku,
    //    MostrarDetalle: s.MostrarDetalle,
    //    MostrarLineas: s.MostrarLineas,
    //    InventarioSeguridad: s.InventarioSeguridad,
    //    Meses: s.Meses
    //  }
    //}).toArray();
  }

  //KeyUp(buscar) Sku X categorias
  search: string = null;
  searchSnapshot(name: string) {
    this.search = name;
  }

  //KeyUp(buscar) Sku tabla
  searchCheckSku: string = null;
  searchSnapshotCheckSku(name: string) {
    this.searchCheckSku = name;
  }

  ClearInputCheckSku() {
    this.searchCheckSku = null;
    this.ListaCargaSku = this.ListaCargaSku;
  }

  //KeyUp(buscar) Sku plantilla
  searchCheckSkuPlantilla: string = null;
  searchSnapshotCheckSkuPlantilla(name: string) {
    this.searchCheckSkuPlantilla = name;
  }

  ClearInputCheckSkuPlantilla() {
    this.searchCheckSkuPlantilla = null;
    this.ListaCargaSkuPlantillas = this.ListaCargaSkuPlantillas;
  }

  //KeyUp(buscar) Sku Editar plantilla
  searchCheckSkuEditarPlantilla: string = null;
  searchSnapshotCheckSkuEditarPlantilla(name: string) {
    this.searchCheckSkuEditarPlantilla = name;
  }

  ClearInputCheckSkuEditarPlantilla() {
    this.searchCheckSkuEditarPlantilla = null;
    this.ListaSkuEditarPlantilla = this.ListaSkuEditarPlantilla;
  }

  //Abrir y cerrar tabla detalle
  //arrayEncontrado: Array<listaTest> = [];
  //arrayNoEncontrado: Array<listaTest> = [];
  //abrirTabla(SkuId: number, MostrarLineas: boolean) {
  //  this.arrayEncontrado = [];
  //  this.arrayNoEncontrado = [];

  //  //this.arrayEncontrado = Enumerable.from(this.listaTest).where(w => w.SkuId == SkuId).select(s => {
  //  //  return {
  //  //    Id: s.Id,
  //  //    SkuId: s.SkuId,
  //  //    Sku: s.Sku,
  //  //    MostrarDetalle: s.MostrarDetalle,
  //  //    MostrarLineas: MostrarLineas == true ? s.MostrarLineas = false : MostrarLineas == false ? s.MostrarLineas = true: false,
  //  //    InventarioSeguridad: s.InventarioSeguridad,
  //  //    Meses: s.Meses
  //  //  }
  //  //}).toArray();

  //  //this.arrayNoEncontrado = Enumerable.from(this.listaTest).where(w => w.SkuId != SkuId).select(s => {
  //  //  return {
  //  //    Id: s.Id,
  //  //    SkuId: s.SkuId,
  //  //    Sku: s.Sku,
  //  //    MostrarDetalle: s.MostrarDetalle,
  //  //    MostrarLineas: s.MostrarLineas,
  //  //    InventarioSeguridad: s.InventarioSeguridad,
  //  //    Meses: s.Meses
  //  //  }
  //  //}).toArray();

  //  //this.listaTest = Enumerable.from(this.arrayEncontrado).concat(this.arrayNoEncontrado).orderBy(o => o.SkuId).toArray();

  //  this.arrayEncontrado = Enumerable.from(this.ListaEnDuroMetodo).where(w => w.SkuId == SkuId).select(s => {
  //    return {
  //      Id: s.Id,
  //      SkuId: s.SkuId,
  //      Sku: s.Sku,
  //      MostrarDetalle: s.MostrarDetalle,
  //      MostrarLineas: MostrarLineas == true ? s.MostrarLineas = false : MostrarLineas == false ? s.MostrarLineas = true : false,
  //      InventarioSeguridad: s.InventarioSeguridad,
  //      Meses: s.Meses
  //    }
  //  }).toArray();

  //  this.arrayNoEncontrado = Enumerable.from(this.ListaEnDuroMetodo).where(w => w.SkuId != SkuId).select(s => {
  //    return {
  //      Id: s.Id,
  //      SkuId: s.SkuId,
  //      Sku: s.Sku,
  //      MostrarDetalle: s.MostrarDetalle,
  //      MostrarLineas: s.MostrarLineas,
  //      InventarioSeguridad: s.InventarioSeguridad,
  //      Meses: s.Meses
  //    }
  //  }).toArray();

  //  this.ListaEnDuroMetodo = Enumerable.from(this.arrayEncontrado).concat(this.arrayNoEncontrado).orderBy(o => o.SkuId).toArray();
  //}

  //Limpiar filtros
  LimpiarFiltros() {
    this.centroUpi = null;
    this.tipoMaterialUpi = null;

    this.grupoMaterialUpi = null;
    this.ListaGrupoMateriales = [];

    this.ListaCargaSku = [];
    this.ListaSkuChequeadosProveedor = [];
  }

  //Ver plantillas
  verPlantillas() {
    this.modalVerPlantillas.showModal();
  }

  mostrarPlantilla(Id: number) {
    //this.Plantillas.forEach(f => {
    //  if (f.Id == Id) {
    //    //this.ProveedorPrincipal = Enumerable.from(this.ProveedoresPrincipales).where(w => w.PersonaProveedorId == f.Proveedor.PersonaProveedorId).firstOrDefault();
    //    this.PlantaPrincipal = Enumerable.from(this.PlantasPrincipales).where(w => w.Id == f.Planta.Id).firstOrDefault();

    //    //if (this.ProveedorPrincipal != null) {
    //      //this.validListaSkuProveedores = null;
    //      //this.CallHttp.httpGet<Array<SkyByProveedor>>(environment.webServices.ppm + "Mants/Personas/Home/LoadSkuProveedorByPersonaProveedor?ProveedorId=" + this.ProveedorPrincipal.PersonaProveedorId, null)
    //      //  .subscribe(response => {
    //      //    if (response.length == 0) {
    //      //      this.validListaSkuProveedores = 1;
    //      //    } else {
    //      //      this.validListaSkuProveedores = 2;
    //      //      this.ListaSkuxProveedor = Enumerable.from(response).select(s => {
    //      //        return {
    //      //          Id: s.Id,
    //      //          Descripcion: s.Codigo + " - " + s.Descripcion,
    //      //          Codigo: s.Codigo,
    //      //          isSelected: false
    //      //        }
    //      //      }).orderBy(o => o.Codigo).toArray();
    //      //    }

    //      //  }, error => {
    //      //    this.DialogService.NotificacionAlert(error);
    //      //  });
    //    //} else {
    //    //  this.ListaSkuxProveedor = [];
    //    //  this.validListaSkuProveedores = 0;
    //    //}

    //    f.Detalle.forEach(ff => {
    //      //Combo Uno
    //      let cue = Enumerable.from(this.ComboUno).where(w => w.Id == ff.ComboUno.Id).select(s => {
    //        return {
    //          Id: s.Id,
    //          Descripcion: s.Descripcion,
    //          Estado: true,
    //          NCombo: s.NCombo
    //        }
    //      }).toArray();

    //      let cune = Enumerable.from(this.ComboUno).where(w => w.Id != ff.ComboUno.Id).select(s => {
    //        return {
    //          Id: s.Id,
    //          Descripcion: s.Descripcion,
    //          Estado: false,
    //          NCombo: s.NCombo
    //        }
    //      }).toArray();

    //      this.ComboUno = Enumerable.from(cue).concat(cune).orderBy(o => o.Id).toArray();

    //      //Combo Dos
    //    //  let cde = Enumerable.from(this.ComboDos).where(w => w.Id == ff.ComboDos.Id).select(s => {
    //    //  return {
    //    //    Id: s.Id,
    //    //    Descripcion: s.Descripcion,
    //    //    Estado: true,
    //    //    NCombo: s.NCombo
    //    //  }
    //    //}).toArray();

    //    //  let cdne = Enumerable.from(this.ComboDos).where(w => w.Id != ff.ComboDos.Id).select(s => {
    //    //  return {
    //    //    Id: s.Id,
    //    //    Descripcion: s.Descripcion,
    //    //    Estado: false,
    //    //    NCombo: s.NCombo
    //    //  }
    //    //}).toArray();

    //    //this.ComboDos = Enumerable.from(cde).concat(cdne).orderBy(o => o.Id).toArray();

    //    //Combo Tres
    //    //  let cte = Enumerable.from(this.ComboTres).where(w => w.Id == ff.ComboTres.Id).select(s => {
    //    //  return {
    //    //    Id: s.Id,
    //    //    Descripcion: s.Descripcion,
    //    //    Estado: true,
    //    //    NCombo: s.NCombo
    //    //  }
    //    //}).toArray();

    //    //  let ctne = Enumerable.from(this.ComboTres).where(w => w.Id != ff.ComboTres.Id).select(s => {
    //    //  return {
    //    //    Id: s.Id,
    //    //    Descripcion: s.Descripcion,
    //    //    Estado: false,
    //    //    NCombo: s.NCombo
    //    //  }
    //    //}).toArray();

    //    //  this.ComboTres = Enumerable.from(cte).concat(ctne).orderBy(o => o.Id).toArray();


    //    })        
    //  }
    //})

    this.modalVerPlantillas.closeModal();
  }

  ChangeComboUno(Id: number) {
    this.CheckComboUno = Enumerable.from(this.ComboUno).where(w => w.Id == Id).firstOrDefault();
    
    //this.CheckComboUno = Enumerable.from(this.ComboUno).where(w => w.Id == Id).firstOrDefault();
    //if (this.listaReconstruidoFinal.length == 0) {
    //  this.CheckComboUno = Enumerable.from(this.ComboUno).where(w => w.Id == Id).firstOrDefault();
    //} else if (this.listaReconstruidoFinal.length != 0) {
    //  this.BuscarSku();
    //}
  }

  //Abrir plantilla modal
  descripcionPlantilla: string = null;
  cantidadMesesPlantilla: number = null;
  BtnCrearPlantilla() {
    //Variable Nueva Plantilla
    this.centroPlantilla = null;
    this.tipoMaterialUpiPlantilla = [];
    this.grupoMaterialUpiPlantilla = [];
    this.cantidadMesesPlantilla = null;
    this.descripcionPlantilla = null;
    this.ListaCargaSkuPlantillas = [];
    this.ListaSkuChequeadosPlantilla = [];

    //Variables Editar Plantilla
    this.descripcionDetallePlantilla = null;
    this.cantidadMesesEditarPlantilla = null;
    this.centroEditarPlantilla = null;
    this.tipoMaterialEditarUpiPlantilla = [];
    this.grupoMaterialUpiEditarPlantilla = [];
    this.SkuEditarUpiPlantilla = [];

    this.CargarPlantillas();
    this.modalCrearPlantilla.showModal();
  }

  ListaPlantillasCreadas: Array<GridPlantillasCreadasVm> = [];
  CargarPlantillas() {
    let obj = {
      usuarioId: this.user.Id
    }

    this.CallHttp.httpPost<Array<PlantillasCreadasVm>>(environment.webServices.ppm + "Mants/PlantillasUPI/Home/LoadPlantillasUPIByUsuarioId", obj)
      .subscribe(response => {
        if (response.length != 0) {
          this.ObtenerTipoMaterialGrupoMaterialesEditarPlantillaTodos();
          this.ListaPlantillasCreadas = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              DescripcionPlantilla: s.DescripcionPlantilla,
              CantidadMeses: s.CantidadMeses,
              FechaInicio: s.FechaInicio,
              FechaFin: s.FechaFin,
              Plantas: s.Plantas,
              Planta: Enumerable.from(s.Plantas).select(s => s.PlantaDescripcion).firstOrDefault(),
              TipoMaterial: s.TipoMaterial,
              GrupoMaterial: s.GrupoMaterial,
              Skus: s.Skus,
              CreatedBy: s.CreatedBy,
              CreatedDate: s.CreatedDate
            }
          }).orderBy(o => o.Id).toArray();

          this.myGridPlantillas.source.localdata = this.ListaPlantillasCreadas;
          this.myGridPlantillas.dataAdapter.dataBind();          
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  idPlantillaEditar: string = null;
  descripcionDetallePlantilla: string = null;
  cantidadMesesEditarPlantilla: number = null;
  centroEditarPlantilla: CentrosVm = null;
  tipoMaterialEditarUpiPlantilla: Array<TipoMaterialesVm> = [];
  grupoMaterialUpiEditarPlantilla: Array<GrupoMaterialesVm> = [];
  SkuEditarUpiPlantilla: Array<CargarSkuPorGrupoMaterial> = [];
  SkuChequeadosEditarUpiPlantilla: Array<CargarSkuPorGrupoMaterial> = [];
  VerDetallePlantilla(plantilla: GridPlantillasCreadasVm) {
    this.ArrayCodigoSkuEditarPlantilla = [];
    this.codigoSkuSearchEditarPlantilla = null;
    this.codigoSkuEditarPlantilla = null;

    this.idPlantillaEditar = plantilla.Id;
    this.descripcionDetallePlantilla = plantilla.DescripcionPlantilla;
    this.centroEditarPlantilla = Enumerable.from(plantilla.Plantas).select(s => {
      return {
        Id: null,
        Codigo: null,
        Observacion: null,
        PlantaId: s.plantaId,
        Planta: s.PlantaDescripcion
      }
    }).firstOrDefault();
    this.cantidadMesesEditarPlantilla = plantilla.CantidadMeses;

    if (plantilla.TipoMaterial.length == 0) {
      this.tipoMaterialEditarUpiPlantilla = [];
    } else {
      this.tipoMaterialEditarUpiPlantilla = Enumerable.from(plantilla.TipoMaterial).select(s => {
        return {
          Id: s.TipoMaterialId,
          Descripcion: s.TipoMaterialDescripción,
          PersonaJuridicaClienteId: null
        }
      }).toArray();
    }

    if (plantilla.GrupoMaterial.length == 0) {
      this.ListaGrupoMaterialesEditarPlantilla = Enumerable.from(this.ListaGrupoMaterialesEditarPlantillaTodos).orderBy(o => o.Descripcion).toArray();

      this.grupoMaterialUpiEditarPlantilla = [];
      this.ListaSkuEditarPlantilla = [];

      this.SkuChequeadosEditarUpiPlantilla = Enumerable.from(plantilla.Skus).select(s => {
        return {
          Id: s.SkuId,
          PersonaJuridicaClienteId: null,
          PersonaJuridicaClienteNombre: null,
          Descripcion: null,
          Codigo: s.Skucodigo,
          GrupoCompraId: null,
          Sku: null,
          isSelected: true
        }
      }).orderBy(o => o.Codigo).toArray();
      this.SkuChequeadosEditarUpiPlantilla.forEach(c => {
        this.ListaSkuEditarPlantillaTodos.forEach(cc => {
          if (c.Codigo == cc.Codigo) {
            c.Codigo = cc.Codigo;
            c.Descripcion = this.upperCase.transform(cc.Descripcion);
            c.isSelected = true;
          }
        });
      });
    } else {
      this.ListaGrupoMaterialesEditarPlantilla = Enumerable.from(this.ListaGrupoMaterialesEditarPlantillaTodos).orderBy(o => o.Descripcion).toArray();

      this.grupoMaterialUpiEditarPlantilla = Enumerable.from(plantilla.GrupoMaterial).select(s => {
        return {
          Id: s.GrupoMaterialId,
          Descripcion: s.GrupoMaterialDescripcion,
          PersonaJuridicaClienteId: null,
          CategoriaGrupoArticuloSkuInboundId: null,
        }
      }).toArray();

      this.ObtenerSkuEditarPlantilla();

      this.SkuChequeadosEditarUpiPlantilla = Enumerable.from(plantilla.Skus).select(s => {
        return {
          Id: s.SkuId,
          PersonaJuridicaClienteId: null,
          PersonaJuridicaClienteNombre: null,
          Descripcion: null,
          Codigo: s.Skucodigo,
          GrupoCompraId: null,
          Sku: null,
          isSelected: true
        }
      }).orderBy(o => o.Codigo).toArray();

      this.SkuChequeadosEditarUpiPlantilla.forEach(c => {
        this.ListaSkuEditarPlantillaTodos.forEach(cc => {
          if (c.Codigo == cc.Codigo) {
            c.Codigo = cc.Codigo;
            c.Descripcion = this.upperCase.transform(cc.Descripcion);
            c.isSelected = true;
          }
        });
      });

      this.SkuEditarUpiPlantilla.forEach(t => {
        this.ListaSkuEditarPlantillaTodos.forEach(tt => {
          if (t.Codigo == tt.Codigo) {
            t.Descripcion = this.upperCase.transform(tt.Descripcion);
            t.Codigo = tt.Codigo;
          }
        });
      });
    }

    this.modalEditarPlantilla.showModal();
  }

  //Guardar Editar Plantilla
  GuardarEditarPlantilla() {
    if (this.centroEditarPlantilla == null) {
      this.DialogService.NotificacionWarning("El centro es un campo obligatorio");
    } else if (this.cantidadMesesEditarPlantilla == null) {
      this.DialogService.NotificacionWarning("La cantidad de meses es un campo obligatorio");
    } else {
              this.DialogService.ShowConfirm("¿Esta seguro de editar la plantilla?", "")
                .subscribe(confirm => {
                  if (confirm) {
                    let centroArray: Array<Planta> = [];
                    let objCentroArray: Planta = null;

                    objCentroArray = {
                      plantaId: this.centroEditarPlantilla.PlantaId,
                      PlantaDescripcion: this.centroEditarPlantilla.Planta
                    }

                    centroArray.push(objCentroArray);

                    let obj = {
                      id: this.idPlantillaEditar,
                      descripcionPlantilla: this.descripcionDetallePlantilla,
                      cantidadMeses: this.cantidadMesesEditarPlantilla,
                      plantas: centroArray,
                      tipoMaterial: Enumerable.from(this.tipoMaterialEditarUpiPlantilla).select(tm => {
                        return {
                          tipoMaterialId: tm.Id,
                          tipoMaterialDescripción: tm.Descripcion
                        }
                      }).toArray(),
                      grupoMaterial: Enumerable.from(this.grupoMaterialUpiEditarPlantilla).select(gm => {
                        return {
                          grupoMaterialId: gm.Id,
                          grupoMaterialDescripcion: gm.Descripcion
                        }
                      }).toArray(),
                      skus: Enumerable.from(this.SkuChequeadosEditarUpiPlantilla).select(u => {
                        return {
                          skuId: u.Id,
                          skucodigo: u.Codigo
                        }
                      }).toArray(),
                      modifyBy: this.user.Id
                    }

                    let json = JSON.stringify(obj);
                    console.log("Json Actualizar Plantilla: " + json);

                    this.CallHttp.httpPost<RespuestaUpiEditarPlantilla>(environment.webServices.ppm + "Mants/PlantillasUPI/Home/UpdatePlantillaUPI", obj)
                      .subscribe(response => {
                        if (response.IsValid) {
                          this.modalEditarPlantilla.closeModal();
                          this.CargarPlantillas();
                          this.DialogService.NotificacionSucess(response.Message);
                        } else {
                          this.DialogService.NotificacionWarning(response.Message);
                        }
                      }, error => {
                        this.DialogService.NotificacionAlert(error);
                      });
                  }
                });

           }
  }

  //Crer Nueva Plantilla
  CrearNuevaPlantilla() {
    if (this.centroPlantilla == null) {
      this.DialogService.NotificacionWarning("El centro es un campo obligatorio");
    } else if (this.cantidadMesesPlantilla == null) {
      this.DialogService.NotificacionWarning("La cantidad de meses es un campo obligatorio");
    } else if (this.descripcionPlantilla == null) {
      this.DialogService.NotificacionWarning("El descripción es un campo obligatorio");
    } else if (this.ListaSkuChequeadosPlantilla.length == 0) {
      this.DialogService.NotificacionWarning("Seleccione uno o varios Sku's");    
    } else {
              this.DialogService.ShowConfirm("¿Esta seguro de crear la plantilla?", "")
                .subscribe(confirm => {
                  if (confirm) {

                    let centroArray: Array<Planta> = [];
                    let objCentroArray: Planta = null;

                    objCentroArray = {
                      plantaId: this.centroPlantilla.PlantaId,
                      PlantaDescripcion: this.centroPlantilla.Planta
                    }

                    centroArray.push(objCentroArray);

                    let obj = {
                      descripcionPlantilla: this.descripcionPlantilla,
                      cantidadMeses: this.cantidadMesesPlantilla,
                      plantas: centroArray,
                      tipoMaterial: Enumerable.from(this.tipoMaterialUpiPlantilla).select(tm => {
                        return {
                          tipoMaterialId: tm.Id,
                          tipoMaterialDescripción: tm.Descripcion
                        }
                      }).toArray(),
                      grupoMaterial: Enumerable.from(this.grupoMaterialUpiPlantilla).select(gm => {
                        return {
                          grupoMaterialId: gm.Id,
                          grupoMaterialDescripcion: gm.Descripcion
                        }
                      }).toArray(),
                      skus: Enumerable.from(this.ListaSkuChequeadosPlantilla).select(u => {
                        return {
                          skuId: u.Id,
                          skucodigo: u.Codigo
                        }
                      }).toArray(),
                      createdBy: this.user.Id
                    }

                    let json = JSON.stringify(obj);
                    console.log("Json Crear Plantilla: " + json);

                    this.CallHttp.httpPost<RespuestaUpiCrearPlantilla>(environment.webServices.ppm + "Mants/PlantillasUPI/Home/CreatePlantillaUPI", obj)
                      .subscribe(response => {
                        if (response.IsValid) {
                          this.centroPlantilla = null;
                          this.tipoMaterialUpiPlantilla = [];
                          this.grupoMaterialUpiPlantilla = [];
                          this.cantidadMesesPlantilla = null;
                          this.descripcionPlantilla = null;
                          this.ListaCargaSkuPlantillas = [];
                          this.ListaSkuChequeadosPlantilla = [];
                          this.CargarPlantillas();
                          this.DialogService.NotificacionSucess(response.Message);
                        } else {
                          this.DialogService.NotificacionWarning(response.Message);
                        }
                      }, error => {
                        this.DialogService.NotificacionAlert(error);
                      });
                  }
                });
           }
  }

  masterSelectedEditarPlantilla = false;
  onCategoriaPressedSkuAllEditarPlantilla(evt) {
    this.ListaSkuEditarPlantilla.forEach((c) => c.isSelected = evt.target.checked);
    let SkuEncontrados = Enumerable.from(this.ListaSkuEditarPlantilla).where(w => w.isSelected == true).select(s => {
      return {
        Id: s.Id,
        PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
        PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
        Descripcion: this.upperCase.transform(s.Descripcion),
        Codigo: s.Codigo,
        Sku: s.Sku,
        GrupoCompraId: s.GrupoCompraId,
        isSelected: s.isSelected
      }
    }).orderBy(o => o.Codigo).toArray();
    this.SkuChequeadosEditarUpiPlantilla = SkuEncontrados;
  }

  onCategoriaPressedSkuEditarPlantilla(event: any, SkuId: string) {
    if (event.target.checked) {
      let [skuEncontrado] = this.ListaSkuEditarPlantilla.filter(w => w.Id == SkuId);
      if (skuEncontrado) {
        skuEncontrado.isSelected = true;
      }
      this.SkuChequeadosEditarUpiPlantilla.push(skuEncontrado);
      this.SkuChequeadosEditarUpiPlantilla = Enumerable.from(this.SkuChequeadosEditarUpiPlantilla).select(s => {
        return {
          Id: s.Id,
          PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
          PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
          Descripcion: this.upperCase.transform(s.Descripcion),
          Codigo: s.Codigo,
          Sku: s.Sku,
          GrupoCompraId: s.GrupoCompraId,
          isSelected: s.isSelected
        }
      }).orderBy(o => o.Codigo).toArray();
    } else {
      for (var i = 0; i < this.SkuChequeadosEditarUpiPlantilla.length; i++) {
        if (this.SkuChequeadosEditarUpiPlantilla[i].Id == SkuId) {
          this.SkuChequeadosEditarUpiPlantilla.splice(i--, 1);
        }
      }

      this.SkuChequeadosEditarUpiPlantilla = Enumerable.from(this.SkuChequeadosEditarUpiPlantilla).orderBy(o => o.Codigo).toArray();
    }
  }

  ArrayCodigoSkuEditarPlantilla: Array<SkuPorCodigo> = [];
  codigoSkuSearchEditarPlantilla: string = null;
  codigoSkuEditarPlantilla: string = null;
  BuscarSkuXCodigoEditarPlantilla() {
    if (this.codigoSkuSearchEditarPlantilla == null || this.codigoSkuSearchEditarPlantilla == "") {
      this.DialogService.NotificacionWarning("Ingrese un código");
    } else {
      this.ArrayCodigoSkuEditarPlantilla = [];

      let obj = {
        personaJuridicaClienteId: "8092e57d-03a5-44d3-b271-0f27ebf3d818",
        codigos: [this.codigoSkuSearchEditarPlantilla]
      }

      this.CallHttp.httpPost<Array<SkuPorCodigo>>(environment.webServices.ppm + "Mants/Personas/Home/ListSkuInboundClienteByCodigo", obj)
        .subscribe(response => {
          response.forEach(f => {
            if (response.length == 0) {
              this.codigoSkuPlantilla = "No existe un Sku creado con este código " + this.codigoSkuSearchEditarPlantilla;
            } else {
              this.ArrayCodigoSkuEditarPlantilla = response;
              this.codigoSkuEditarPlantilla = f.Codigo + " - " + this.upperCase.transform(f.Descripcion);
            }
          });

        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
    }
  }

  AgregarSkuXCodigoEditarPlantilla() {
    if (this.codigoSkuSearchEditarPlantilla == null || this.codigoSkuSearchEditarPlantilla == "") {
      this.DialogService.NotificacionWarning("Ingrese un código");
    } else {
      if (this.SkuChequeadosEditarUpiPlantilla.length == 0) {
        this.codigoSkuSearchEditarPlantilla = null;
        this.codigoSkuEditarPlantilla = null;

        let SkuEncontrado = {
          Id: this.ArrayCodigoSkuEditarPlantilla[0].Id,
          PersonaJuridicaClienteId: null,
          PersonaJuridicaClienteNombre: null,
          Descripcion: this.upperCase.transform(this.ArrayCodigoSkuEditarPlantilla[0].Descripcion),
          Codigo: this.ArrayCodigoSkuEditarPlantilla[0].Codigo,
          Sku: this.ArrayCodigoSkuEditarPlantilla[0].Codigo + " - " + this.upperCase.transform(this.ArrayCodigoSkuEditarPlantilla[0].Descripcion),
          GrupoCompraId: null,
          isSelected: true
        }
        this.SkuChequeadosEditarUpiPlantilla.push(SkuEncontrado);
        this.SkuChequeadosEditarUpiPlantilla = Enumerable.from(this.SkuChequeadosEditarUpiPlantilla).orderBy(o => o.Codigo).toArray();

        this.ListaSkuEditarPlantilla.forEach(f => {
          if (f.Codigo == this.ArrayCodigoSkuEditarPlantilla[0].Codigo) {
            f.isSelected = true;
          }
        });
      } else {
        let validarExisteCodigo: boolean = false;
        validarExisteCodigo = Enumerable.from(this.SkuChequeadosEditarUpiPlantilla).any(w => w.Codigo == this.ArrayCodigoSkuEditarPlantilla[0].Codigo);

        if (validarExisteCodigo == true) {
          this.DialogService.NotificacionWarning("Sku  " + this.upperCase.transform(this.ArrayCodigoSkuEditarPlantilla[0].Descripcion) + " ya esta seleccionado");
        } else {
          this.codigoSkuSearchEditarPlantilla = null;
          this.codigoSkuEditarPlantilla = null;

            let SkuEncontrado = {
              Id: this.ArrayCodigoSkuEditarPlantilla[0].Id,
              PersonaJuridicaClienteId: null,
              PersonaJuridicaClienteNombre: null,
              Descripcion: this.upperCase.transform(this.ArrayCodigoSkuEditarPlantilla[0].Descripcion),
              Codigo: this.ArrayCodigoSkuEditarPlantilla[0].Codigo,
              Sku: this.ArrayCodigoSkuEditarPlantilla[0].Codigo + " - " + this.upperCase.transform(this.ArrayCodigoSkuEditarPlantilla[0].Descripcion),
              GrupoCompraId: null,
              isSelected: true
            }
            this.SkuChequeadosEditarUpiPlantilla.push(SkuEncontrado);
            this.SkuChequeadosEditarUpiPlantilla = Enumerable.from(this.SkuChequeadosEditarUpiPlantilla).orderBy(o => o.Codigo).toArray();

            this.ListaSkuEditarPlantilla.forEach(f => {
              if (f.Codigo == this.ArrayCodigoSkuEditarPlantilla[0].Codigo) {
                f.isSelected = true;
              }
            });
        }
      }
    }
  }

  //Usar Nueva Plantilla
  UsarPlantilla(plantilla: GridPlantillasCreadasVm) {
    this.ListaSkuChequeadosProveedor = [];
    this.centroUpi = Enumerable.from(plantilla.Plantas).select(s => {
      return {
        Id: null,
        Codigo: null,
        Observacion: null,
        PlantaId: s.plantaId,
        Planta: s.PlantaDescripcion
      }
    }).firstOrDefault();
    this.CargarUsarPlantillaTipoMaterialesUpi();
    this.tipoMaterialUpi = Enumerable.from(plantilla.TipoMaterial).select(s => {
      return {
        Id: s.TipoMaterialId,
        Descripcion: s.TipoMaterialDescripción,
        PersonaJuridicaClienteId: null
      }
    }).toArray();
    this.ObtenerTipoMaterial();

    if (plantilla.GrupoMaterial.length == 0) {
      let array: Array<CargarSkuPorGrupoMaterial> = [];
      array = Enumerable.from(plantilla.Skus).select(s => {
        return {
          Id: s.SkuId,
          Sku: null,
          Codigo: s.Skucodigo,
          Descripcion: null,
          PersonaJuridicaClienteId: null,
          PersonaJuridicaClienteNombre: null,
          GrupoCompraId: null,
          isSelected: false
        }
      }).orderBy(o => o.Codigo).toArray();

      this.ListaSkuEditarPlantillaTodos.forEach(f => {
        array.forEach(ff => {
          if (f.Codigo == ff.Codigo) {
            let obj = {
              Id: f.Id,
              Sku: ff.Codigo + " - " + this.upperCase.transform(f.Descripcion),
              Codigo: f.Codigo,
              Descripcion: this.upperCase.transform(f.Descripcion),
              PersonaJuridicaClienteId: f.PersonaJuridicaClienteId,
              PersonaJuridicaClienteNombre: f.PersonaJuridicaClienteNombre,
              GrupoCompraId: f.GrupoCompraId,
              isSelected: true
            }

            this.ListaSkuChequeadosProveedor.push(obj);
            this.ListaSkuChequeadosProveedor = Enumerable.from(this.ListaSkuChequeadosProveedor).orderBy(o => o.Codigo).toArray();
          }
        });
      });
      this.BuscarSku();  
    } else {
      let array: Array<CargarSkuPorGrupoMaterial> = [];
      array = Enumerable.from(plantilla.Skus).select(s => {
        return {
          Id: s.SkuId,
          Sku: null,
          Codigo: s.Skucodigo,
          Descripcion: null,
          PersonaJuridicaClienteId: null,
          PersonaJuridicaClienteNombre: null,
          GrupoCompraId: null,
          isSelected: false
        }
      }).orderBy(o => o.Codigo).toArray();

      this.ListaSkuEditarPlantillaTodos.forEach(f => {
        array.forEach(ff => {
          if (f.Codigo == ff.Codigo) {
            let obj = {
              Id: f.Id,
              Sku: ff.Codigo + " - " + this.upperCase.transform(f.Descripcion),
              Codigo: f.Codigo,
              Descripcion: this.upperCase.transform(f.Descripcion),
              PersonaJuridicaClienteId: f.PersonaJuridicaClienteId,
              PersonaJuridicaClienteNombre: f.PersonaJuridicaClienteNombre,
              GrupoCompraId: f.GrupoCompraId,
              isSelected: true
            }

            this.ListaSkuChequeadosProveedor.push(obj);
            this.ListaSkuChequeadosProveedor = Enumerable.from(this.ListaSkuChequeadosProveedor).orderBy(o => o.Codigo).toArray();
          }
        });
      });
      this.grupoMaterialUpi = Enumerable.from(plantilla.GrupoMaterial).select(s => {
        return {
          Id: s.GrupoMaterialId,
          Descripcion: s.GrupoMaterialDescripcion,
          PersonaJuridicaClienteId: null,
          CategoriaGrupoArticuloSkuInboundId: null,
        }
      }).toArray();
      this.ObtenerSkusUsarPlantilla(plantilla.GrupoMaterial);
    }

    this.FechaInicio = moment(plantilla.FechaInicio).format('YYYY-MM-DD');
    this.FechaFinal = moment(plantilla.FechaFin).format('YYYY-MM-DD');
    this.modalCrearPlantilla.closeModal();
  }

  CargarUsarPlantillaTipoMaterialesUpi() {
    let personaJuridicaId = "8092e57d-03a5-44d3-b271-0f27ebf3d818";
    this.CallHttp.httpGet<Array<TipoMaterialesVm>>(environment.webServices.ppm + "Mants/Personas/Home/GetLoadCategoriaGrupoArticuloByPersonaJuridicaClienteId?PersonaJuridicaClienteId=" + personaJuridicaId)
      .subscribe(response => {
        if (response.length == 0) {
          this.DialogService.NotificacionWarning("No existen tipos de materiales");
        } else {
          this.ListaTipoMateriales = Enumerable.from(response).select(s => {
            return {
              Id: s.Id,
              Descripcion: s.Descripcion,
              PersonaJuridicaClienteId: s.PersonaJuridicaClienteId
            }
          }).orderBy(o => o.Descripcion).toArray();
        }
      }, error => {
        this.DialogService.NotificacionAlert(error);
      });
  }

  ObtenerSkusUsarPlantilla(GrupoMateriales: Array<GrupoMaterial>) {
      this.ListaSkuChequeadosProveedor;

      let obj = {
        grupoArticuloSkuInboundIds: Enumerable.from(GrupoMateriales).select(s => s.GrupoMaterialId).toArray()
      }

      this.CallHttp.httpPost<Array<CargarSkuPorGrupoMaterial>>(environment.webServices.ppm + "/Mants/Personas/Home/DetailsByGrupoArticuloIdByList", obj)
        .subscribe(response => {
          if (response.length != 0) {
            this.ListaCargaSku = Enumerable.from(response).select(s => {
              return {
                Id: s.Id,
                PersonaJuridicaClienteId: s.PersonaJuridicaClienteId,
                PersonaJuridicaClienteNombre: s.PersonaJuridicaClienteNombre,
                Descripcion: s.Descripcion,
                Codigo: s.Codigo,
                GrupoCompraId: s.GrupoCompraId,
                Sku: s.Codigo + " - " + this.upperCase.transform(s.Descripcion),
                isSelected: false
              }
            }).orderBy(o => o.Codigo).toArray();

            this.ListaSkuChequeadosProveedor.forEach(f => {
              this.ListaCargaSku.forEach(ff => {
                if (f.Codigo == ff.Codigo) {
                  ff.isSelected = true;
                }
              });
            });

            this.BuscarSku();  
          }
        }, error => {
          this.DialogService.NotificacionAlert(error);
        });
  }

  //Proveedor por planta plantilla
  //ProveedorPlantilla: Proveedores;
  PlantaPlantilla: Plantas;

  //Seleccion de combos Plantilla
  ComboSaveUno: ComboSave;
  ComboSaveDos: ComboSave;
  ComboSaveTres: ComboSave;
  ChangeComboPlantilla(Id: number, Descripcion: string, NCombo: number) {
    if (NCombo == 1) {
      this.ComboSaveUno = { Id: Id, Descripcion: Descripcion };
    } else if (NCombo == 2) {
      this.ComboSaveDos = { Id: Id, Descripcion: Descripcion };
    } else {
              this.ComboSaveTres = { Id: Id, Descripcion: Descripcion };
           }
  }

  ///VER GESTIONES X MES///
  SkuCodigoxMes: string;
  SkuxMes: string;
  MesxMes: string;
  NMesxMes: number;
  arrayGestionesxMes: Array<NewGestiones> = [];

  arrayGestionesFinalizadasxMes: Array<NewGestiones> = [];
  arrayGestionesNoFinalizadasxMes: Array<NewGestiones> = [];
  verGestionesMes(SkuCodigo: string, Sku: string, Mes: string, Nmes: number, valorLinea: number) {
    this.arrayGestionesxMes = [];
    this.arrayGestionesFinalizadasxMes = [];
    this.arrayGestionesNoFinalizadasxMes = [];

    if (valorLinea != 0) {
      this.SkuCodigoxMes = SkuCodigo;
      this.SkuxMes = Sku;
      this.MesxMes = Mes;
      this.NMesxMes = Nmes;

      this.listaOriginalUPI.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          if (f.Mes == this.NMesxMes && ff.SkuCodigo == this.SkuCodigoxMes) {
            ff.NewGestiones.forEach(fff => {
              let obj = {
                CodigoGestion: fff.CodigoGestion,
                CodigoSku: fff.CodigoSku,
                GestionSeguimientoId: fff.GestionSeguimientoId,
                Unidad: fff.Unidad,
                Semana: fff.Semana,
                SemanaEtaModificada: fff.SemanaEtaModificada,
                Eta: fff.Eta,
                EtaModificada: fff.EtaModificada,
                Abierta: fff.Abierta,
                ProveedorNombre: fff.ProveedorNombre,
                SitioActual: fff.SitioActual,
                FechaMigo: fff.FechaMigo,
                Migo: fff.Migo,
                EnviarMigo: fff.EnviarMigo,
                Estado: fff.Estado
              }
              this.arrayGestionesxMes.push(obj);
            })
          }
        })
      });

      //this.myGridGestionesMes.source.localdata = this.arrayGestionesxMes;
      //this.myGridGestionesMes.dataAdapter.dataBind();

      this.arrayGestionesFinalizadasxMes = Enumerable.from(this.arrayGestionesxMes).where(w => w.Estado.Value == 4 || w.EnviarMigo == true).select(s => {
        return {
          Abierta: s.Abierta,
          CodigoGestion: s.CodigoGestion,
          CodigoSku: s.CodigoGestion,
          Estado: s.Estado,
          Eta: s.Eta,
          EtaModificada: s.EtaModificada,
          FechaMigo: s.FechaMigo,
          GestionSeguimientoId: s.GestionSeguimientoId,
          Migo: s.Migo,
          EnviarMigo: s.EnviarMigo,
          SemanaEtaModificada: s.SemanaEtaModificada,
          SitioActual: s.SitioActual,
          Unidad: s.Unidad,

          Semana: s.Semana,
          ProveedorNombre: s.ProveedorNombre
        }
      }).orderBy(o => o.Semana).toArray();
      this.myGridGestionesFinalizadasMes.source.localdata = this.arrayGestionesFinalizadasxMes;
      this.myGridGestionesFinalizadasMes.dataAdapter.dataBind();

      this.arrayGestionesNoFinalizadasxMes = Enumerable.from(this.arrayGestionesxMes).where(w => w.Estado.Value != 4).select(s => {
        return {
          Abierta: s.Abierta,
          CodigoGestion: s.CodigoGestion,
          CodigoSku: s.CodigoGestion,
          Estado: s.Estado,
          Eta: s.Eta,
          EtaModificada: s.EtaModificada,
          FechaMigo: s.FechaMigo,
          GestionSeguimientoId: s.GestionSeguimientoId,
          Migo: s.Migo,
          EnviarMigo: s.EnviarMigo,
          SemanaEtaModificada: s.SemanaEtaModificada,
          SitioActual: s.SitioActual,
          Unidad: s.Unidad,

          Semana: s.Semana,
          ProveedorNombre: s.ProveedorNombre
        }
      }).orderBy(o => o.Semana).toArray();
      this.myGridGestionesNoFinalizadasMes.source.localdata = this.arrayGestionesNoFinalizadasxMes;
      this.myGridGestionesNoFinalizadasMes.dataAdapter.dataBind();
      this.modalGestionesxMes.showModal();
    }
  }

  ///VER GESTIONES SEMANAS X MES///
  SkuCodigoSemanasxMes: string;
  SkuSemanasxMes: string;
  MesSemanasxMes: string;
  NMesSemanasxMes: number;
  SemanaxMes: number;
  arrayGestionesSemanasxMes: Array<NewGestiones> = [];

  arrayGestionesFinalizadasSemanasxMes: Array<NewGestiones> = [];
  arrayGestionesNoFinalizadasSemanasxMes: Array<NewGestiones> = [];
  verGestionesSemanasxMes(SkuCodigo: string, Sku: string, Mes: string, Nmes: number, Semana: number, valorLinea: number) {
    this.arrayGestionesSemanasxMes = [];

    if (valorLinea != 0) {
      this.SkuCodigoSemanasxMes = SkuCodigo;
      this.SkuSemanasxMes = Sku;
      this.NMesSemanasxMes = Nmes;
      this.MesSemanasxMes = Mes;
      this.SemanaxMes = Semana;

      this.listaOriginalUPI.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          if (f.Mes == this.NMesSemanasxMes && f.Semana == this.SemanaxMes && ff.SkuCodigo == this.SkuCodigoSemanasxMes) {

            ff.NewGestiones.forEach(fff => {
              let obj = {
                CodigoGestion: fff.CodigoGestion,
                CodigoSku: fff.CodigoSku,
                GestionSeguimientoId: fff.GestionSeguimientoId,
                Unidad: fff.Unidad,
                Semana: fff.Semana,
                SemanaEtaModificada: fff.SemanaEtaModificada,
                Eta: fff.Eta,
                EtaModificada: fff.EtaModificada,
                Abierta: fff.Abierta,
                ProveedorNombre: fff.ProveedorNombre,
                SitioActual: fff.SitioActual,
                FechaMigo: fff.FechaMigo,
                Migo: fff.Migo,
                EnviarMigo: fff.EnviarMigo,
                Estado: fff.Estado
              }
              this.arrayGestionesSemanasxMes.push(obj);
            })
          }
        })
      });

      this.arrayGestionesFinalizadasSemanasxMes = Enumerable.from(this.arrayGestionesSemanasxMes).where(w => w.Estado.Value == 4 || w.EnviarMigo == true).select(s => {
        return {
          Abierta: s.Abierta,
          CodigoGestion: s.CodigoGestion,
          CodigoSku: s.CodigoGestion,
          Estado: s.Estado,
          Eta: s.Eta,
          EtaModificada: s.EtaModificada,
          FechaMigo: s.FechaMigo,
          GestionSeguimientoId: s.GestionSeguimientoId,
          Migo: s.Migo,
          EnviarMigo: s.EnviarMigo,
          SemanaEtaModificada: s.SemanaEtaModificada,
          SitioActual: s.SitioActual,
          Unidad: s.Unidad,

          Semana: s.Semana,
          ProveedorNombre: s.ProveedorNombre
        }
      }).orderBy(o => o.Semana).toArray();
      this.myGridGestionesFinalizadasSemanas.source.localdata = this.arrayGestionesFinalizadasSemanasxMes;
      this.myGridGestionesFinalizadasSemanas.dataAdapter.dataBind();

      this.arrayGestionesNoFinalizadasSemanasxMes = Enumerable.from(this.arrayGestionesSemanasxMes).where(w => w.Estado.Value != 4).select(s => {
        return {
          Abierta: s.Abierta,
          CodigoGestion: s.CodigoGestion,
          CodigoSku: s.CodigoGestion,
          Estado: s.Estado,
          Eta: s.Eta,
          EtaModificada: s.EtaModificada,
          FechaMigo: s.FechaMigo,
          GestionSeguimientoId: s.GestionSeguimientoId,
          Migo: s.Migo,
          EnviarMigo: s.EnviarMigo,
          SemanaEtaModificada: s.SemanaEtaModificada,
          SitioActual: s.SitioActual,
          Unidad: s.Unidad,

          Semana: s.Semana,
          ProveedorNombre: s.ProveedorNombre
        }
      }).orderBy(o => o.Semana).toArray();
      this.myGridGestionesNoFinalizadasSemanas.source.localdata = this.arrayGestionesNoFinalizadasSemanasxMes;
      this.myGridGestionesNoFinalizadasSemanas.dataAdapter.dataBind();

      //this.myGridGestionesSemanas.source.localdata = this.arrayGestionesSemanasxMes;
      //this.myGridGestionesSemanas.dataAdapter.dataBind();
      this.modalGestionesSemanasxMes.showModal();
    }
  }

  ///VER DESPACHOS ASIGNADOS X MES///
  SkuCodigoDespachoAsignadoxMes: string;
  SkuDespachoAsignadoxMes: string;
  MesDespachoAsignadoxMes: string;
  NMesDespachoAsignadoxMes: number;
  arrayDespachoAsignadoxMes: Array<DespachosModales> = [];
  verDespachosAsignadosMes(SkuCodigo: string, Sku: string, Mes: string, Nmes: number, valorLinea: number) {
    if (valorLinea != 0) {
      this.arrayDespachoAsignadoxMes = [];
      this.SkuCodigoDespachoAsignadoxMes = SkuCodigo;
      this.SkuDespachoAsignadoxMes = Sku;
      this.MesDespachoAsignadoxMes = Mes;
      this.NMesDespachoAsignadoxMes = Nmes;


      this.listaOriginalUPI.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          if (f.Mes == this.NMesDespachoAsignadoxMes && ff.SkuCodigo == this.SkuCodigoDespachoAsignadoxMes) {
            ff.DespachosTorre.forEach(fff => {
              let obj = {
                Id: null,
                Fecha: fff.Fecha,
                ProveedorNombre: fff.ProveedorNombre,
                CodigoDespacho: fff.CodigoDespacho,
                Cantidad: Enumerable.from(fff.DespachoDetalle).select(s => s.Cantidad).firstOrDefault(),
                GetSemana: 'Semana ' + fff.GetSemana
              }
              this.arrayDespachoAsignadoxMes.push(obj);
            });
          }
        })
      });

      this.myGridDespachosAsignadosMes.source.localdata = this.arrayDespachoAsignadoxMes;
      this.myGridDespachosAsignadosMes.dataAdapter.dataBind();
      this.modalDespachosAsignadosxMes.showModal();
    }
  }

  ///VER DESPACHOS ASIGNADOS SEMANAS X MES///
  SkuCodigoDespachoAsignadoSemanasxMes: string;
  SkuDespachoAsignadoSemanasxMes: string;
  MesDespachoAsignadoSemanasxMes: string;
  NMesDespachoAsignadoSemanasxMes: number;
  SemanaDespachoAsignadoxMes: number;
  arrayDespachosAsignadoSemanasxMes: Array<DespachosModales> = [];
  verDespachosAsignadosSemanasxMes(SkuCodigo: string, Sku: string, Mes: string, Nmes: number, Semana: number, valorLinea: number) {
    if (valorLinea != 0) {
      this.arrayDespachosAsignadoSemanasxMes = [];
      this.SkuCodigoDespachoAsignadoSemanasxMes = SkuCodigo;
      this.SkuDespachoAsignadoSemanasxMes = Sku;
      this.NMesDespachoAsignadoSemanasxMes = Nmes;
      this.MesDespachoAsignadoSemanasxMes = Mes;
      this.SemanaDespachoAsignadoxMes = Semana;

      this.listaOriginalUPI.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          if (f.Mes == this.NMesDespachoAsignadoSemanasxMes && f.Semana == this.SemanaDespachoAsignadoxMes && ff.SkuCodigo == this.SkuCodigoDespachoAsignadoSemanasxMes) {

            ff.DespachosTorre.forEach(fff => {
              let obj = {
                Id: null,
                Fecha: fff.Fecha,
                ProveedorNombre: fff.ProveedorNombre,
                CodigoDespacho: fff.CodigoDespacho,
                Cantidad: Enumerable.from(fff.DespachoDetalle).select(s => s.Cantidad).firstOrDefault(),
                GetSemana: 'Semana ' + fff.GetSemana
              }
              this.arrayDespachosAsignadoSemanasxMes.push(obj);
            });
          }
        })
      });

      this.myGridDespachosAsignadosSemanas.source.localdata = this.arrayDespachosAsignadoSemanasxMes;
      this.myGridDespachosAsignadosSemanas.dataAdapter.dataBind();
      this.modalDespachosAsignadosSemanasxMes.showModal();
    }
  }

  ///VER DESPACHOS SIN ASIGNAR X MES///
  SkuCodigoDespachoSinAsignarxMes: string;
  SkuDespachoSinAsignarxMes: string;
  MesDespachoSinAsignarxMes: string;
  NMesDespachoSinAsignarxMes: number;
  arrayDespachoSinAsignarxMes: Array<DespachosModales> = [];
  arrayOrdenesCompraSinAsignarxMes: Array<any> = [];
  verDespachosSinAsignarMes(SkuCodigo: string, Sku: string, Mes: string, Nmes: number, ValorLinea: number) {
    if (ValorLinea != 0) {
      this.arrayDespachoSinAsignarxMes = [];
      this.arrayOrdenesCompraSinAsignarxMes = [];
      this.SkuCodigoDespachoSinAsignarxMes = SkuCodigo;
      this.SkuDespachoSinAsignarxMes = Sku;
      this.MesDespachoSinAsignarxMes = Mes;
      this.NMesDespachoSinAsignarxMes = Nmes;

      this.listaOriginalUPI.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          if (f.Mes == this.NMesDespachoSinAsignarxMes && ff.SkuCodigo == this.SkuCodigoDespachoSinAsignarxMes) {
            ff.DespachoAndOc.Despachos.forEach(fff => {
              let objDespachos = {
                Id: null,
                Fecha: fff.Fecha,
                ProveedorNombre: fff.ProveedorNombre,
                CodigoDespacho: fff.CodigoDespacho,
                Cantidad: fff.Cantidad,
                GetSemana: 'Semana ' + fff.GetSemana
              }
              this.arrayDespachoSinAsignarxMes.push(objDespachos);
            });

            ff.DespachoAndOc.OrdenCompra.forEach(v => {
              let objOrdenCompra = {
                ProveedorNombre: v.ProveedorNombre,
                Fecha: v.Fecha,
                OrdenCompra: v.OrdenCompra,
                Cantidad: Enumerable.from(v.OrdenComparaDetalle).select(s => s.Cantidad).sum()
              }
              this.arrayOrdenesCompraSinAsignarxMes.push(objOrdenCompra);
            });
          }
        })
      });

      this.myGridDespachosSinAsignarMes.source.localdata = this.arrayDespachoSinAsignarxMes;
      this.myGridDespachosSinAsignarMes.dataAdapter.dataBind();

      this.myGridOrdenesCompraMes.source.localdata = this.arrayOrdenesCompraSinAsignarxMes;
      this.myGridOrdenesCompraMes.dataAdapter.dataBind();

      this.modalDespachosSinAsignarxMes.showModal();
    }
  }

  ///VER DESPACHOS SIN ASIGNAR SEMANA X MES///
  SkuCodigoDespachoSinAsignarSemanasxMes: string;
  SkuDespachoSinAsignarSemanasxMes: string;
  MesDespachoSinAsignarSemanasxMes: string;
  NMesDespachoSinAsignarSemanasxMes: number;
  SemanaDespachoSinAsignarxMes: number;
  arrayDespachoSinAsignarSemanasxMes: Array<DespachosModales> = [];
  verDespachosSinAsignarSemanasxMes(SkuCodigo: string, Sku: string, Mes: string, Nmes: number, Semana: number, ValorLinea: number) {
    if (ValorLinea != 0) {
      this.arrayDespachoSinAsignarSemanasxMes = [];
      this.SkuCodigoDespachoSinAsignarSemanasxMes = SkuCodigo;
      this.SkuDespachoSinAsignarSemanasxMes = Sku;
      this.NMesDespachoSinAsignarSemanasxMes = Nmes;
      this.MesDespachoSinAsignarSemanasxMes = Mes;
      this.SemanaDespachoSinAsignarxMes = Semana;

      this.listaOriginalUPI.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          if (f.Mes == this.NMesDespachoSinAsignarSemanasxMes && f.Semana == this.SemanaDespachoSinAsignarxMes && ff.SkuCodigo == this.SkuCodigoDespachoSinAsignarSemanasxMes) {

            ff.DespachoAndOc.Despachos.forEach(fff => {
              let objDespachos = {
                Id: null,
                Fecha: fff.Fecha,
                ProveedorNombre: fff.ProveedorNombre,
                CodigoDespacho: fff.CodigoDespacho,
                Cantidad: Enumerable.from(fff.DespachoDetalle).select(s => s.Cantidad).firstOrDefault(),
                GetSemana: 'Semana ' + fff.GetSemana
              }
              this.arrayDespachoSinAsignarSemanasxMes.push(objDespachos);
            });

            ff.DespachoAndOc.OrdenCompra.forEach(v => {
              let objOrdenCompra = {
                ProveedorNombre: v.ProveedorNombre,
                Fecha: v.Fecha,
                OrdenCompra: v.OrdenCompra,
                Cantidad: Enumerable.from(v.OrdenComparaDetalle).select(s => s.Cantidad).sum()
              }
            });
          }
        })
      });

      this.myGridDespachosSinAsignarSemanas.source.localdata = this.arrayDespachoSinAsignarSemanasxMes;
      this.myGridDespachosSinAsignarSemanas.dataAdapter.dataBind();
      this.modalDespachosSinAsignarSemanasxMes.showModal();
    }   
  }

  ///VER GESTIONES MENSUALES X SKU///
  arrayGestionesMesSku: Array<GestionesMesSkuII> = [];//Array<GestionesMesSku> = [];
  skuDescripcionGestionMensual: string;
  verGestionesMesSku(SkuCodigo: string, Sku: string) {
    this.myGridGestionesMensualSku.myGridGestionesMensualSku.clear();
    let codigosSku: Array<GestionesMesSku> = [];
    this.arrayGestionesMesSku = [];
    this.skuDescripcionGestionMensual = Sku;

    Enumerable.from(this.listaOriginalUPI).groupBy(s => { return { Mes: s.Mes, Semana: s.Semana } }).forEach(s => {
      s.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          
          let [mesesGesiones] = codigosSku.filter(s => s.SkuCodigo == SkuCodigo);

          if (!mesesGesiones) {
            if (ff.SkuCodigo == SkuCodigo) {
                codigosSku.push({
                SkuCodigo: SkuCodigo,
                SkuDescripcion: Sku,
                Details: [
                  {
                    SkuCodigo: SkuCodigo,
                    Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                    Nmes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Id,
                    Gestiones: ff.NewGestiones
                  }
                ]
              });
            }              
          }
          else {
              let mesIndex = mesesGesiones.Details.findIndex(v => v.Nmes == s.key().Mes);

              //if (mesIndex > -1) {
              //  let mes = mesesGesiones.Details[mesIndex];

              //  if (ff.SkuCodigo == SkuCodigo) {
              //    if (ff.NewGestiones.length != 0) {
              //      ff.NewGestiones.forEach(c => {
              //        mes.Gestiones.push(
              //          {
              //            CodigoGestion: c.CodigoGestion,
              //            CodigoSku: c.CodigoSku,
              //            GestionSeguimientoId: c.GestionSeguimientoId,
              //            Unidad: c.Unidad,
              //            Semana: c.Semana,
              //            SemanaEtaModificada: c.SemanaEtaModificada,
              //            Eta: c.Eta,
              //            EtaModificada: c.EtaModificada,
              //            Abierta: c.Abierta,
              //            ProveedorNombre: c.ProveedorNombre,
              //            SitioActual: c.SitioActual,
              //            FechaMigo: c.FechaMigo,
              //            Migo: c.Migo,
              //            Estado: c.Estado
              //          }
              //        )
              //      });
              //    }                  
              //  }
              //}
              //else {
                if (ff.SkuCodigo == SkuCodigo) {                  
                  mesesGesiones.Details.push({
                    SkuCodigo: SkuCodigo,
                    Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                    Nmes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Id,
                    Gestiones: ff.NewGestiones.length != 0 ? ff.NewGestiones : []
                  })
                }              

              //  mesIndex = mesesGesiones.Details.length - 1;
              //}
          }        
        });
      });
    });

    codigosSku.forEach(f => {
      f.Details.forEach(ff => {
        ff.Gestiones.forEach(fff => {          
          let obj = {
            Mes: ff.Mes,
            Nmes: ff.Nmes,
            Abierta: fff.Abierta,
            CodigoGestion: fff.CodigoGestion,
            CodigoSku: fff.CodigoSku,
            Eta: fff.Eta,
            EtaModificada: fff.EtaModificada,
            GestionSeguimientoId: fff.GestionSeguimientoId,
            ProveedorNombre: fff.ProveedorNombre,
            Semana: fff.Semana,
            SemanaEtaModificada: fff.SemanaEtaModificada,
            SitioActual: fff.SitioActual,
            Unidad: fff.Unidad
          }
          this.arrayGestionesMesSku.push(obj);         
        });
      });
    });

    this.myGridGestionesMensualSku.source.localdata =  this.arrayGestionesMesSku;
    this.myGridGestionesMensualSku.dataAdapter.dataBind();

    $(this.myGridGestionesMensualSku.myGridGestionesMensualSku.elementRef.nativeElement).show();
    this.modalGestionesMesSku.showModal().subscribe(response => {
      $(this.myGridGestionesMensualSku.myGridGestionesMensualSku.elementRef.nativeElement).hide();
    });
  }

  //VER DESPACHOS ASIGNADOS MENSUALES X SKU
  arrayDespachosAsignadosMesSku: Array<DespachosAsignadosMesSkuII> = [];//Array<DespachosAsignadosMesSku> = [];
  skuDescripcionDespachoAsignadoMensual: string;
  verDespachosAsignadosMesSku(SkuCodigo: string, Sku: string) {
    this.skuDescripcionDespachoAsignadoMensual = Sku;
    let codigosSku: Array<DespachosAsignadosMesSku> = [];
    this.arrayDespachosAsignadosMesSku = [];

    Enumerable.from(this.listaOriginalUPI).groupBy(s => { return { Mes: s.Mes, Semana: s.Semana } }).forEach(s => {
      s.forEach(f => {
        f.UpiByDiasBySku.forEach(ff => {
          let [mesesDespachosAsignados] = codigosSku.filter(s => s.SkuCodigo == SkuCodigo);

          if (!mesesDespachosAsignados) {
            if (ff.SkuCodigo == SkuCodigo) {
              codigosSku.push({
                SkuCodigo: SkuCodigo,
                SkuDescripcion: Sku,
                Details: [
                  {
                    SkuCodigo: SkuCodigo,
                    Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                    Nmes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Id,
                    DespachosAsignados: Enumerable.from(ff.DespachosTorre).select(s => {
                      return {
                        Id: null,
                        Fecha: s.Fecha,
                        ProveedorNombre: s.ProveedorNombre,
                        CodigoDespacho: s.CodigoDespacho,
                        Cantidad: Enumerable.from(s.DespachoDetalle).select(s => s.Cantidad).firstOrDefault(),
                        GetSemana: 'Semana ' + s.GetSemana
                      }
                    }).toArray()
                  }
                ]
              });
            }
          } else {
            let mesIndex = mesesDespachosAsignados.Details.findIndex(v => v.Nmes == s.key().Mes);

            if (mesIndex > -1) {
              let mes = mesesDespachosAsignados.Details[mesIndex];

              if (ff.SkuCodigo == SkuCodigo) {
                if (ff.DespachosTorre.length != 0) {
                  ff.DespachosTorre.forEach(c => {
                    mes.DespachosAsignados.push(
                      {
                        Id: null,
                        Fecha: c.Fecha,
                        ProveedorNombre: c.ProveedorNombre,
                        CodigoDespacho: c.CodigoDespacho,
                        Cantidad: Enumerable.from(c.DespachoDetalle).select(s => s.Cantidad).firstOrDefault(),
                        GetSemana: 'Semana ' + c.GetSemana
                      }
                    )
                  });
                }

              }
            } else {
              if (ff.SkuCodigo == SkuCodigo) {
                mesesDespachosAsignados.Details.push({
                  SkuCodigo: SkuCodigo,
                  Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                  Nmes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Id,
                  DespachosAsignados: Enumerable.from(ff.DespachosTorre).select(s => {
                    return {
                      Id: null,
                      Fecha: s.Fecha,
                      ProveedorNombre: s.ProveedorNombre,
                      CodigoDespacho: s.CodigoDespacho,
                      Cantidad: Enumerable.from(s.DespachoDetalle).select(s => s.Cantidad).firstOrDefault(),
                      GetSemana: 'Semana ' + s.GetSemana
                    }
                  }).toArray()
                })
              }

              mesIndex = mesesDespachosAsignados.Details.length - 1;
            }

          }

        });
      });
    });
    codigosSku.forEach(f => {
      f.Details.forEach(ff => {
        ff.DespachosAsignados.forEach(fff => {

          let obj = {
                Id: fff.Id,
                Mes: ff.Mes,
                Nmes: ff.Nmes,
                Fecha: fff.Fecha,
                ProveedorNombre: fff.ProveedorNombre,
                CodigoDespacho: fff.CodigoDespacho,
                Cantidad: fff.Cantidad,
                GetSemana: fff.GetSemana
          }
          this.arrayDespachosAsignadosMesSku.push(obj);

        });
      });
    });

    this.myGridDespachosAsignadosMensualSku.source.localdata = this.arrayDespachosAsignadosMesSku;
    this.myGridDespachosAsignadosMensualSku.dataAdapter.dataBind();
    
    $(this.myGridDespachosAsignadosMensualSku.myGridDespachosAsignadosMensualSku.elementRef.nativeElement).show();
    this.modalDespachosAsignadosMesSku.showModal().subscribe(response => {
      $(this.myGridDespachosAsignadosMensualSku.myGridDespachosAsignadosMensualSku.elementRef.nativeElement).hide();
    });
    //this.modalDespachosAsignadosMesSku.showModal();
  }

  //VER DESPACHOS SIN ASIGNAR MENSUALES X SKU
  arrayDespachosSinAsignarMesSku: Array<DespachosSinAsignarMesualSkuII> = []; //Array<DespachosSinAsignarMesSku> = [];
  arrayDespachosSinAsignarMensualSku: Array<DespachosSinAsignarSkuII> = [];
  arrayOrdenesDeCompraMensualSku: Array<OrdenesDeCompraII> = [];
  skuDescripcionDespachoSinAsignadoMensual: string;
  verDespachosSinAsignarMesSku(SkuCodigo: string, Sku: string) {
    if (this.checkDespachosSinRelacionar == true) {    
      this.skuDescripcionDespachoSinAsignadoMensual = Sku;
      let codigosSku: Array<DespachosSinAsignarMesSku> = [];
      this.arrayDespachosSinAsignarMensualSku = [];
      this.arrayOrdenesDeCompraMensualSku = [];
      this.arrayDespachosSinAsignarMesSku = [];

      Enumerable.from(this.listaOriginalUPI).groupBy(s => { return { Mes: s.Mes, Semana: s.Semana } }).forEach(s => {
        s.forEach(f => {
          f.UpiByDiasBySku.forEach(ff => {
            let [mesesDespachosSinAsignar] = codigosSku.filter(s => s.SkuCodigo == SkuCodigo);

            if (!mesesDespachosSinAsignar) {
              if (ff.SkuCodigo == SkuCodigo) {
                codigosSku.push({
                  SkuCodigo: SkuCodigo,
                  SkuDescripcion: Sku,
                  Details: [
                    {
                      SkuCodigo: SkuCodigo,
                      Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                      Nmes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Id,
                      DespachosSinAsignar: Enumerable.from(ff.DespachoAndOc.Despachos).select(s => {
                        return {
                          Id: null,
                          Fecha: s.Fecha,
                          ProveedorNombre: s.ProveedorNombre,
                          CodigoDespacho: s.CodigoDespacho,
                          Cantidad: s.Cantidad,
                          GetSemana: 'Semana ' + s.GetSemana
                        }
                      }).toArray(),
                      OrdenesCompra: Enumerable.from(ff.DespachoAndOc.OrdenCompra).select(s => {
                        return {
                          ProveedorNombre: s.ProveedorNombre,
                          Fecha: s.Fecha,
                          OrdenCompra: s.OrdenCompra,
                          Cantidad: Enumerable.from(s.OrdenComparaDetalle).select(s => s.Cantidad).sum()
                        }
                      }).toArray()                    
                    }
                  ]
                });
              }
            } else {
              let mesIndex = mesesDespachosSinAsignar.Details.findIndex(v => v.Nmes == s.key().Mes);

              if (mesIndex > -1) {
                let mes = mesesDespachosSinAsignar.Details[mesIndex];

                if (ff.SkuCodigo == SkuCodigo) {
                  if (ff.DespachoAndOc.Despachos.length != 0) {
                    ff.DespachoAndOc.Despachos.forEach(c => {
                      mes.DespachosSinAsignar.push(
                        {
                          Id: null,
                          Fecha: c.Fecha,
                          ProveedorNombre: c.ProveedorNombre,
                          CodigoDespacho: c.CodigoDespacho,
                          Cantidad: c.Cantidad,
                          GetSemana: 'Semana ' + c.GetSemana
                        }
                      )
                    });

                    ff.DespachoAndOc.OrdenCompra.forEach(v => {
                      mes.OrdenesCompra.push(
                        {
                          ProveedorNombre: v.ProveedorNombre,
                          Fecha: v.Fecha,
                          OrdenCompra: v.OrdenCompra,
                          Cantidad: Enumerable.from(v.OrdenComparaDetalle).select(s => s.Cantidad).sum()
                        }
                      )
                    });
                  }

                }
              } else {
                if (ff.SkuCodigo == SkuCodigo) {
                  mesesDespachosSinAsignar.Details.push({
                    SkuCodigo: SkuCodigo,
                    Mes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Mes,
                    Nmes: this.MesesAnio.filter(w => w.Id == s.key().Mes)[0].Id,
                    DespachosSinAsignar: Enumerable.from(ff.DespachoAndOc.Despachos).select(s => {
                      return {
                        Id: null,
                        Fecha: s.Fecha,
                        ProveedorNombre: s.ProveedorNombre,
                        CodigoDespacho: s.CodigoDespacho,
                        Cantidad: s.Cantidad,
                        GetSemana: 'Semana ' + s.GetSemana
                      }
                    }).toArray(),
                    OrdenesCompra: Enumerable.from(ff.DespachoAndOc.OrdenCompra).select(s => {
                      return {
                        ProveedorNombre: s.ProveedorNombre,
                        Fecha: s.Fecha,
                        OrdenCompra: s.OrdenCompra,
                        Cantidad: Enumerable.from(s.OrdenComparaDetalle).select(s => s.Cantidad).sum()
                      }
                    }).toArray() 
                  })
                }

                mesIndex = mesesDespachosSinAsignar.Details.length - 1;
              }

            }

          });
        });
      });
      codigosSku.forEach(f => {
        f.Details.forEach(ff => {
          let infoDespachosSinAsignar: DespachosSinAsignarSkuII;
          let infoOrdenesDeCompra: OrdenesDeCompraII;

          ff.DespachosSinAsignar.forEach(fff => {
            if (ff.DespachosSinAsignar.length != 0) {
              infoDespachosSinAsignar = {
                Id: fff.Id,
                Mes: ff.Mes,
                Nmes: ff.Nmes,
                Fecha: fff.Fecha,
                ProveedorNombre: fff.ProveedorNombre,
                CodigoDespacho: fff.CodigoDespacho,
                Cantidad: fff.Cantidad,
                GetSemana: fff.GetSemana
              }
              this.arrayDespachosSinAsignarMensualSku.push(infoDespachosSinAsignar);
            }          
          });
        

          ff.OrdenesCompra.forEach(ffff => {
            if (ff.OrdenesCompra.length != 0) {
              infoOrdenesDeCompra = {
                Mes: ff.Mes,
                Nmes: ff.Nmes,
                ProveedorNombre: ffff.ProveedorNombre,
                Fecha: ffff.Fecha,
                OrdenCompra: ffff.OrdenCompra,
                Cantidad: ffff.Cantidad
              }
              this.arrayOrdenesDeCompraMensualSku.push(infoOrdenesDeCompra);
            }          
          });

        });
      });
    
      let array = {
        DespachosSinAsignar: this.arrayDespachosSinAsignarMensualSku,
        OrdenesDeCompra: this.arrayOrdenesDeCompraMensualSku
      }

      this.arrayDespachosSinAsignarMesSku.push(array);

      this.myGridDespachosSinAsignarMesSku.source.localdata = this.arrayDespachosSinAsignarMensualSku;
      this.myGridDespachosSinAsignarMesSku.dataAdapter.dataBind();

      this.myGridOrdenesCompraMesSku.source.localdata = this.arrayOrdenesDeCompraMensualSku;
      this.myGridOrdenesCompraMesSku.dataAdapter.dataBind();

      $(this.myGridDespachosSinAsignarMesSku.myGridDespachosSinAsignarMesSku.elementRef.nativeElement).show();
      $(this.myGridOrdenesCompraMesSku.myGridOrdenesCompraMesSku.elementRef.nativeElement).show();
      this.modalDespachossinAsignarMesSku.showModal().subscribe(response => {
        $(this.myGridDespachosSinAsignarMesSku.myGridDespachosSinAsignarMesSku.elementRef.nativeElement).hide();
        $(this.myGridOrdenesCompraMesSku.myGridOrdenesCompraMesSku.elementRef.nativeElement).hide();
      });

      //console.log(codigosSku);
      //console.log(this.arrayDespachosSinAsignarMesSku);
      //this.arrayDespachosSinAsignarMesSku = codigosSku;
     // this.modalDespachossinAsignarMesSku.showModal();
    }
  }
}

///OBJETOS INICIO RECONSTRUCCION PARA TABLA///
export interface SkuList {
  SkuCodigo: string;
  Sku: string;
  Planta: string;
  MostrarDetalleIngresos: boolean;
  MostrarOcultarLineasTabla: boolean;
  AbrirCerrarTabla: boolean;
  UnidadMedida: string;
  LineasDescripcion: Array<ListaSemanaDetalle>;
  Meses: Array<DetalleMensual>;
}

export interface DetalleMensual {
  CantidadMeses: number;
  MesesArray: Array<MesArray>;
}

export interface MesArray {
  Mes: string;
  NMes: number;
  DetailSemana: boolean;
  CantidadSemanas: number;
  Lineas: Array<LineasMes>;
  Semanas: Array<Semanas>;
}

export interface Semanas {
  NSemana: number;
  Semana: string;
  ParoProgramado: boolean;
  SemanaDetalle: Array<LineasSemanas>;
}

export interface LineasMes {
  LineaPrincipal: number,
  InventarioInicial: number,
  Cobertura: number;
  Ingresos: number;
  IngresosSum: number;
  DespachosAsignados: number;
  DespachosAsignadosSum: number;
  DespachosSinAsignar: number;
  DespachosSinAsignarSum: number;
  Gestiones: number;
  GestionesSum: number;
  Consumos: number;
  ConsumosSum: number;
  InventarioFinal: number;
  //InventarioFinalConDespachosAsignadosSum: number;
  //InventarioFinalSinDespachosAsignadosSum: number;
}

export interface LineasSemanas {
  LineaPrincipal: number,
  InventarioInicial: number,
  Cobertura: number;
  Ingresos: number;
  IngresosSum: number;
  DespachosAsignados: number;
  DespachosAsignadosSum: number;
  DespachosSinAsignar: number;
  DespachosSinAsignarSum: number;
  Gestiones: number;
  GestionesSum: number;
  Consumos: number;
  ConsumosSum: number;
  InventarioFinal: number;
}
///OBJETOS FIN RECONSTRUCCION PARA TABLA///

export interface DespachosModales {
  Id?: string;
  Fecha: Date
  ProveedorNombre: string;
  CodigoDespacho: string;
  Cantidad?: number;
  GetSemana: string;
}

export interface EncabezadoMesesSku {
  CantidadMeses: number;
  Meses: Array<EncabezadoMesSemanas>;
}

export interface EncabezadoMesSemanas {
  CantidadSemanas: number;
  DetailSemana: boolean;
  Nmes: number;
  Mes: string;
  Semanas: Array<EncabezadoSemanas>; 
}

export interface EncabezadoSemanas {
  NSemana: number;
  Semana: string;
}

///INICIO OBJETOS LINEA PARA GESTIONES MES SKU ///
export interface GestionesMesSku {
  SkuDescripcion?: string;
  SkuCodigo?: string;
  Details?: Array<DetailsGestionesMesSku>;
}

export interface DetailsGestionesMesSku {
  SkuCodigo?: string,
  Mes?: string;
  Nmes?: number;
  Gestiones?: Array<NewGestiones>;
}

export interface GestionesMesSkuII {
  Mes: string;
  Nmes: number;
  Abierta: boolean;
  CodigoGestion: string;
  CodigoSku: string;
  Eta: Date;
  EtaModificada: Date;
  GestionSeguimientoId: string;
  ProveedorNombre: string;
  Semana: number;
  SemanaEtaModificada: number;
  SitioActual: { Id: string; Nombre: string };
  Unidad: number;
}
///FIN OBJETOS LINEA PARA GESTIONES MES SKU ///

///INICIO OBJETOS LINEA PARA DESPACHOS ASIGNADOS MES SKU///
export interface DespachosAsignadosMesSku {
  SkuDescripcion?: string;
  SkuCodigo?: string;
  Details?: Array<DetailsDespachosAsignadosMesSku>;
}

export interface DetailsDespachosAsignadosMesSku {
  SkuCodigo?: string,
  Mes?: string;
  Nmes?: number;
  DespachosAsignados?: Array<DespachosModales>;
}

export interface DespachosAsignadosMesSkuII {
  Id: string;
  Mes: string;
  Nmes: number;
  Fecha: Date
  ProveedorNombre: string;
  CodigoDespacho: string;
  Cantidad: number;
  GetSemana: string;
}
///FIN OBJETOS LINEA PARA DESPACHOS ASIGNADOS MES SKU///

///INICIO OBJETOS LINEA PARA DESPACHOS SIN ASIGNAR MES SKU///
export interface DespachosSinAsignarMesSku {
  SkuDescripcion?: string;
  SkuCodigo?: string;
  Details?: Array<DetailsDespachosSinAsignarMesSku>;
}

export interface DetailsDespachosSinAsignarMesSku {
  SkuCodigo?: string,
  Mes?: string;
  Nmes?: number;
  DespachosSinAsignar?: Array<DespachosModales>;
  OrdenesCompra?: Array<OrdenesDeCompra>;
}

export interface DespachosSinAsignarMesSkuII {
  SkuDescripcion: string;
  SkuCodigo: string;
  DespachosSinAsignados: Array<DetailsDespachosSinAsignarMesSku>;
}

export interface DespachosSinAsignarMesualSkuII {
  DespachosSinAsignar: Array<DespachosSinAsignarSkuII>;
  OrdenesDeCompra: Array<OrdenesDeCompraII>;
}

export interface DespachosSinAsignarSkuII {
  Id: string;
  Mes: string;
  Nmes: number;
  Fecha: Date
  ProveedorNombre: string;
  CodigoDespacho: string;
  Cantidad: number;
  GetSemana: string;
}

export interface OrdenesDeCompraII {
  Mes: string;
  Nmes: number;
  ProveedorNombre: string;
  Fecha: Date;
  OrdenCompra: string;
  Cantidad: number;
}
///FIN OBJETOS LINEA PARA DESPACHOS SIN ASIGNAR MES SKU///


///OBJETOS LINEA PARA ORDENES DE COMPRA///
export interface OrdenesDeCompra {
  ProveedorNombre: string;
  Fecha: Date;
  OrdenCompra: string;
  Cantidad: number;
}

///OBJETO DE SUMAS TOTALES///
export interface SumasTotales {
  Sku: string;
  LineasDescripcion: Array<ListaSemanaDetalle>;
  AbrirTotales: boolean,
  Totales: Array<DetailsTotales>;
}

export interface DetailsTotales {
  CantidadMeses: number;
  Meses: Array<DetailsMeses>;
}

export interface DetailsMeses {
  CantidadSemanas: number;
  DetailSemana: boolean;
  Nmes: number;
  Mes: string;
  Lineas: Array<LineasMesSemanas>;
  LineasSemanas: Array<LineasSemanasTotales>;
}

export interface LineasSemanasTotales {
  NSemana: number;
  Semana: string;
  SemanaDetalle: Array<LineasMesSemanas>;
}

export interface LineasMesSemanas {
  LineaPrincipal?: number,
  InventarioInicial?: number,
  InventarioInicialTotal?: number,
  Cobertura?: any;
  CoberturaTotal?: number;
  Ingresos?: number;
  DespachosAsignados?: number;
  DespachosSinAsignar?: number;
  Gestiones?: number;
  Consumos?: number;
  ConsumosTotal?: number;
  InventarioFinal?: number;
  //LineaPrincipal: number,
  //InventarioInicial: number,
  //Cobertura: number;
  //CoberturaSum: number;
  //Ingresos: number;
  //IngresosSum: number;
  //DespachosAsignados: number;
  //DespachosAsignadosSum: number;
  //DespachosSinAsignar: number;
  //DespachosSinAsignarSum: number;
  //Gestiones: number;
  //GestionesSum: number;
  //Consumos: number;
  //ConsumosSum: number;
  //InventarioFinal: number;
}
