import { Component, OnInit, ViewChild } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';

@Component({
  selector: 'app-grid-despachos-sin-asignar-mes',
  templateUrl: './grid-despachos-sin-asignar-mes.component.html',
  styleUrls: ['./grid-despachos-sin-asignar-mes.component.css']
})
export class GridDespachosSinAsignarMesComponent implements OnInit {

  constructor() { }
  @ViewChild('myGridDespachosSinAsignarMes', { static: false }) myGridDespachosSinAsignarMes: jqxGridComponent;

  ngOnInit() {
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Fecha', type: 'date' },
        { name: 'ProveedorNombre', type: 'string' },
        { name: 'CodigoDespacho', type: 'string' },
        { name: 'Cantidad', type: 'number' },
        { name: 'GetSemana', type: 'string' },
      ],
      id: "CodigoDespacho",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      {
        text: 'Nº', sortable: false, filterable: false, columntype: 'number', width: '5%', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 12px; text-align:center;">' + (value + 1) + '</div>';
        },
      },
      {
        text: 'Semana', datafield: 'GetSemana', cellsalign: 'center', align: 'center', filterable: false, width: '10%'
      },
      { text: 'Fecha', datafield: 'Fecha', cellsalign: 'center', align: 'center', width: '20%', filterable: false, cellsformat: 'dd/MM/yyyy hh:mm:ss' },
      { text: 'Proveedor', datafield: 'ProveedorNombre', cellsalign: 'left', align: 'center', width: '35%' },
      { text: 'Despacho', datafield: 'CodigoDespacho', cellsalign: 'center', align: 'center', filterable: false, width: '15%' },
      {
        text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '15%', cellsformat: 'n', aggregates: ['sum'],
        aggregatesrenderer: (aggregates, columns, element) => {
          let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + aggregates.sum + '</div>';
          return renderstring;
        }
      }
    ];
}
