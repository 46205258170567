import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { jqxGridComponent } from '../../../../../node_modules/jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';


@Component({
  selector: 'app-grid-ordenes-de-compra-mensual',
  templateUrl: './grid-ordenes-de-compra-mensual.component.html',
  styleUrls: ['./grid-ordenes-de-compra-mensual.component.css']
})
export class GridOrdenesDeCompraMensualComponent implements OnInit, AfterViewInit {

  constructor() { }
  @ViewChild('myGridOrdenesCompraMesSku', { static: false }) myGridOrdenesCompraMesSku: jqxGridComponent;
  //@Input() ordenescompra: Array<OrdenesDeCompra>;

  ngOnInit() {
  }

  ngAfterViewInit() {
    //this.source.localdata = this.ordenescompra;
    //this.dataAdapter.dataBind();
  }

  source: jqwidgets.GridSource =
    {
      datafields: [
        { name: 'Mes', type: 'string' },
        { name: 'ProveedorNombre', type: 'string' },
        { name: 'Fecha', type: 'date' },
        { name: 'OrdenCompra', type: 'number' },
        { name: 'Cantidad', type: 'number' }
      ],
      id: "OrdenCompra",
      datatype: "array"
    };

  dataAdapter: any = new jqx.dataAdapter(this.source);

  columns: any[] =
    [
      {
        text: 'Nº', sortable: false, filterable: false, columntype: 'number', width: '5%', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, column: any, value: number): string => {
          return '<div style="margin: 12px; text-align:center;">' + (value + 1) + '</div>';
        },
      },
      { text: 'Mes', datafield: 'Mes', cellsalign: 'left', align: 'center', width: '20%' },
      { text: 'Proveedor', datafield: 'ProveedorNombre', cellsalign: 'left', align: 'center', width: '35%' },
      { text: 'Orden Compra', datafield: 'OrdenCompra', cellsalign: 'left', align: 'center', filterable: false, width: '30%' },
      { text: 'Fecha', datafield: 'Fecha', cellsalign: 'center', align: 'center', width: '20%', filterable: false, cellsformat: 'dd/MM/yyyy hh:mm:ss' },
      {
        text: 'Cantidad', datafield: 'Cantidad', cellsalign: 'center', align: 'center', filterable: false, width: '20%', cellsformat: 'n', aggregates: ['sum'],
        aggregatesrenderer: (aggregates) => {
          if (aggregates.sum == undefined) {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + 0 + '</div>';
            return renderstring;
          } else {
            let renderstring = '<div style="position: relative; margin-top: 4px; margin-right:5px; text-align: center; overflow: hidden;">' + aggregates.sum + '</div>';
            return renderstring;
          }
        }
      }
    ];

}

export interface OrdenesDeCompra {
  ProveedorNombre: string;
  Fecha: Date;
  OrdenCompra: string;
  Cantidad: number;
}
