import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { JqxGridModule } from '../../ng-modules/jqx-grid/jqx-grid.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AlertasComponent } from './alertas/alertas.component';
import { GridAlertasComponent } from './grid-alertas/grid-alertas.component';
import { ModalModule } from 'modal';
import { GridGestionesComponent } from './grid-gestiones/grid-gestiones.component';

@NgModule({
  declarations: [AlertasComponent, GridAlertasComponent, GridGestionesComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    JqxGridModule,
    ModalModule,
    NgSelectModule
  ]
})
export class AlertasModule { }
