import { Component, OnInit, ViewChild } from '@angular/core';
//import { EvaluacionForecastVm } from '../../pojos/evaluacion-forecast-object';
import { CallHttp } from 'vesta';
import { ScmGraph, ScmColors, ScmGraphDataFooter, ScmGraphSeries, ShadowsByRange, LinesV, GraphXAxis, ScmGraphPointValue, PointVm, ScmGraphDataFooters, GraphYAxis, rectDataY, ScmGraphPoint } from '../../../objects/grafico-test-object';
import * as Enumerable from 'linq';

@Component({
  selector: 'app-grafica-test',
  templateUrl: './grafica-test.component.html',
  styleUrls: ['./grafica-test.component.css']
})
export class GraficaTestComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.randomData();
  }

  dataGridEvaluacionForecast: Array<any> = [];
  //_differenceY2Y1: number = 31.25;
  //_tamanioParticion: number = 30000;
  //_particiones: number = 8;

  _heightGraph: number = 320;
  _heightGraphArea: number = 0;
  _widthGraphArea: number = 20;
  _differenceY2Y1: number = 24;//esta debe ser calculada
  _tamanioParticion: number = 100;
  _particiones: number = 12;
  posYDataLeyendX: number = this._heightGraph + 20;

  graphScmProd: ScmGraph;
  graphScmProd2: ScmGraph;
  graphScmProd3: ScmGraph;

  randomData() {
    let items = [];

    for (var i = 0; i < 12; i++) {
      let limMax = Math.floor(1000);
      //let limMax = Math.floor(240000);
      let limMin = Math.ceil(0);

      let max = Math.floor(Math.random() * (limMax - limMin + 1)) + limMin;
      let min = Math.floor(Math.random() * (max - 10));

      items.push({
        valueMax: max,
        valueMin: min
      });
    }


    let widthBar = 60;//120
    let widthBarV = widthBar / 2;//120
    let inicioBar = 100;
    let distance = 20;
    let hFooter = 35;
    let distanceEveryFooter = 15;
    this.graphScmProd2 = {
      title: 'Análisis de inventario',
      distance: distance,
      widthBar: widthBar,
      series:
        this.GetSeriesGraph(items, widthBar, distance, this.GetXAxis(this.GetMaxValue(items), this.GetMaxValue(items) / this._particiones, this._differenceY2Y1).y, this._heightGraph),
      linesV: [
        LinesV.insert(inicioBar + widthBar * 0 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 1 + distance * 1 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 2 + distance * 2 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 3 + distance * 3 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 4 + distance * 4 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 5 + distance * 5 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 6 + distance * 6 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 7 + distance * 7 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 8 + distance * 8 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 9 + distance * 9 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 10 + distance * 10 + widthBarV, this._heightGraph),
        LinesV.insert(inicioBar + widthBar * 11 + distance * 11 + widthBarV, this._heightGraph)
      ],
      lineStartEndMonth: [
        {
          xUno: 340, yUno: 90, xDos: 220, yDos: 90
        }
      ],
      xAxis: this.GetXAxis(this.GetMaxValue(items), this.GetMaxValue(items) / this._particiones, this._differenceY2Y1),
      yAxis: this.GetYAxis(items, widthBar, distance, true),
      //yAxis: {
      //  y: this._heightGraph + 20,
      //  x: [
      //    { point: 240, value: "01/01/21" },
      //    { point: 400, value: "Febrero" },
      //    { point: 560, value: "Marzo" },
      //    { point: 720, value: "Abril" },
      //    { point: 880, value: "Mayo" },
      //    { point: 1040, value: "Junio" }//+10 = x2 de X es decir la recta X 
      //  ],
      //  rectData: {
      //    wRect: 120,
      //    hRect: 30,
      //    points: [
      //      { x: 220, y: this._heightGraph + 20 },
      //      { x: 380, y: this._heightGraph + 20 },
      //      { x: 540, y: this._heightGraph + 20 },
      //      { x: 700, y: this._heightGraph + 20 },
      //      { x: 860, y: this._heightGraph + 20 },
      //      { x: 1020, y: this._heightGraph + 20 }
      //    ], radiusXY: "5"
      //  },
      //  title: 'Fecha ETA'
      //},
      //dataFooters: this.GetDataFooters(items, ['Total Inventario', 'Total Entrada', 'Total Salida'], null, widthBar, distance),
      shadowsByRange: [
        ShadowsByRange.insert('', 550, 700, 1, 500)//inicio, fin
      ]
    }
  }

  GetMaxValue(data: Array<any>) {
    let max = data[0].valueMax;

    for (var i = 0; i < data.length; i++) {
      if (data[i].valueMax > max) {
        max = data[i].valueMax;
      }
    }

    return max;
  }

  GetPointMinMaxY(hGraph: number, item: any, linesY: Array<ScmGraphPointValue>, difference: number = this._tamanioParticion, diffy2y1: number = this._differenceY2Y1): PointVm {
    let valueMaxItem = item.valueMax;
    //let valueMaxItem = 157815;//item.valueMax
    let valueMinItem = item.valueMin;
    //let valueMinItem = 42158;//item.valueMin
    let max = valueMaxItem > 0 ? (this._heightGraph - (diffy2y1 / difference) * valueMaxItem) : 0;
    let min = valueMinItem > 0 ? (this._heightGraph - max - (diffy2y1 / difference) * valueMinItem) : 0;
    //31.25 / 30000 = 0.00104166666666666666666666666667
    //0.00104166666666666666666666666667 * 26000 = 27.083333333333333333333333333333
    //30000 / 31.25 = 960

    if (min > hGraph || max > hGraph) {
      return { min: 0, max: 0 }
    }


    return { min: min, max: max }
  }

  GetSeriesGraph(data: Array<any>, wBar: number, d: number, linesY: Array<ScmGraphPointValue>, hGraph: number): Array<ScmGraphSeries> {
    this._heightGraphArea = hGraph;
    let dataGraph: Array<ScmGraphSeries> = [];
    let inicioX = 100;
    let widthBar = wBar;
    let distance = d;

    for (var i = 0; i < data.length; i++) {
      //dataGraph.push(ScmGraphData.insert(inicioX + widthBar * i + distance * i, 71.25, widthBar, 198.75));
      let x = inicioX + widthBar * i + distance * i;
      dataGraph.push(ScmGraphSeries.insert(
        x,
        this.GetPointMinMaxY(hGraph, data[i], linesY).max,
        widthBar,
        this.GetPointMinMaxY(hGraph, data[i], linesY).min,
        "#2874a6",
        "Min: " + data[i].valueMax + "\n" + "Max: " + data[i].valueMin
      ));

      if (i == data.length - 1) {
        this._widthGraphArea += x + widthBar;
      }
    }


    return dataGraph;
  }

  GetDataFooters(data: Array<any>, listTitle: Array<string>, listFields: Array<string>, wBar: number, distance: number, distanceH: number = 120) {
    let dataFooters = [];
    let hFooter = 35;
    let distanceHFooter = 15;
    let yRectTitle: number = this._heightGraph + distanceH;
    let y: number = this._heightGraph + distanceH;

    for (var i = 0; i < listTitle.length; i++) {
      let listInfo: Array<ScmGraphDataFooter> = [];

      for (var k = 0; k < data.length; k++) {
        listInfo.push(ScmGraphDataFooter.insert(
          100 + wBar * k + distance * k,
          y + distanceHFooter * i + hFooter * i,
          wBar,
          hFooter,
          0 //valor
        ));
      }

      let objFooter: ScmGraphDataFooters = {
        dataFooter: listInfo,
        title: listTitle[i],
        x: 0,
        y: yRectTitle,
        yTitle: yRectTitle + 20
      };

      yRectTitle += 50;

      dataFooters.push(objFooter);
    }

    return dataFooters;
  }

  GetXAxis(maxValue: number, difference: number, diffy2y1: number): GraphXAxis {
    let listY: Array<ScmGraphPointValue> = [];
    let diff = maxValue;
    let inicio: number = 30;

    while (diff > 0) {
      if (listY.length > 0) {
        listY.push({ point: inicio, value: diff.toString() });
        diff = diff - difference;
      } else {
        listY.push({ point: inicio, value: maxValue.toString() });
        diff = maxValue - difference;
      }
      inicio = inicio + diffy2y1;
    }
    let obj: GraphXAxis = { x: 0, y: listY, title: 'Cajas' };

    return obj;
  }

  GetYAxis(items: Array<any>, wBar: number, distance: number, isVertical: boolean = false): GraphYAxis {
    let listX: Array<ScmGraphPointValue> = [];
    let listPoints: Array<ScmGraphPoint> = [];

    let c = 2;
    for (var i = 0; i < items.length; i++) {
      listX.push({
        point: 120 + wBar * i + distance * i,
        value: 'Test ' + (i + c)
      });
    }

    let rectData: rectDataY = { wRect: wBar, hRect: 30, points: listPoints, radiusXY: "5" };
    let obj: GraphYAxis = { y: isVertical ? this._heightGraph + 10 : this._heightGraph + 20, x: listX, title: 'Fecha ETA' };
    return obj;
  }

  SetDataGraph(data: Array<any>, widthBar: number = 60, distance: number, vista: string) {
    let tamData = data == null ? 12 : data.length;
    let widthBarV = widthBar / 2;
    this.graphScmProd2 = null;
    let maxValue = this.GetMaxValue(data);
    let miniLineX = this._heightGraph - 20;

    switch (vista) {
      case 'Filtros':

        break;
      case 'Mensual':

        break;
      default:
        //Vista semanal
        for (var i = 0; i < data.length; i++) {

        }
    }
  }
}
